import { Skeleton, SkeletonProps } from '@chakra-ui/react';
import React from 'react';

export const CustomSkeleton = (props: SkeletonProps) => {
    return (
        <Skeleton
            endColor={'neutral.300'}
            speed={1.3}
            startColor={'neutral.200'}
            {...props}
        />
    );
};
