import { ReactNode } from 'react';

import {
    GetActivityKeyReturn, GetCellKeyReturn,
    GetStudentAssignmentKeyReturn,
    GetStudentKeyReturn,
    GetTopicKeyReturn
} from '../../routes/helpers/keys/key-generator';
import {
    GridCellActivityId,
    GridCellActivityType,
    GridCells,
    ToggleGridCell,
    TopicActivityCount
} from '../../routes/helpers/slice/topic/topic-slice.types';
import { CourseData } from '../courses/types/courses.types';
import { DataGridContainerVariants } from '@containers/activity-router/data-grid-container/data-grid-container.types';
import {
    StrandId,
    StudentId,
    TopicId
} from '@containers/activity-router/routes/helpers/slice/slice.types';
import {
    SelectedGroupStudents
} from '@services/gateways/combined-assign-gateway/combined-assign-gateway.types';

export enum CheckboxStates {
    checked = 'checked',
    unchecked = 'unchecked',
    partial = 'partial'
}

type GridCellClickHandlerParams = Omit<ToggleGridCell, 'studentAssignments'>;

export type GridCellClickHandler = ({
    strandId,
    topicId,
    activityId,
    activityType,
    student,
    isSelected
}: GridCellClickHandlerParams) => void;

export type GridClickHandlers = {
    onCellClickHandler: GridCellClickHandler
};

export type KeyGenerators = {
    getStudentKey: GetStudentKeyReturn
    getTopicKey: GetTopicKeyReturn
    getActivityKey: GetActivityKeyReturn
    getStudentAssignmentKey: GetStudentAssignmentKeyReturn
    getCellKey: GetCellKeyReturn
};

export type GridComponentCell = ReactNode & { props: { dynamicProps: any } };

export type GridComponentsTopicCell = {
    strandId?: StrandId
    isExpanded: boolean
    topicId: TopicId
    userProfileId: StudentId
};

export type GridComponentsActivityCell = {
    userProfileId: StudentId
    activityId: GridCellActivityId
    activityType: GridCellActivityType
};

export type GridComponents = {
    topicCell: ({
        isExpanded,
        topicId,
        userProfileId,
        strandId
    }: GridComponentsTopicCell) => GridComponentCell
    activityCell: ({
        userProfileId,
        activityId,
        activityType
    }: GridComponentsActivityCell) => GridComponentCell
};

export type GridProps = {
    selectedGroupStudents: SelectedGroupStudents
    courses: CourseData
    gridClickHandlers: GridClickHandlers
    gridCells: GridCells
    topicActivityCount: TopicActivityCount
    keyGenerators: KeyGenerators
    components: GridComponents
    variant: DataGridContainerVariants
};
