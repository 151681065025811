import { CourseData } from '@containers/activity-router/data-grid-container/courses/types/courses.types';
import {
    ManageSelectedAssignmentsParams
} from '@containers/activity-router/routes/helpers/assignment/assignment-selected/route-assignments-selected.types';
import {
    GridCells,
    GridCounters, ToggleActivityGridCheckbox,
    ToggleStudentGridCheckbox, ToggleTopicGridCheckbox
} from '@containers/activity-router/routes/helpers/slice/topic/topic-slice.types';
import {
    AssignmentActionRecord
} from '@containers/activity-router/routes/learn/activities/helpers/slice/assignments/selected/assignments-selected-helpers.types';

export type StrandId = string;

export type StudentId = string;

export type TopicId = string;

export type ActivityId = string;

export type DefaultState = {
    course: {
        selected: CourseData
    }
    grid: {
        cells: GridCells
        counters: GridCounters
    }
    assignments: {
        selected: AssignmentsSelected
    }
};

export enum AssignmentActions {
    assign = 'assign',
    reassign = 'reassign',
    unassign = 'unassign',
    modify = 'modify'
}

export enum GridCount {
    studentCount = 'studentCount',
    topicCount = 'topicCount',
    topicActivityCount = 'topicActivityCount',
    activityCount = 'activityCount'
}

export type GridCounts = {
    selectedActivities: number
    totalActivities: number
};

export type GridCountsActivities = {
    selectedActivities: Array<StudentId>
    totalActivities: number
};

export type AssignmentsSelected = {
    [AssignmentActions.assign]: AssignmentActionRecord
    [AssignmentActions.reassign]: AssignmentActionRecord
    [AssignmentActions.unassign]: AssignmentActionRecord
    [AssignmentActions.modify]: AssignmentActionRecord
};

type ToggleRouteGridCheckboxDefaultParams = {
    state: {
        gridCells: GridCells
        gridCounters: GridCounters
        assignmentsSelected: AssignmentsSelected
    }
    manageSelectedAssignments: (params: ManageSelectedAssignmentsParams) => AssignmentsSelected
};

export type ToggleRouteStudentGridCheckboxParams = ToggleRouteGridCheckboxDefaultParams & {
    payload: ToggleStudentGridCheckbox
};

export type ToggleRouteTopicGridCheckboxParams = ToggleRouteGridCheckboxDefaultParams & {
    payload: ToggleTopicGridCheckbox
};

export type ToggleRouteActivityGridCheckboxParams = ToggleRouteGridCheckboxDefaultParams & {
    payload: ToggleActivityGridCheckbox
};
