import React from 'react';

import * as styled from './loading-footer.styled';
import { CustomSkeleton } from '@components/loading/custom-skeleton/custom-skeleton';

export const LoadingFooter = () => {
    return (
        <styled.container>
            <styled.wrapper>
                <CustomSkeleton {...styled.button} />
                <CustomSkeleton {...styled.button} margin={'0 16px'} />
                <CustomSkeleton {...styled.button} />
            </styled.wrapper>
        </styled.container>
    );
};
