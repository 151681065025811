import {
    Popover,
    PopoverTrigger, Portal
} from '@chakra-ui/react';
import React from 'react';

import * as styled from './context-modal.styled';
import { ContextModalProps } from './context-modal.types';

export const ContextModal = (props: ContextModalProps) => {
    return (
        <Popover
            closeOnBlur
            isLazy
            {...props}
        >
            <PopoverTrigger>
                {props.triggeredBy}
            </PopoverTrigger>
            <Portal>
                <styled.contextModalContent>
                    <styled.contextModalBody>
                        {props.children}
                    </styled.contextModalBody>
                </styled.contextModalContent>
            </Portal>
        </Popover>
    );
};
