const checkboxBaseHover = {
    _hover: {
        background: {
            base: 'primary.500',
            tablet: 'primary.600'
        },
        borderColor: {
            base: 'primary.500',
            tablet: 'primary.600'
        }
    }
};

export const checkboxBaseStyle = {
    borderRadius: '6px',
    background: 'white.0',
    _checked: {
        background: 'primary.500',
        borderColor: 'primary.500',
        ...checkboxBaseHover
    },
    _indeterminate: {
        background: 'primary.500',
        borderColor: 'primary.500',
        ...checkboxBaseHover
    },
    _hover: {
        borderColor: 'neutral.400'
    }
};
