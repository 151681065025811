import {
    getDokFilterCourseValues,
    getOverviewDokCourse
} from '../../../components/challenges-course-filters/challenges-course-filters-helpers';
import {
    GetUpdatedDokOverviewFilterValuesParams,
    GetUpdatedDokOverviewFilterValuesReturn
} from './challenge-slice-filtering-helpers.types';
import {
    DokFilterCourse
} from '@containers/activity-router/routes/learn/challenges/components/challenges-course-filters/components/dok-filter/dok-filter.types';
import {
    getChallengesDokFilteredCourse,
    getUpdatedCourseOverviewDokInitialState
} from '@containers/activity-router/routes/learn/challenges/helpers/slice/course/get-challenges-dok-filtered-course';

export const getUpdatedDokOverviewFilterValues = ({
    dokFilterCourse,
    selectedCourse,
    courseLookup,
    type
}: GetUpdatedDokOverviewFilterValuesParams): GetUpdatedDokOverviewFilterValuesReturn => {
    // Get the updated Dok filter state
    const updatedDokCourseItems = getOverviewDokCourse({
        dokFilterCourse,
        courseLookup,
        type
    });
    // Get the updated course
    const updatedCourse = getChallengesDokFilteredCourse({
        course: selectedCourse,
        dokCourseItems: updatedDokCourseItems
    });
    // Get the updated level filter
    const {
        updatedLevelFilter
    } = getDokFilterCourseValues({ dokFilterCourse: updatedDokCourseItems as DokFilterCourse });
    // Filter the course based on Dok items
    const updatedFilteredCourse = getUpdatedCourseOverviewDokInitialState({
        course: updatedCourse,
        courseLookup
    });

    return {
        updatedDokCourseItems,
        updatedFilteredCourse,
        updatedLevelFilter
    };
};
