import { checkboxBaseStyle } from '../checkbox-base';
import {
    TopicFilterCheckboxesVariants
} from '@containers/activity-router/data-grid-container/courses/course-filters/topic-filter/topic-filter.types';

export const TopicFilterCheckbox = {
    [TopicFilterCheckboxesVariants.topicFilterCheckbox]: {
        control: {
            ...checkboxBaseStyle,
            w: '24px',
            h: '24px'
        }
    }
};
