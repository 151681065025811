import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { prepareHeadersBase } from '@services/api/services-api-helpers';

export const classroomReportGatewayApi = createApi({
    reducerPath: 'classroomReportGatewayApi',
    baseQuery: fetchBaseQuery({
        baseUrl: window._env_?.CLASSROOM_REPORT_GATEWAY_URL || '',
        prepareHeaders: (headers) => prepareHeadersBase(headers)
    }),
    keepUnusedDataFor: 0,
    endpoints: (builder) => ({
        getAssignments: builder.query({
            query: ({
                classroomId,
                groupId,
                assignmentResultType,
                usePreviousResultWhenThereIsNoCurrent,
                authToken
            }) => ({
                // eslint-disable-next-line max-len
                url: `assignments/classrooms/${classroomId}/groups/${groupId}?assignmentResultType=${assignmentResultType}&usePreviousResultWhenThereIsNoCurrent=${usePreviousResultWhenThereIsNoCurrent}`,
                headers: { authToken }
            })
        }),
        getActivitiesAttempts: builder.query({
            query: ({
                classroomId,
                encryptedUserProfileId,
                activities,
                assignmentResultType,
                authToken
            }) => ({
                // eslint-disable-next-line max-len
                url: `assignments/classrooms/${classroomId}/student-activities-attempts?assignmentResultType=${assignmentResultType}&encryptedUserProfileId=${encryptedUserProfileId}`,
                method: 'POST',
                headers: { authToken },
                body: activities
            })
        })
    })
});

export const { useGetAssignmentsQuery, useGetActivitiesAttemptsQuery } = classroomReportGatewayApi;
