import { BoxProps, ButtonProps, useDisclosure } from '@chakra-ui/react';
import React, { ReactElement } from 'react';

import * as styled from './info-popover.styled';
import { ContextModal } from '@components/context-modal/context-modal';
import { InfoActiveIcon } from '@components/icon/info-popover/info-active-icon';
import { InfoIcon } from '@components/icon/info-popover/info-icon';

type InfoPopoverProps = {
    popoverText: ReactElement
    overrideStyles?: {
        containerStyles?: BoxProps
        iconStyles?: ButtonProps
    }
};

export const InfoPopover = ({
    popoverText,
    overrideStyles = {}
}: InfoPopoverProps) => {
    const { isOpen, onClose, onToggle } = useDisclosure();
    const getInfoIcon = () => {
        const defaultStyles = {
            w: '20px',
            h: '20px'
        };
        const toggledStyles = {
            border: '1px solid',
            borderColor: isOpen ? 'primary.200' : 'neutral.300',
            backgroundColor: isOpen ? 'primary.50' : 'white.0',
            _hover: {
                cursor: 'pointer',
                backgroundColor: isOpen ? 'primary.100' : 'neutral.50'
            }
        };
        const icon = isOpen ? (
            <InfoActiveIcon {...defaultStyles} />
        ) : (
            <InfoIcon {...defaultStyles} />
        );

        return (
            <styled.icon
                {...toggledStyles}
                onClick={onToggle}
                {...overrideStyles.iconStyles}
            >
                {icon}
            </styled.icon>
        );
    };

    return (
        <ContextModal
            isOpen={isOpen}
            placement={'bottom-end'}
            triggeredBy={getInfoIcon()}
            onClose={onClose}
        >
            <styled.modalContainer {...overrideStyles.containerStyles}>
                {popoverText}
            </styled.modalContainer>
        </ContextModal>
    );
};
