import { GridCount } from '../../../slice/slice.types';
import { TopicActivityMapHelper } from '../counters.types';
import {
    ManageStudentGridCounters,
    UpdatedActivity,
    UpdatedStudent,
    UpdatedTopic,
    UpdatedTopicActivity,
    UpdateTopicActivityMap
} from './student-grid-counters.types';

const {
    studentCount,
    topicCount,
    topicActivityCount,
    activityCount
} = GridCount;

function updatedStudent ({
    updatedStudentCount,
    studentId,
    isChecked
}: UpdatedStudent) {
    const { totalActivities } = updatedStudentCount[studentId];

    return isChecked ? totalActivities : 0;
}

function updateTopicActivityMap ({
    updatedTopicActivityMap,
    topicKey,
    updatedCount
}: UpdateTopicActivityMap) {
    updatedTopicActivityMap = {
        ...updatedTopicActivityMap,
        [topicKey]: updatedCount
    };

    return updatedTopicActivityMap;
}

function updatedTopicActivity ({
    updatedTopicActivityCount,
    isChecked
}: UpdatedTopicActivity) {
    let topicActivityMap: TopicActivityMapHelper = {};

    Object.keys(updatedTopicActivityCount).forEach((topicKey) => {
        const {
            selectedActivities,
            totalActivities
        } = updatedTopicActivityCount[topicKey];

        updatedTopicActivityCount[topicKey].selectedActivities = isChecked ? totalActivities : 0;
        topicActivityMap = updateTopicActivityMap({
            updatedTopicActivityMap: topicActivityMap,
            topicKey,
            updatedCount: isChecked ? totalActivities : selectedActivities
        });
    });

    return {
        updatedTopicActivityCount,
        topicActivityMap
    };
}

function updatedTopic ({
    updatedTopicCount,
    topicActivityMap,
    isChecked
}: UpdatedTopic) {
    Object.keys(updatedTopicCount).forEach((topicKey) => {
        const {
            selectedActivities
        } = updatedTopicCount[topicKey];
        const checkedValue = selectedActivities + topicActivityMap[topicKey];
        const uncheckedValue = selectedActivities - topicActivityMap[topicKey];

        updatedTopicCount[topicKey].selectedActivities = isChecked ?
            checkedValue :
            uncheckedValue;
    });

    return updatedTopicCount;
}

function updatedActivity ({
    updatedActivityCount,
    studentId,
    isChecked
}: UpdatedActivity) {
    Object.keys(updatedActivityCount).forEach((topicKey) => {
        Object.keys(updatedActivityCount[topicKey]).forEach((activityKey) => {
            const { selectedActivities } = updatedActivityCount[topicKey][activityKey];
            const index = selectedActivities.indexOf(studentId);

            if (isChecked) {
                if (index === -1) updatedActivityCount[topicKey][activityKey].selectedActivities.push(studentId);
            } else {
                updatedActivityCount[topicKey][activityKey].selectedActivities.splice(index, 1);
            }
        });
    });

    return updatedActivityCount;
}

export function manageStudentGridCounters ({
    counters,
    studentId,
    isChecked
}: ManageStudentGridCounters) {
    const {
        updatedTopicActivityCount,
        topicActivityMap
    } = updatedTopicActivity({
        updatedTopicActivityCount: counters[topicActivityCount][studentId],
        isChecked
    });

    // Updated Student Counters
    counters[studentCount][studentId].selectedActivities = updatedStudent({
        updatedStudentCount: counters[studentCount],
        studentId,
        isChecked
    });
    // Updated Topic Activity Counters
    counters[topicActivityCount][studentId] = updatedTopicActivityCount;
    // Updated Topic Count
    counters[topicCount] = updatedTopic({
        updatedTopicCount: counters[topicCount],
        topicActivityMap,
        isChecked
    });
    // Updated Activity Count
    counters[activityCount] = updatedActivity({
        updatedActivityCount: counters[activityCount],
        studentId,
        isChecked
    });

    return counters;
}
