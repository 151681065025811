import {
    CombinedAssignCourseList,
    CombinedAssignSelectedCourse,
    TransformCourse
} from '../../combined-assign-gateway.types';
import { DropdownOption } from '@components/dropdown/dropdown.types';

function transformTopics (topics: CombinedAssignSelectedCourse['topics']): TransformCourse['topics'] {
    return topics.map((levelOne, strandIndex) => {
        const isStrand = levelOne.topics !== null;
        const defaultExpanded = true;

        return {
            ...levelOne,
            name: manageNameCasing(isStrand, levelOne.name || ''),
            topics: levelOne.topics?.map((topic) => ({
                ...topic,
                isExpanded: defaultExpanded
            })) || null,
            isExpanded: defaultExpanded,
            strandIndex
        };
    });
}

export function transformCourseData (course: CombinedAssignSelectedCourse): TransformCourse {
    if (!course) return course;

    return {
        ...course,
        topics: transformTopics(course.topics)
    };
}

function manageNameCasing (isStrand: boolean, name: string) {
    return isStrand ? name.toUpperCase() : name;
}

export function transformNewCoursesCourseListData (courseList: Array<CombinedAssignCourseList>): Array<DropdownOption> {
    return courseList.map((item) => {
        return {
            value: item.id,
            label: item.name
        };
    });
}

export function initAssignedActivitiesCourseData (course: TransformCourse): TransformCourse {
    return {
        ...course,
        topics: course.topics.map(strand => ({
            ...strand,
            isExpanded: false,
            topics: strand.topics ? strand.topics.map(topic => ({
                ...topic,
                isExpanded: false
            })) : null
        }))
    };
}
