export const strandColors = {
    strand: {
        0: {
            text: '#007794',
            iconBgDark: '#56C5E0',
            iconBGLight: '#C0F0F9',
            topicBG: '#E3FBFF',
            topicBGHover: '#D1F1F7',
            topicBGOpen: '#B2E8F1'
        },
        1: {
            text: '#972500',
            iconBgDark: '#FA7E46',
            iconBGLight: '#FDCBB5',
            topicBG: '#FFF1E1',
            topicBGHover: '#FFE2C0',
            topicBGOpen: '#FFD7A9'
        },
        2: {
            text: '#661985',
            iconBgDark: '#CF61D5',
            iconBGLight: '#F8B0F0',
            topicBG: '#FEEBFA',
            topicBGHover: '#F9D8F3',
            topicBGOpen: '#FCC2F2'
        },
        3: {
            text: '#DA5C34',
            iconBgDark: '#FF6A5E',
            iconBGLight: '#FEBFBA',
            topicBG: '#FFE8E5',
            topicBGHover: '#FED1CB',
            topicBGOpen: '#FFABA5'
        },
        4: {
            text: '#376B05',
            iconBgDark: '#77CB0B',
            iconBGLight: '#CDFBA1',
            topicBG: '#F0FFE2',
            topicBGHover: '#DFFFC0',
            topicBGOpen: '#B8ED86'
        }
    }
};
