import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { sortStudentsByKey } from './helpers/student-list.helpers';
import {
    StudentListState,
    StudentListConstants,
    SortStudentListPayload,
    SetStudentListSelectedStudents
} from './student-list.types';
import { RootState } from '@config/react-redux/store';

const { name } = StudentListConstants;
const initialState: StudentListState = {
    selected: {
        classroomId: null,
        groupId: null,
        selectedGroupStudents: []
    },
    sortKey: 'firstName'
};

export const studentListSlice = createSlice({
    name,
    initialState,
    reducers: {
        setStudentListSelected (state, action: PayloadAction<SetStudentListSelectedStudents>) {
            const {
                classroomId,
                groupId,
                selectedGroupStudents
            } = action.payload;

            state.selected = {
                ...state.selected,
                classroomId,
                groupId,
                selectedGroupStudents: sortStudentsByKey({
                    students: selectedGroupStudents,
                    key: state.sortKey
                })
            };
        },
        sortStudentListSelected (state, action: PayloadAction<SortStudentListPayload>) {
            const { sortKey } = action.payload;

            state.selected = {
                ...state.selected,
                selectedGroupStudents: sortStudentsByKey({
                    students: state.selected.selectedGroupStudents,
                    key: sortKey
                })
            };
            state.sortKey = sortKey;
        }
    }
});

/**
 * ACTIONS
 */
export const {
    setStudentListSelected,
    sortStudentListSelected
} = studentListSlice.actions;

/**
 * SELECTORS
 */
export const selectStudentListSelected = (state: RootState) => state[name].selected;

export const selectStudentListSortKey = (state: RootState) => state[name].sortKey;

export const selectStudentListGroupStudents = (state: RootState) => state[name].selected.selectedGroupStudents;

export default studentListSlice.reducer;
