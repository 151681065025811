import {
    ActivityId,
    StrandId,
    StudentId,
    TopicId
} from '../slice/slice.types';
import {
    GridCellActivityId,
    GridCellActivityType
} from '../slice/topic/topic-slice.types';

export type GetStudentKeyReturn = ({ id }: { id: StudentId }) => string;

export const getStudentKey: GetStudentKeyReturn =
    ({ id }) => id;

export type TopicKeyParams = { strandId?: StrandId; topicId: TopicId };

export type GetTopicKeyReturn = ({
    topicId,
    strandId
}: TopicKeyParams) => string;

export const getTopicKey: GetTopicKeyReturn = ({
    strandId,
    topicId
}) => {
    return JSON.stringify({
        ...(typeof strandId !== 'undefined' && { strandId }),
        topicId
    });
};

export const parseTopicKey = (key: string): TopicKeyParams => JSON.parse(key);

type ActivityKeyProps = {
    id: ActivityId
    activityType: number
};

export type GetActivityKeyReturn = ({
    id,
    activityType
}: ActivityKeyProps) => string;

export const getActivityKey: GetActivityKeyReturn = ({
    id,
    activityType
}) => `${id}/${activityType}`;

export const parseActivityKey = (key: string): ActivityKeyProps => {
    const parsedKey = key.split('/');

    return {
        id: parsedKey[0],
        activityType: parseInt(parsedKey[1])
    };
};

type StrandCourseKeyProps = {
    strandId: StrandId
    topicId: TopicId
    activityId: ActivityId
};

export type GetStrandCourseKeyReturn = ({
    strandId,
    topicId,
    activityId
}: StrandCourseKeyProps) => string;

export const getStrandCourseKey: GetStrandCourseKeyReturn = ({
    strandId,
    topicId,
    activityId
}) => `${strandId}/${topicId}/${activityId}`;

export const parseStrandCourseKey = (key: string) => {
    const parsedKey = key.split('/');
    const strandId = parsedKey[0] || '';
    const topicId = parsedKey[1] || '';
    const activityId = getActivityKey({
        id: parsedKey[2] || '',
        activityType: parseInt(parsedKey[3]) || 0
    });

    return {
        strandId,
        topicId,
        activityId
    };
};

type StudentAssignmentKeyProps = {
    userProfileId: StudentId
    activityId: GridCellActivityId
    activityType: GridCellActivityType
};

export type GetStudentAssignmentKeyReturn = ({
    userProfileId,
    activityId,
    activityType
}: StudentAssignmentKeyProps) => string;

export const getStudentAssignmentKey: GetStudentAssignmentKeyReturn = ({
    userProfileId,
    activityId,
    activityType
}) => `${userProfileId}/${activityId}/${activityType}`;

export const parseStudentAssignmentKey = (key: string) => {
    const parsedKey = key.split('/');

    return {
        studentId: parsedKey[0],
        activityId: parsedKey[1],
        activityType: parseInt(parsedKey[2])
    };
};

type CellKeyProps = {
    userProfileId: string
    topicId: string
    activityId?: string | null
};

export type GetCellKeyReturn = ({
    userProfileId,
    topicId,
    activityId
}: CellKeyProps) => string;

export const getCellKey: GetCellKeyReturn = ({
    userProfileId,
    topicId,
    activityId = null
}) => `${userProfileId}/${topicId}/${activityId}`;

export const keyGenerators = {
    getStudentKey,
    getTopicKey,
    getActivityKey,
    getStudentAssignmentKey,
    parseStudentAssignmentKey,
    getCellKey
};
