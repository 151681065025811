import { DropdownOption } from '@components/dropdown/dropdown.types';
// eslint-disable-next-line max-len
import { getTranslations } from '@containers/activity-router/routes/learn/new-courses/components/new-courses-empty-state/new-course-empty-state.translations';
import { CombinedAssignYearGroup } from '@services/gateways/combined-assign-gateway/combined-assign-gateway.types';

export const transformNewCoursesYearGroups = (yearGroups: Array<CombinedAssignYearGroup>): Array<DropdownOption> => {
    const { OPTION_DISABLED } = getTranslations();
    const updatedYearGroups: Array<DropdownOption> = [];
    const validYearLevels = [3, 4, 5, 6];

    yearGroups.forEach((item) => {
        if (validYearLevels.includes(item.yearGroup)) {
            updatedYearGroups.push({
                value: item.yearGroup,
                label: item.longName
            });
        }
    });

    updatedYearGroups.push({
        value: 0,
        label: OPTION_DISABLED,
        disabled: true
    });

    return updatedYearGroups;
};
