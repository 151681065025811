import { CourseData } from '@containers/activity-router/data-grid-container/courses/types/courses.types';
import {
    manageCourseTopicExpandCollapse
} from '@containers/activity-router/routes/helpers/courses/course-expand-helpers';
import { LevelFilter } from '@containers/activity-router/routes/learn/challenges/challenges-constants';
import { TransformCombinedAssignTopic } from '@services/gateways/combined-assign-gateway/combined-assign-gateway.types';

type GetChallengesLevelFilteredCourseProps = {
    course: CourseData
    filterLevel: number
};

type GetFilteredActivitiesParams = {
    topic: TransformCombinedAssignTopic
};

/**
 * As per previous Challenges implementation Level 2 IS Level 3 and Level 11 is Level 10 from a data perspective.
 * This function clears that up for us and can be found in previous module.
 */
export const getUpdatedChallengesLevelFilter = (levelFilter: number) => {
    const {
        MIN_LEVEL,
        MAX_LEVEL
    } = LevelFilter;

    if (levelFilter === MIN_LEVEL) levelFilter++;
    else if (levelFilter === MAX_LEVEL) levelFilter--;

    return levelFilter;
};

export const getChallengesLevelFilteredCourse = ({
    course,
    filterLevel
}: GetChallengesLevelFilteredCourseProps) => {
    if (!course) return {
        updatedCourse: course,
        hasTopics: true
    };
    const updatedLevelFilter = getUpdatedChallengesLevelFilter(filterLevel);
    const getFilteredActivities = ({ topic }: GetFilteredActivitiesParams) => {
        return topic.activities.filter((activity) => {
            const { yearGroup } = activity.extraProperties;
            const equalToLevelFilter = yearGroup === updatedLevelFilter;
            const selectAllLevelFilter = updatedLevelFilter === LevelFilter.SELECT_ALL;

            return equalToLevelFilter || selectAllLevelFilter;
        });
    };
    const filteredCourse: CourseData = {
        ...course,
        topics: course.topics?.map((strand) => ({
            ...strand,
            topics: strand.topics?.map((topic) => ({
                ...topic,
                activities: getFilteredActivities({ topic })
            })).filter((topic) => topic.activities.length > 0) || null
        })).filter((strand) => (strand.topics?.length || 0) > 0) || null
    };
    const hasTopics = filteredCourse.topics.length > 0;

    let updatedCourse = hasTopics ? filteredCourse : course;

    updatedCourse = manageCourseTopicExpandCollapse({
        course: updatedCourse,
        isExpanded: true
    });

    return {
        updatedCourse,
        hasTopics
    };
};
