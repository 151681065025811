import { ChangeEvent } from 'react';

import {
    ActivitiesTopic,
    ActivitiesTopics
} from '../../../../routes/learn/activities/activities.types';
import { DataGridContainerVariants } from '../../../data-grid-container.types';
import { CourseData } from '../../types/courses.types';

export enum TopicFilterCheckboxesVariants {
    topicFilterCheckbox = 'topicFilterCheckbox'
}

export enum TopicFilterButtons {
    topicFilterButton = 'topicFilterButton'
}

export type TopicFilterDefaultPropsReturn = {
    currentTopics: ActivitiesTopics
    currentTopicsLength: number
    checkedTopicLength: number
    hasCheckedTopics: boolean
    checkedTopicIsPlural: boolean
    hasSelectedTopics: boolean
};

export type InputChangeEvent = ChangeEvent<HTMLInputElement>;

export type OnCheckboxChangeHandler = {
    event: InputChangeEvent
    selectedTopic: ActivitiesTopic
};

export type SetFiltersTopic = (activitiesTopic: ActivitiesTopics) => void;

export type TopicFilterProps = {
    variant: DataGridContainerVariants
    selectedCourse: CourseData
    selectedFiltersTopics: ActivitiesTopics
    setFiltersTopic: (activitiesTopic: ActivitiesTopics) => void
    clearFilterCallback: () => void
};
