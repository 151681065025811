import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@config/react-redux/store';
import {
    AppLandingConstants,
    AppLandingModal,
    AppLandingState
} from '@containers/app/app-landing/app-landing.types';

const { name } = AppLandingConstants;
const initialState: AppLandingState = {
    modal: {
        variant: null,
        type: null
    }
};

export const appLandingSlice = createSlice({
    name,
    initialState,
    reducers: {
        setAppLandingModal (state, action: PayloadAction<AppLandingModal>) {
            if (state.modal.variant !== action.payload.variant) {
                state.modal = action.payload;
            }
        }
    }
});

/**
 * ACTIONS
 */
export const {
    setAppLandingModal
} = appLandingSlice.actions;

/**
 * SELECTORS
 */
export const selectAppLandingModal = (state: RootState) => state[name].modal;

export default appLandingSlice.reducer;
