import { CheckboxStates } from '../../../data-grid-container/grid/grid.types';
import {
    GridCounts,
    GridCountsActivities, TopicId
} from '../slice/slice.types';
import {
    GridCellActivityId,
    GridCells
} from '../slice/topic/topic-slice.types';

export function manageActivityGridCheckbox ({
    gridCells,
    updatedActivityId,
    isChecked
}: {
    gridCells: GridCells
    updatedActivityId: GridCellActivityId
    isChecked: boolean
}) {
    Object.keys(gridCells).forEach((studentId) => {
        Object.keys(gridCells[studentId]).forEach((topicId) => {
            Object.keys(gridCells[studentId][topicId]).forEach((activityId) => {
                if (activityId === updatedActivityId) {
                    gridCells[studentId][topicId][activityId].isSelected = isChecked;
                }
            });
        });
    });

    return gridCells;
}

export function manageTopicGridCheckbox ({
    gridCells,
    updatedTopicId,
    isChecked
}: {
    gridCells: GridCells
    updatedTopicId: TopicId
    isChecked: boolean
}) {
    Object.keys(gridCells).forEach((studentId) => {
        Object.keys(gridCells[studentId]).forEach((topicId) => {
            if (topicId === updatedTopicId) {
                Object.keys(gridCells[studentId][topicId]).forEach((activityId) => {
                    gridCells[studentId][topicId][activityId].isSelected = isChecked;
                });
            }
        });
    });

    return gridCells;
}

export function getTopicCheckboxState (topicCountValue: GridCounts) {
    if (Object.keys(topicCountValue).length > 0) {
        const { selectedActivities, totalActivities } = topicCountValue;

        if (selectedActivities === totalActivities) return CheckboxStates.checked;
        if (selectedActivities === 0) return CheckboxStates.unchecked;

        return CheckboxStates.partial;
    }

    return CheckboxStates.unchecked;
}

export function getActivityCheckboxState (activityCountValue: GridCountsActivities) {
    if (Object.keys(activityCountValue).length > 0) {
        const { selectedActivities, totalActivities } = activityCountValue;
        const selectedActivitiesLength = selectedActivities.length;

        if (selectedActivitiesLength === totalActivities) return CheckboxStates.checked;
        if (selectedActivitiesLength === 0) return CheckboxStates.unchecked;

        return CheckboxStates.partial;
    }

    return CheckboxStates.unchecked;
}
