/* eslint-disable max-len */
import { configureStore } from '@reduxjs/toolkit';

import StudentListSlice from '../../containers/activity-router/data-grid-container/student-list/student-list-slice';
import AuthSlice from '../auth/auth-slice';
import { AuthConstants } from '../auth/auth.types';
import NewCourseSlice from '@containers//activity-router/routes/learn/new-courses/new-courses-slice';
import DynamicGridFiltersSlice
    from '@containers/activity-router/data-grid-container/dynamic-grid-filters/dynamic-grid-filters-slice';
import {
    DynamicGridFiltersConstants
} from '@containers/activity-router/data-grid-container/dynamic-grid-filters/dynamic-grid-filters.types';
import {
    viewAssignedActivitiesModalSlice
} from '@containers/activity-router/data-grid-container/student-list/components/view-assigned-activities-modal/view-assigned-activities-modal-slice';
import {
    ViewAssignmentModalConstants
} from '@containers/activity-router/data-grid-container/student-list/components/view-assigned-activities-modal/view-assigned-activities-modal.types';
import { StudentListConstants } from '@containers/activity-router/data-grid-container/student-list/student-list.types';
import AssessmentsSlice from '@containers/activity-router/routes/assess/assessments/assessments-slice';
import { AssessmentsConstants } from '@containers/activity-router/routes/assess/assessments/assessments.types';
import ActivitiesSlice from '@containers/activity-router/routes/learn/activities/activities-slice';
import { ActivitiesConstants } from '@containers/activity-router/routes/learn/activities/activities.types';
import ChallengesSlice from '@containers/activity-router/routes/learn/challenges/challenges-slice';
import { ChallengesConstants } from '@containers/activity-router/routes/learn/challenges/challenges.types';
import { NewCoursesConstants } from '@containers/activity-router/routes/learn/new-courses/new-courses.types';
import SkillQuestsSlice from '@containers/activity-router/routes/revise/skill-quests/skill-quests-slice';
import { SkillQuestsConstants } from '@containers/activity-router/routes/revise/skill-quests/skill-quests.types';
import AppLandingSlice from '@containers/app/app-landing/app-landing-slice';
import { AppLandingConstants } from '@containers/app/app-landing/app-landing.types';
import ClassGroupFiltersSlice from '@containers/class-group-filters/class-group-filters-slice';
import { ClassGroupFiltersStateConstants } from '@containers/class-group-filters/class-group-filters.types';
import { authenticationGatewayApi } from '@services/gateways/authentication-gateway/api/authentication-gateway-api';
import { classroomReportGatewayApi } from '@services/gateways/classroom-report-gateway/api/classroom-report-gateway-api';
import { combinedAssignGatewayApi } from '@services/gateways/combined-assign-gateway/api/combined-assign-gateway-api';

export const store = configureStore({
    reducer: {
        [AppLandingConstants.name]: AppLandingSlice,
        [AuthConstants.name]: AuthSlice,
        [ClassGroupFiltersStateConstants.name]: ClassGroupFiltersSlice,
        [StudentListConstants.name]: StudentListSlice,
        [ActivitiesConstants.name]: ActivitiesSlice,
        [ChallengesConstants.name]: ChallengesSlice,
        [SkillQuestsConstants.name]: SkillQuestsSlice,
        [NewCoursesConstants.name]: NewCourseSlice,
        [AssessmentsConstants.name]: AssessmentsSlice,
        [DynamicGridFiltersConstants.name]: DynamicGridFiltersSlice,
        [combinedAssignGatewayApi.reducerPath]: combinedAssignGatewayApi.reducer,
        [authenticationGatewayApi.reducerPath]: authenticationGatewayApi.reducer,
        [classroomReportGatewayApi.reducerPath]: classroomReportGatewayApi.reducer,
        [ViewAssignmentModalConstants.name]: viewAssignedActivitiesModalSlice.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
        combinedAssignGatewayApi.middleware,
        authenticationGatewayApi.middleware,
        classroomReportGatewayApi.middleware
    )
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type
export type AppDispatch = typeof store.dispatch;
