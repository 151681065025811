import { CheckboxStates } from '../../../../data-grid-container/grid/grid.types';
import {
    GetStudentCheckboxState,
    ManageStudentGridCheckbox
} from './student-list-checkbox-helpers.types';

export function manageStudentGridCheckbox ({
    gridCells,
    updatedStudentId,
    isChecked
}: ManageStudentGridCheckbox) {
    Object.keys(gridCells).forEach((studentId) => {
        if (studentId === updatedStudentId) {
            const selectedStudentGridCells = gridCells[studentId];

            Object.keys(selectedStudentGridCells).forEach((topicId) => {
                const selectedStudentTopicGridCells = selectedStudentGridCells[topicId];

                Object.keys(selectedStudentTopicGridCells).forEach((activityId) => {
                    gridCells[studentId][topicId][activityId].isSelected = isChecked;
                });
            });
        }
    });

    return gridCells;
}

export function getStudentCheckboxState ({ studentCountValue }: GetStudentCheckboxState) {
    const {
        selectedActivities,
        totalActivities
    } = studentCountValue || {};

    if (selectedActivities === totalActivities) return CheckboxStates.checked;
    if (selectedActivities === 0) return CheckboxStates.unchecked;

    return CheckboxStates.partial;
}
