export enum ChallengesAssignmentResultType {
    highestTeacherAssigned = 0,
    highestVoluntary,
    highest,
}

export const LevelFilter = {
    SELECT_ALL: 0,
    MIN_LEVEL: 2,
    MAX_LEVEL: 11
};

export const CHALLENGES_STRAND = {
    NUMBER_ALGEBRA: 'number&algebra',
    STATISTICS_DATA: 'statistics&data',
    CHANCE_PROBABILITY: 'chance&probability',
    GEOMETRY: 'geometry',
    MEASUREMENT: 'measurement'
};
