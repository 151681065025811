import { chakra, Flex } from '@chakra-ui/react';

import {
    topicFilterInputWidth
} from '@containers/activity-router/data-grid-container/courses/course-filters/topic-filter/topic-filter.styled';
import {
    CellDimensions,
    CourseContainerWidth
} from '@containers/activity-router/data-grid-container/data-grid-container.styled';

export const container = chakra(Flex, {
    label: 'loading-route-container',
    baseStyle: {
        alignItems: 'center',
        flexDirection: 'column',
        w: CourseContainerWidth.w
    }
});

export const topicFilter = {
    borderRadius: '6px',
    h: '40px',
    minH: '40px',
    w: topicFilterInputWidth,
    marginTop: '120px'
};

export const course = {
    borderTopLeftRadius: '12px',
    borderBottomLeftRadius: '12px',
    marginTop: '0',
    w: '100%'
};

export const checkboxContainer = chakra(Flex, {
    label: 'loading-route-checkboxContainer',
    baseStyle: {
        flexDirection: 'column',
        w: CellDimensions.w
    }
});
