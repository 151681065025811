import { Divider, Flex, Text } from '@chakra-ui/react';
import React from 'react';

import * as styled from './access-toggle.styled';
import { getTranslations } from './access-toggle.translations';
import { FormLabel } from './form-label';
import { useRestrictData } from './hooks/use-restrict-data';
import { useRestrictService } from './hooks/use-restrict-service';
import { InfoPopover } from '@components/info-popover/info-popover';

export const AccessToggleVariant = 'accessToggleVariant';

export const AccessToggle = () => {
    const { putClassGroupRestricted } = useRestrictService();
    const {
        FULL_ACCESS,
        RESTRICT_STUDENTS,
        POPOVER_TEXT
    } = getTranslations();
    const isRestrictedToAssignmentsOnly = useRestrictData();
    const onAccessToggleChange = async () =>
        await putClassGroupRestricted({ isRestrictedToAssignmentsOnly });

    return (
        <styled.container>
            <Divider h={'52px'} orientation={'vertical'} />
            <styled.formControl>
                <Flex gap={'8px'}>
                    <InfoPopover
                        overrideStyles={{
                            iconStyles: styled.iconOverrideStyles
                        }}
                        popoverText={<Text>{POPOVER_TEXT}</Text>}
                    />
                    <styled.accessRestrictSwitch
                        id="access-toggle"
                        isChecked={isRestrictedToAssignmentsOnly}
                        size="lg"
                        variant={AccessToggleVariant}
                        onChange={onAccessToggleChange}
                    />
                </Flex>
                <FormLabel
                    isChecked={isRestrictedToAssignmentsOnly}
                    labels={{
                        checked: RESTRICT_STUDENTS,
                        unchecked: FULL_ACCESS
                    }}
                />
            </styled.formControl>
        </styled.container>
    );
};
