import { Flex } from '@chakra-ui/react';
import React from 'react';

import * as styled from './loading-students.styled';
import { CustomSkeleton } from '@components/loading/custom-skeleton/custom-skeleton';
import { LoadingCheckbox } from '@components/loading/loading-route/loading-checkbox/loading-checkbox';
import { CellCountReturn } from '@components/loading/loading-route/loading-route.types';
import { CellDimensions } from '@containers/activity-router/data-grid-container/data-grid-container.styled';

type LoadingStudentsProps = {
    horizontalCellCount: CellCountReturn
    verticalCellCount: CellCountReturn
};

export const LoadingStudents = ({
    horizontalCellCount,
    verticalCellCount
}: LoadingStudentsProps) => {
    return (
        <Flex direction={'column'}>
            <Flex gap={'16px'}>
                {horizontalCellCount.map((key) => <CustomSkeleton {...styled.student} key={key} />)}
            </Flex>
            <Flex>
                {horizontalCellCount.map((key) => {
                    return (
                        <styled.details key={key}>
                            <CustomSkeleton {...styled.assigned} />
                        </styled.details>
                    );
                })}
            </Flex>
            <Flex>
                {horizontalCellCount.map((key) => <LoadingCheckbox key={key} />)}
            </Flex>
            <Flex>
                {horizontalCellCount.map((horizontalKey) => {
                    return (
                        <Flex direction={'column'} key={horizontalKey}>
                            {verticalCellCount.map((verticalKey) => {
                                return (
                                    <Flex key={verticalKey} {...CellDimensions} alignItems={'center'} justifyContent={'center'}>
                                        <CustomSkeleton borderRadius={'12px'} h={'54px'} w={'54px'} />
                                    </Flex>
                                );
                            })}
                        </Flex>
                    );
                })}
            </Flex>
        </Flex>
    );
};
