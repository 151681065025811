import { Box, Flex } from '@chakra-ui/react';
import React, { useMemo } from 'react';

import { convertNumberToPx } from '../../../../style/helpers';
import * as styled from './loading-course.styled';
import { CustomSkeleton } from '@components/loading/custom-skeleton/custom-skeleton';
import { LoadingCheckbox } from '@components/loading/loading-route/loading-checkbox/loading-checkbox';
import { CellCountReturn } from '@components/loading/loading-route/loading-route.types';
import {
    CELL_DIMENSION,
    CellDimensions,
    GridHeaderHeight
} from '@containers/activity-router/data-grid-container/data-grid-container.styled';
import {
    GenericGridFiltersHeight
} from '@containers/activity-router/data-grid-container/generic-grid-filters/generic-grid-filters.styled';

export const LoadingCourse = ({ verticalCellCount }: { verticalCellCount: CellCountReturn }) => {
    const courseHeight = useMemo(() => {
        return convertNumberToPx(verticalCellCount.length * CELL_DIMENSION);
    }, [verticalCellCount]);

    return (
        <Flex>
            <styled.container>
                <Box {...styled.topicFilter} />
                <CustomSkeleton {...styled.course} h={courseHeight} marginTop={'97px'} />
            </styled.container>
            <styled.checkboxContainer>
                <Box h={GenericGridFiltersHeight} minH={`calc(${GridHeaderHeight} + ${CellDimensions.h})`} w={CellDimensions.w} />
                {verticalCellCount.map((key) => <LoadingCheckbox key={key} />)}
            </styled.checkboxContainer>
        </Flex>
    );
};
