/* eslint-disable max-len */
import { CourseTopicProps } from '../../course-topic/course-topic.types';
import {
    GridCounts,
    TopicId
} from '@containers/activity-router/routes/helpers/slice/slice.types';

export enum TopicButtonVariants {
    gridTopicButton = 'gridTopicButton',
    assignModalTopicButton = 'assignModalTopicButton'
}

export type TopicButtonProps =
    Pick<CourseTopicProps,
        'topic' |
        'topicIndex' |
        'strandId' |
        'strandIndex' |
        'strandCode'
        > & {
    topicId: TopicId
    onButtonClickHandler: CourseTopicProps['clickHandlers']['buttonClickHandlers']['onTopicButtonClickHandler']
    onCheckboxClickHandler: CourseTopicProps['clickHandlers']['checkboxClickHandlers']['onTopicCheckboxClickHandler']
    topicCountValue: GridCounts
};
