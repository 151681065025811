import { PayloadAction } from '@reduxjs/toolkit';

import {
    TransformCombinedAssignClassroom,
    TransformCombinedAssignGroup
} from '../../services/gateways/combined-assign-gateway/combined-assign-gateway.types';

export enum ClassGroupFiltersStateConstants {
    name = 'classGroupFilters',
    classroom = 'classroom',
    group = 'group'
}

export type ClassGroupDictionaryItems = Pick<TransformCombinedAssignGroup, 'isRestrictedToAssignmentsOnly'>;

export interface ClassGroupFiltersState {
    [ClassGroupFiltersStateConstants.classroom]: {
        data: Array<TransformCombinedAssignClassroom>
        selected: TransformCombinedAssignClassroom | null
    }
    [ClassGroupFiltersStateConstants.group]: {
        data: Array<TransformCombinedAssignGroup>
        selected: TransformCombinedAssignGroup | null
    }
    dictionary: Record<string, RestrictedDictionary>
}

export type GetSelectedClassroom = {
    data: Array<TransformCombinedAssignClassroom>
    value: number
};

export type GetSelectedGroup = {
    data: Array<TransformCombinedAssignGroup>
    value: number
};

export type ManageRestrict = {
    data: Array<TransformCombinedAssignGroup>
    action: PayloadAction<ClassGroupDictionaryItems>
};

export type ManageRestrictGroups = ManageRestrict & {
    selected: TransformCombinedAssignGroup
    dictionary: RestrictedDictionary
};

export type RestrictedDictionary = Record<string, ClassGroupDictionaryItems>;

export type GetCurrentIsRestrictedToAssignmentsOnly = Pick<ManageRestrictGroups, 'selected' | 'dictionary'> & {
    group: TransformCombinedAssignGroup
    updatedIsRestrictedToAssignmentsOnly: boolean
};
