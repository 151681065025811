import {
    ChallengesDokFilterCourse,
    DokFilterLookup
} from '../../../components/challenges-course-filters/components/dok-filter/dok-filter.types';
import { CourseData } from '@containers/activity-router/data-grid-container/courses/types/courses.types';
import { CheckboxStates } from '@containers/activity-router/data-grid-container/grid/grid.types';
import {
    manageCourseTopicExpandCollapse
} from '@containers/activity-router/routes/helpers/courses/course-expand-helpers';
import { getTopicKey, parseStrandCourseKey } from '@containers/activity-router/routes/helpers/keys/key-generator';

type GetChallengesDokFilteredCourseProps = {
    course: CourseData
    dokCourseItems: ChallengesDokFilterCourse
};

type GetUpdatedCourseOverviewDokInitialStateParams = {
    course: CourseData
    courseLookup: DokFilterLookup
};

export const getUpdatedCourseFilterDokInitialState = (course: CourseData): CourseData => {
    if (!course) return course;

    return manageCourseTopicExpandCollapse({
        course,
        isExpanded: true
    });
};

export const getUpdatedCourseOverviewDokInitialState = ({
    course,
    courseLookup
}: GetUpdatedCourseOverviewDokInitialStateParams): CourseData => {
    if (!course) return course;

    const lookupKeys = Object.keys(courseLookup).map((key) => getTopicKey({ ...parseStrandCourseKey(key) }));

    return {
        ...course,
        topics: course?.topics.map((strand) => {
            const updatedStrand = strand.topics?.map((topic) => {
                const currentTopicKey = getTopicKey({
                    strandId: strand.id,
                    topicId: topic.id
                });

                return {
                    ...topic,
                    isExpanded: lookupKeys.includes(currentTopicKey)
                };
            }) || [];

            return {
                ...strand,
                topics: updatedStrand,
                isExpanded: updatedStrand?.some((topic) => topic.isExpanded)
            };
        }) || []
    };
};

export const getChallengesDokFilteredCourse = ({
    course,
    dokCourseItems
}: GetChallengesDokFilteredCourseProps): CourseData => {
    if (!course) return course;

    return {
        ...course,
        topics: course.topics.map((strand) => {
            const dokStrandItem = dokCourseItems?.find((dokStrand) => dokStrand.id === strand.id);
            const updatedTopics = strand.topics?.map((topic) => {
                const dokTopicItem = dokStrandItem?.topics.find((dokTopic) => dokTopic.id === topic.id);
                const updatedActivities = topic.activities.filter((activity) => {
                    const dokActivityItem = dokTopicItem?.activities.find((dokActivity) => dokActivity.id === activity.id);

                    return dokActivityItem?.checked !== CheckboxStates.unchecked;
                });

                return {
                    ...topic,
                    activities: updatedActivities
                };
            }).filter((topic) => topic !== null && topic.activities.length > 0) || [];

            return {
                ...strand,
                topics: updatedTopics
            };
        }).filter((strand) => strand !== null && strand.topics.length > 0) || []
    };
};
