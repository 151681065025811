import {
    chakra,
    Box,
    Button
} from '@chakra-ui/react';

export const icon = chakra(Button, {
    label: 'info-popover-icon',
    baseStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '100px',
        h: '40px',
        w: '40px'
    }
});

export const modalContainer = chakra(Box, {
    label: 'info-popover-modal-container',
    baseStyle: {
        backgroundColor: 'white.0',
        border: '1px solid',
        borderColor: 'neutral.200',
        borderRadius: '16px',
        color: 'neutral.700',
        fontSize: 'sm',
        p: '16px',
        w: '320px'
    }
});
