import React from 'react';

import * as styled from './loading-route.styled';
import { LoadingRouteProps } from './loading-route.types';
import { LoadingFooter } from '@components/loading/loading-route/loading-footer/loading-footer';
import { LoadingGrid } from '@components/loading/loading-route/loading-grid/loading-grid';

export const LoadingRoute = ({
    studentListLength,
    courseLength,
    isLoadingApp = false
} : LoadingRouteProps) => {
    const gridStyles = isLoadingApp ? { paddingTop: 3 } : {};

    return (
        <styled.container>
            <styled.grid {...gridStyles}>
                <LoadingGrid courseLength={courseLength} studentListLength={studentListLength} />
            </styled.grid>
            <LoadingFooter />
        </styled.container>
    );
};
