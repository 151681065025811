import { chakra, Flex } from '@chakra-ui/react';

import { PagePadding } from '../../../style/constants';
import { CLASS_GROUP_FILTERS_CONTAINER_HEIGHT } from '@containers/class-group-filters/class-group-filters.styled';
import {
    NAVIGATION_CONTAINER_WIDTH, NAVIGATION_GROUP_BUTTON_HEIGHT
} from '@containers/navigation/activity-groups-tabs/activity-group-tabs.styled';
import { ACTIVITY_TYPE_TABS_HEIGHT } from '@containers/navigation/activity-type-tabs/activity-type-tabs.styled';

export const container = chakra(Flex, {
    label: 'loading-app-container',
    baseStyle: {
        flexDirection: 'column',
        w: '100%',
        overflow: 'scroll'
    }
});

export const wrapper = chakra(Flex, {
    label: 'loading-app-wrapper',
    baseStyle: {
        paddingLeft: PagePadding.left,
        paddingRight: PagePadding.left,
        alignItems: 'center',
        backgroundColor: 'whiteAlpha.500',
        flexDirection: 'column',
        w: '100%'
    }
});

export const header = {
    borderRadius: '12px',
    h: CLASS_GROUP_FILTERS_CONTAINER_HEIGHT,
    marginTop: '84px',
    w: '100%'
};

export const navigation = {
    borderRadius: '100px',
    h: {
        base: `calc(${NAVIGATION_GROUP_BUTTON_HEIGHT.base} + 10px)`,
        tablet: `calc(${NAVIGATION_GROUP_BUTTON_HEIGHT.tablet} + 10px)`
    },
    marginTop: '22px',
    w: NAVIGATION_CONTAINER_WIDTH
};

export const activityTypes = {
    paddingLeft: PagePadding.left,
    paddingRight: PagePadding.left,
    borderTopLeftRadius: '12px',
    borderTopRightRadius: '12px',
    h: ACTIVITY_TYPE_TABS_HEIGHT,
    marginTop: '42px',
    w: '100%'
};
