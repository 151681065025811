import {
    RouteHelpersModalConstants
} from '@containers/activity-router/routes/helpers/modal/route-modal-helpers.types';
import { DefaultState } from '@containers/activity-router/routes/helpers/slice/slice.types';
import {
    TransformedNewCoursesAssignments
} from '@services/gateways/combined-assign-gateway/combined-assign-gateway.types';

export enum NewCoursesConstants {
    name = 'newCourses'
}

export type NewCoursesState = DefaultState & {
    assignments: {
        grid: TransformedNewCoursesAssignments
    }
    modal: RouteHelpersModalConstants | null
};
