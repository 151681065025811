import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
    AuthGatewayBody,
    AuthGatewayProps
} from '@services/gateways/authentication-gateway/authentication-gateway.types';

export const authenticationGatewayApi = createApi({
    reducerPath: 'authenticationGatewayApi',
    baseQuery: fetchBaseQuery({
        baseUrl: window._env_?.AUTHENTICATION_GATEWAY_URL || '',
        prepareHeaders: (headers) => {
            headers.set('Accept', 'application/json');
            headers.set('Content-Type', 'application/json');

            return headers;
        }
    }),
    endpoints: (builder) => ({
        getAuthData: builder.mutation<AuthGatewayProps, AuthGatewayBody>({
            query: ({ username, password }) => ({
                url: '/sessions',
                method: 'POST',
                body: {
                    username,
                    password
                }
            })
        })
    })
});

export const { useGetAuthDataMutation } = authenticationGatewayApi;
