import React from 'react';
import ReactDOM from 'react-dom/client';

import AppInit from './containers/app/app-init/app-init';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <AppInit />
    </React.StrictMode>
);
