import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit';

import { RootState } from '../../config/react-redux/store';
import { TransformCombinedAssignInitData } from '../../services/gateways/combined-assign-gateway/combined-assign-gateway.types';
import { WHOLE_CLASS_GROUP_ID } from './class-group-filters.constants';
import {
    ClassGroupFiltersState,
    ClassGroupFiltersStateConstants,
    ClassGroupDictionaryItems
} from './class-group-filters.types';
import {
    manageWholeClassRestrict,
    manageGroupRestrict
} from './helpers/class-group-restrict-access-helpers';
import {
    getNextValidGroup,
    getSelectedClassroom,
    getSelectedGroup
} from './helpers/class-group-selected-helpers';

const {
    classroom,
    group,
    name
} = ClassGroupFiltersStateConstants;
const initialState: ClassGroupFiltersState = {
    [classroom]: {
        data: [],
        selected: null
    },
    [group]: {
        data: [],
        selected: null
    },
    dictionary: {}
};

export const classGroupFiltersSlice = createSlice({
    name,
    initialState,
    reducers: {
        setClassGroupFiltersInitialData (state, action: PayloadAction<TransformCombinedAssignInitData>) {
            const selectedClass = getSelectedClassroom({
                data: action.payload.classrooms,
                value: action.payload.selectedClassroomId
            });
            const selectedGroup = getSelectedGroup({
                data: selectedClass.groups,
                value: action.payload.selectedGroupId
            });

            state[classroom].data = action.payload.classrooms;
            state[classroom].selected = selectedClass;
            state[group].data = selectedClass.groups;
            state[group].selected = selectedGroup;
        },
        setClassGroupFiltersClassroom (state, action: PayloadAction<number>) {
            const updatedClassroom = getSelectedClassroom({
                data: state[classroom].data,
                value: action.payload
            });

            state[classroom].selected = updatedClassroom;
            state[group].data = updatedClassroom.groups;
            // eslint-disable-next-line prefer-destructuring
            state[group].selected = getNextValidGroup({
                groups: updatedClassroom.groups
            });
        },
        setClassGroupFiltersGroup (state, action: PayloadAction<number>) {
            const updatedGroup = getSelectedGroup({
                data: state[group].data,
                value: action.payload
            });

            state[group].selected = updatedGroup;
        },
        updateClassGroupRestrictedDictionary (state, action: PayloadAction<ClassGroupDictionaryItems>) {
            const {
                data,
                selected
            } = state[group];
            const selectedClassroom = state[classroom].selected;

            if (selectedClassroom && selected) {
                const { id: classroomId } = selectedClassroom;
                const { id: groupId } = selected;
                const updatedDictionaryItems =
                    groupId === WHOLE_CLASS_GROUP_ID ?
                        manageWholeClassRestrict({
                            data,
                            action
                        }) :
                        manageGroupRestrict({
                            data,
                            action,
                            selected,
                            dictionary: state.dictionary[classroomId] || {}
                        });

                state.dictionary[classroomId] = {
                    ...state.dictionary[classroomId],
                    ...updatedDictionaryItems
                };
            }
        },
        updateClassGroupConquestId (state, action: PayloadAction<any>) {
            const updatedGroup = getSelectedGroup({
                data: state[group].data,
                value: action.payload.groupId
            });
            const updatedClassroom = getSelectedClassroom({
                data: state[classroom].data,
                value: state[classroom].selected?.id || 0
            });

            if (updatedClassroom) {
                state[classroom].data = state[classroom].data.map((classroom) => {
                    if (classroom.id === updatedClassroom.id) {
                        return {
                            ...classroom,
                            groups: classroom.groups.map((group) => {
                                if (group.id === action.payload.groupId) {
                                    group.conquestCourseId = action.payload.conquestCourseId;
                                }

                                return group;
                            })
                        };
                    }

                    return classroom;
                });
                updatedClassroom.groups = updatedClassroom.groups.map((group) => {
                    if (group.id === action.payload.groupId) {
                        group.conquestCourseId = action.payload.conquestCourseId;
                    }

                    return group;
                });
            }

            if (updatedGroup) {
                updatedGroup.conquestCourseId = action.payload.conquestCourseId;
                state[group].selected = updatedGroup;
                state[group].data = state[group].data.map((item) => {
                    if (item.id === action.payload.groupId) return updatedGroup;

                    return item;
                });
            }
        }
    }
});

/**
 * ACTIONS
 */
export const {
    setClassGroupFiltersInitialData,
    setClassGroupFiltersClassroom,
    setClassGroupFiltersGroup,
    updateClassGroupRestrictedDictionary,
    updateClassGroupConquestId
} = classGroupFiltersSlice.actions;

/**
 * SELECTORS
 */
export const selectClassGroupFilterClassroom = (state: RootState) => state[name][classroom];

export const selectClassGroupFilterClassroomSelectedId = (state: RootState) => {
    if (state.classGroupFilters.classroom.selected) return state.classGroupFilters.classroom.selected.id;

    return null;
};

export const selectClassGroupFilterGroup = (state: RootState) => state[name][group];

export const selectClassGroupFilterGroupSelectedId = (state: RootState) => {
    if (state.classGroupFilters.group.selected) return state.classGroupFilters.group.selected.id;

    return null;
};

export const selectClassGroupDictionary = (state: RootState) => state[name].dictionary;

export const selectClassGroupClassicCourseId = (state: RootState) => state[name][group].selected?.classicCourseId || 0;

export const selectClassGroupConquestCourseId = (state: RootState) => state[name][group].selected?.conquestCourseId || '0';

export const selectClassGroupUpfCourseId = (state: RootState) => state[name][group].selected?.upfCourseId || 0;

export const selectClassGroupClassroomSelectedYearGroup = (state: RootState) => {
    const { selected } = state[name][classroom];

    return selected ? selected.yearGroup : null;
};

export default classGroupFiltersSlice.reducer;
