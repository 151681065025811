import { checkboxBaseStyle } from '../checkbox-base';
import {
    GridCheckboxesVariants
} from '@containers/activity-router/data-grid-container/grid/grid-checkbox/grid-checkbox.types';

export const GridCheckbox = {
    [GridCheckboxesVariants.activityCheckbox]: {
        control: {
            ...checkboxBaseStyle,
            w: '24px',
            h: '24px',
            svg: { width: '16px' }
        }
    },
    [GridCheckboxesVariants.studentCheckbox]: {
        control: {
            ...checkboxBaseStyle,
            w: '32px',
            h: '32px'
        }
    },
    [GridCheckboxesVariants.topicCheckbox]: {
        borderRight: '1px solid',
        borderColor: 'neutral.200',
        control: {
            ...checkboxBaseStyle,
            w: '32px',
            h: '32px',
            svg: { width: '20px' }
        }
    },
    [GridCheckboxesVariants.assignedCheckbox]: {
        control: {
            ...checkboxBaseStyle,
            w: '24px',
            h: '24px',
            svg: { width: '16px' }
        }
    },
    [GridCheckboxesVariants.dokCheckbox]: {
        control: {
            ...checkboxBaseStyle,
            w: '24px',
            h: '24px',
            svg: { width: '16px' }
        },
        input: {
            width: '14px',
            height: '14px'
        }
    },
    [GridCheckboxesVariants.circleCheckbox]: {
        control: {
            ...checkboxBaseStyle,
            w: '32px',
            h: '32px',
            borderRadius: '48px',
            svg: { width: '20px' }
        },
        input: {
            width: '14px',
            height: '14px'
        }
    }
};
