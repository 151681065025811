import React, { Suspense, lazy } from 'react';
import {
    Routes,
    Route
} from 'react-router-dom';

import { AppProviders } from '../app-providers/app-providers';
import * as styled from './app-init.styled';
import { AppInitRoutePaths } from './app-init.types';
import { BuildNumber } from '@components/build-number/build-number';
import { LoadingApp } from '@components/loading/loading-app/loading-app';

const AppLanding = lazy(() => import('../app-landing/app-landing'));
const EmptyState = lazy(() => import('../../empty-state/empty-state'));
const AppInit = () => {
    return (
        <AppProviders>
            <styled.container>
                <Suspense fallback={<LoadingApp />}>
                    <Routes>
                        <Route element={<AppLanding />} path={AppInitRoutePaths.landing} />
                        <Route element={<EmptyState />} path={AppInitRoutePaths.empty} />
                    </Routes>
                </Suspense>
            </styled.container>
            <BuildNumber />
        </AppProviders>
    );
};

export default AppInit;
