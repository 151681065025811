import React, { useCallback, useEffect, useRef, useState } from 'react';

import { getWindowWidth } from '../../../../style/constants';
import { LoadingRouteProps, CellCountReturn } from '../loading-route.types';
import * as styled from './loading-grid.styled';
import { LoadingCourse } from '@components/loading/loading-route/loading-course/loading-course';
import { LoadingStudents } from '@components/loading/loading-route/loading-students/loading-students';
import { CELL_DIMENSION } from '@containers/activity-router/data-grid-container/data-grid-container.styled';
import { useWindowResize } from '@hooks/use-window-resize/use-window-resize';

export const LoadingGrid = ({ courseLength, studentListLength }: LoadingRouteProps) => {
    const loadingGridRef: any = useRef(null);
    const [verticalCellCount, setVerticalCellCount] = useState<CellCountReturn>([]);
    const [horizontalCellCount, setHorizontalCellCount] = useState<CellCountReturn>([]);
    const generateArray = (length: number) => Array.from({ length }, (_, index) => index);
    const calculateVerticalCellCount = useCallback(() => {
        if (courseLength) {
            const minimumCourseLength = Math.max(courseLength, 4);

            setVerticalCellCount(generateArray(minimumCourseLength));
        } else if (loadingGridRef.current) {
            // Buffer to cater for page padding and other elements.
            const elementBuffer = 190;
            const { offsetTop } = loadingGridRef.current;
            const windowHeight = window.innerHeight;
            const updatedViewportHeight = windowHeight - elementBuffer - offsetTop;
            const count = Math.ceil(updatedViewportHeight / CELL_DIMENSION);

            setVerticalCellCount(generateArray(count));
        }
    }, [courseLength]);
    const calculateHorizontalCellCount = useCallback(() => {
        if (studentListLength) {
            setHorizontalCellCount(generateArray(studentListLength));
        } else {
            // Buffer to cater for page padding and other elements.
            const elementBuffer = 800;
            const count = Math.ceil((getWindowWidth() - elementBuffer) / CELL_DIMENSION);

            setHorizontalCellCount(generateArray(count));
        }
    }, [studentListLength]);

    useWindowResize(() => {
        calculateVerticalCellCount();
        calculateHorizontalCellCount();
    });
    useEffect(() => {
        calculateVerticalCellCount();
        calculateHorizontalCellCount();
    }, [calculateHorizontalCellCount, calculateVerticalCellCount]);

    return (
        <styled.grid ref={loadingGridRef}>
            <LoadingCourse verticalCellCount={verticalCellCount} />
            <LoadingStudents
                horizontalCellCount={horizontalCellCount}
                verticalCellCount={verticalCellCount}
            />
        </styled.grid>
    );
};
