import {
    Box,
    chakra,
    Input,
    PopoverBody,
    PopoverContent,
    VStack
} from '@chakra-ui/react';

import { PortalHeaderHeight } from '../../../../../../style/constants';

export const topicFilterInputValue = 40;

export const topicFilterHeight = `calc(600px - ${PortalHeaderHeight})`;

export const topicFilterBodyPaddingX = '24px';

export const topicFilterInputWidth = {
    base: '287px',
    tablet: '332px'
};

export const container = chakra(Box, {
    label: 'topic-filter-container',
    baseStyle: {
        position: 'relative',
        margin: '0 !important',
        paddingTop: '65px'
    }
});

export const popoverContainer = chakra(Box, {
    label: 'topic-filter-popover-container',
    baseStyle: {
        w: '100%'
    }
});

export const input = chakra(Input, {
    label: 'topic-filter-input',
    baseStyle: {
        w: topicFilterInputWidth,
        h: '40px',
        borderRadius: '6px',
        borderColor: 'neutral.300',
        color: 'neutral.600',
        fontSize: 'sm',
        outline: 'none',
        outlineStyle: 'none',
        boxShadow: 'none',
        _hover: {
            backgroundColor: 'neutral.50'
        }
    }
});

export const popoverContent = chakra(PopoverContent, {
    label: 'topic-filter-popover-content',
    baseStyle: {
        width: '100%',
        border: 'none'
    }
});

export const popoverBody = chakra(PopoverBody, {
    label: 'topic-filter-popover-body',
    baseStyle: {
        width: '382px',
        backgroundColor: 'white.0',
        border: '1px solid',
        borderColor: 'neutral.100',
        borderRadius: '12px',
        p: 0,
        boxShadow: '0px 20px 25px -5px rgba(0, 25, 55, 0.1), 0px 10px 10px -5px rgba(0, 25, 55, 0.04)'
    }
});

export const popoverBodyContainer = chakra(VStack, {
    label: 'topic-filter-popover-body-container',
    baseStyle: {
        height: '100%',
        justifyContent: 'space-between'
    }
});
