import { useEffect } from 'react';

export const useWindowResize = (callback: () => void) => {
    useEffect(() => {
        const handleResize = () => {
            callback();
        };
        const handleOrientationChange = () => {
            // Delay to allow time for orientation change to complete
            setTimeout(callback, 100);
        };

        window.addEventListener('resize', handleResize);
        window.addEventListener('orientationchange', handleOrientationChange);

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('orientationchange', handleOrientationChange);
        };
    }, [callback]);
};
