import {
    getActivityKey,
    getStudentAssignmentKey,
    parseActivityKey,
    parseStudentAssignmentKey
} from '../../../../../../helpers/keys/key-generator';
import {
    GetActivitiesAssignmentIsSelectedParams,
    GetActivityCellAssignmentsParams,
    GetTopicCellAssignmentsParams,
    UpdateActivitiesAssignmentsParams,
    ManageGridAssignmentsProps,
    ManageActivitiesSelectedAssignmentsProps,
    GetActivitiesSelectedAssignmentParams,
    GetActivitiesAssignmentActionParams,
    GetActivitiesAssignReassignProps,
    GetActivitiesCurrentAssignmentProps
} from './assignments-selected-helpers.types';
import {
    updateRouteSelectedAssignment
} from '@containers/activity-router/routes/helpers/assignment/assignment-selected/route-assignments-selected';
import { AssignmentActions } from '@containers/activity-router/routes/helpers/slice/slice.types';

const {
    assign,
    reassign,
    unassign,
    modify
} = AssignmentActions;

function getAssignReassignAction ({ result }: GetActivitiesAssignReassignProps) {
    return result.score === null ? [assign] : [reassign];
}

function getAssignmentAction ({
    assignment,
    result
}: GetActivitiesAssignmentActionParams) {
    if (!assignment && !result) return [assign];
    if (!assignment && result) return getAssignReassignAction({ result });
    if (assignment && !result) return [modify, unassign];
    if (assignment && result) return [modify, unassign];
}

export function getActivitiesSelectedAssignments ({
    currentAssignment,
    selectedAssignments,
    ...params
}: GetActivitiesSelectedAssignmentParams) {
    const assignmentType = getAssignmentAction(currentAssignment);

    assignmentType?.forEach((assignmentAction) => {
        selectedAssignments = updateRouteSelectedAssignment({
            selectedAssignments,
            assignmentAction,
            ...params
        });
    });

    return selectedAssignments;
}

function getCurrentAssignment ({
    activityKey,
    studentKey,
    studentAssignments
}: GetActivitiesCurrentAssignmentProps) {
    const {
        id,
        activityType
    } = parseActivityKey(activityKey);
    const assignmentKey = getStudentAssignmentKey({
        userProfileId: studentKey,
        activityType,
        activityId: id
    });

    return studentAssignments[assignmentKey];
}

export function manageActivitiesSelectedAssignments ({
    selectedAssignments,
    activityKey,
    studentKey,
    studentAssignments,
    isSelected
}: ManageActivitiesSelectedAssignmentsProps) {
    const currentAssignment = getCurrentAssignment({
        activityKey,
        studentKey,
        studentAssignments
    });

    selectedAssignments = getActivitiesSelectedAssignments({
        currentAssignment,
        selectedAssignments,
        activityKey,
        isSelected,
        userProfileId: studentKey
    });

    return selectedAssignments;
}

function getTopicCellAssignments ({
    gridCells,
    topicId,
    selectedAssignments,
    isSelected,
    student: { userProfileId },
    studentAssignments
}: GetTopicCellAssignmentsParams) {
    const userProfileIdToString = userProfileId.toString();

    Object.keys(gridCells[userProfileId][topicId]).forEach((activityKey) => {
        selectedAssignments = manageActivitiesSelectedAssignments({
            selectedAssignments,
            activityKey,
            studentKey: userProfileIdToString,
            studentAssignments,
            isSelected
        });
    });

    return selectedAssignments;
}

function getActivityCellAssignments ({
    activityId,
    student,
    studentAssignments,
    selectedAssignments,
    isSelected
}: GetActivityCellAssignmentsParams) {
    if (activityId) {
        const userProfileIdToString = student.userProfileId.toString();

        selectedAssignments = manageActivitiesSelectedAssignments({
            selectedAssignments,
            activityKey: activityId,
            studentKey: userProfileIdToString,
            studentAssignments,
            isSelected
        });

        return selectedAssignments;
    }

    return selectedAssignments;
}

export function manageActivitiesCellAssignmentsSelected ({
    activityId,
    activityType,
    student,
    topicId,
    isSelected,
    studentAssignments,
    gridCells,
    selectedAssignments
}: ManageGridAssignmentsProps) {
    if (!activityId) {
        selectedAssignments = getTopicCellAssignments({
            gridCells,
            topicId,
            selectedAssignments,
            isSelected,
            studentAssignments,
            student
        });
    } else if (activityId !== null && activityType !== null) {
        selectedAssignments = getActivityCellAssignments({
            activityId,
            student,
            studentAssignments,
            selectedAssignments,
            isSelected
        });
    }

    return selectedAssignments;
}

function getActivitiesAssignmentIsSelected ({
    activityKey,
    studentId,
    isValidAssignment,
    gridCells
}: GetActivitiesAssignmentIsSelectedParams) {
    let updatedIsSelected = false;

    if (isValidAssignment) {
        Object.keys(gridCells).forEach((gridStudentKey) => {
            if (gridStudentKey === studentId) {
                Object.keys(gridCells[gridStudentKey]).forEach((gridTopicKey) => {
                    Object.keys(gridCells[gridStudentKey][gridTopicKey]).forEach((gridActivityKey) => {
                        if (activityKey === gridActivityKey) {
                            const { isSelected } = gridCells[gridStudentKey][gridTopicKey][gridActivityKey];

                            updatedIsSelected = isSelected;
                        }
                    });
                });
            }
        });
    }

    return updatedIsSelected;
}

export function updateActivitiesAssignments ({
    updatedStudentAssignments,
    selectedAssignments,
    gridCells
}: UpdateActivitiesAssignmentsParams) {
    Object.keys(updatedStudentAssignments).forEach((studentAssignmentKey) => {
        const {
            studentId,
            activityId,
            activityType
        } = parseStudentAssignmentKey(studentAssignmentKey);
        const activityKey = getActivityKey({
            id: activityId,
            activityType
        });
        const currentAssignment = updatedStudentAssignments[studentAssignmentKey];
        const assignmentType = getAssignmentAction(currentAssignment);

        Object.keys(selectedAssignments).forEach((assignmentActionKey) => {
            const assignmentAction = assignmentActionKey as AssignmentActions;
            const isSelected = getActivitiesAssignmentIsSelected({
                activityKey,
                studentId,
                isValidAssignment: assignmentType?.includes(assignmentAction) || false,
                gridCells
            });

            selectedAssignments = updateRouteSelectedAssignment({
                selectedAssignments,
                assignmentAction,
                activityKey,
                userProfileId: studentId,
                isSelected
            });
        });
    });

    return selectedAssignments;
}
