import { AssignedActivitiesStudentDataUser } from './view-assigned-activities-modal.types';
import { CourseData } from '@containers/activity-router/data-grid-container/courses/types/courses.types';
import { parseTopicKey, TopicKeyParams } from '@containers/activity-router/routes/helpers/keys/key-generator';

type GetInitialFilteredCourseDataProps = {
    course: CourseData
    selectedStudentData: AssignedActivitiesStudentDataUser
    persistExpanded?: boolean
};

export function getInitialFilteredCourseData ({
    course,
    selectedStudentData,
    persistExpanded = false
}: GetInitialFilteredCourseDataProps) {
    if (!course || !selectedStudentData) return null;
    const isStrand = course && course?.topics[0].topics !== null;
    const shouldRenderItem = (currentId: string, keyParam: keyof TopicKeyParams) =>
        Object.keys(selectedStudentData).some((key) => currentId === parseTopicKey(key)[keyParam]);

    if (isStrand) {
        let filteredStrands = course.topics.filter((strand) => shouldRenderItem(strand.id, 'strandId'));

        const isSingleStrand = filteredStrands.length <= 1;

        filteredStrands = filteredStrands.map((strand, strandIndex) => {
            let filteredTopics = strand.topics?.filter((topic) => shouldRenderItem(topic.id, 'topicId')) || null;

            filteredTopics = filteredTopics?.map((topic, topicIndex) => {
                return {
                    ...topic,
                    isExpanded: persistExpanded ? topic.isExpanded : (strandIndex === 0 && topicIndex === 0)
                };
            }) || null;

            return {
                ...strand,
                isExpanded: isSingleStrand ? true : persistExpanded ? strand.isExpanded : (strandIndex === 0),
                topics: filteredTopics
            };
        });

        return {
            ...course,
            topics: filteredStrands
        };
    } else {
        let filteredTopics = course.topics.filter((topic) => shouldRenderItem(topic.id, 'topicId'));

        const isSingleTopic = filteredTopics.length <= 1;

        filteredTopics = filteredTopics.map((topic, topicIndex) => {
            return {
                ...topic,
                isExpanded: isSingleTopic? true : persistExpanded ? topic.isExpanded : (topicIndex === 0)
            };
        });

        return {
            ...course,
            topics: filteredTopics
        };
    }
}
