import { CourseTypes } from '@services/gateways/combined-assign-gateway/api/combined-assign-gateway-api.types';

export enum ActivityGroupPaths {
    learn = 'learn',
    revise = 'revise',
    assess = 'assess'
}

export enum ActivityTypePaths {
    newCourses = 'newCourses',
    activities = 'activities',
    challenges = 'challenges',
    skillQuests = 'skillQuests',
    revise5 = 'revise5',
    assessments = 'assessments',
    tests = 'tests'
}

export type ActivityItemId = string | number;

export type ActivityPortalId = string | null;

export type ActivityItem = {
    label: string
    id: ActivityItemId
    isHidden: boolean
    isNew: boolean
    isDefault: boolean
    path: string
    portalId: string | null
};

export type NavigationConfigurationItem = {
    type: {
        label: string
        id: string
        portalId: ActivityPortalId
    }
    isDefault: boolean
    activities: Array<ActivityItem>
};

export type GetActivityTypes = {
    [key: string]: ActivityItem
};

export type OnNavigationClickInterceptor = {
    portalId: ActivityPortalId
    callback: any
};

export type NavigationProps = {
	label: string
	id: CourseTypes | string
	isHidden: boolean
	isNew: boolean
	isDefault: boolean
	path: ActivityTypePaths
	portalId: string | null
	isNewCoursesVisible?: boolean
};
