import {
    RouteModalState
} from '@containers/activity-router/routes/helpers/modal/route-modal-helpers.types';
import {
    ActivityId,
    DefaultState,
    StudentId
} from '@containers/activity-router/routes/helpers/slice/slice.types';
import {
    TotalAssignments,
    TransformedStudentAssignments,
    TransformedTotalAssignments
} from '@services/gateways/classroom-report-gateway/classroom-report-gateway.types';
import { ActivitiesComplexity } from '@services/gateways/combined-assign-gateway/combined-assign-gateway.types';

export enum ActivitiesConstants {
    name = 'activities'
}

export type ActivitiesTopic = {
    id: string
    name: string
    isChecked: boolean
};

export type ActivitiesTopics = Array<ActivitiesTopic>;

export type ActivitiesAssignmentGrid = {
    totalAssignments: TransformedTotalAssignments
    studentAssignments: TransformedStudentAssignments
};

const {
    all,
    core,
    easier
} = ActivitiesComplexity;

export type ActivitiesFilterComplexityCounters = {
    [all]: Record<ActivityId, Array<StudentId>>
    [core]: Record<ActivityId, Array<StudentId>>
    [easier]: Record<ActivityId, Array<StudentId>>
};

export type ActivitiesState = DefaultState & {
    filters: {
        complexity: {
            selected: ActivitiesComplexity
            counters: ActivitiesFilterComplexityCounters
        }
        topics: ActivitiesTopics
        isExpanded: boolean
    }
    assignments: {
        grid: ActivitiesAssignmentGrid
    }
    modal: RouteModalState
};

export type TopicsTotalAssignment = {
    topicId: number
    assignedActivityTotal: TotalAssignments['assignedActivityTotal']
    averageResultScore: TotalAssignments['averageResultScore']
};

export type UpdateActivitiesAssignments = {
    updatedStudentAssignments: TransformedStudentAssignments
};
