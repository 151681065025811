import { useEffect } from 'react';

import { useEnvConfig } from '../../env/env-config-provider';

type ExistingScriptProps = {
    scriptId: string
    id: string
    isScriptAdded: boolean
};

type ManageScriptingProps = {
    id: string
    scriptIdText: string
    commentText: string
    callback: (params: ExistingScriptProps) => boolean
};

export const useGoogleTagManager = () => {
    const {
        GOOGLE_ANALYTICS_ID,
        GOOGLE_TAG_MANAGER_ID,
        ENVIRONMENT
    } = useEnvConfig();

    function manageScripting ({
        id,
        scriptIdText,
        commentText,
        callback
    }: ManageScriptingProps) {
        if (id) {
            const ids = id.split(',');
            const comment = document.createComment(commentText);

            let isScriptAdded = false;

            for (const id of ids) {
                const scriptId = `${scriptIdText}-${id}`;
                const existingScript = document.getElementById(scriptId);

                if (!existingScript) {
                    isScriptAdded = callback({
                        scriptId,
                        id,
                        isScriptAdded
                    });
                }
            }

            if (isScriptAdded) document.body.prepend(comment);
        }
    }

    function manageGoogleAnalyticsId ({
        scriptId,
        id,
        isScriptAdded
    }: ExistingScriptProps) {
        const script2 = document.createElement('script');

        script2.text = `
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', '${id}');`;
        document.body.prepend(script2);

        const script = document.createElement('script');

        script.id = scriptId;
        script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
        script.async = true;
        document.body.prepend(script);
        isScriptAdded = true;

        return isScriptAdded;
    }

    function manageGoogleTagManagerId ({
        scriptId,
        id,
        isScriptAdded
    }: ExistingScriptProps) {
        const noscript = document.createElement('noscript');
        const iframe = document.createElement('iframe');

        iframe.src = `https://www.googletagmanager.com/ns.html?id=${id}`;
        iframe.setAttribute('height', String(0));
        iframe.setAttribute('width', String(0));
        iframe.setAttribute('style', 'display:none;visibility:hidden');
        noscript.appendChild(iframe);
        const script = document.createElement('script');

        document.body.prepend(noscript);
        script.id = scriptId;
        script.text = `
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
                    var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;
                    j.src='//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','${id}');
            
                    window.onpageshow = function (event) {
                    if (event.persisted) {
                    window.location.reload()
                    }};`;
        document.body.prepend(script);
        isScriptAdded = true;

        return isScriptAdded;
    }

    useEffect(() => {
        if (ENVIRONMENT !== 'local') {
            manageScripting({
                id: GOOGLE_ANALYTICS_ID,
                scriptIdText: 'googleAnalytics4Id',
                commentText: 'Google analytics',
                callback: manageGoogleAnalyticsId
            });
            manageScripting({
                id: GOOGLE_TAG_MANAGER_ID,
                scriptIdText: 'googleTagManagerId',
                commentText: 'Google analytics 4',
                callback: manageGoogleTagManagerId
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [GOOGLE_ANALYTICS_ID, GOOGLE_TAG_MANAGER_ID]);
};
