import {
    GridCountsActivities, StrandId,
    TopicId
} from '../../../../routes/helpers/slice/slice.types';
import { AdditionalProps } from '../../types/courses-topic.types';
import {
    CourseActivityCheckBoxClickHandler
} from '../../types/courses.types';
import { GetActivityKeyReturn } from '@containers/activity-router/routes/helpers/keys/key-generator';
import {
    CombinedAssignActivities
} from '@services/gateways/combined-assign-gateway/combined-assign-gateway.types';

export enum ActivityButtonVariants {
    gridActivityButton = 'gridActivityButton',
    assignModalActivityButton = 'assignModalActivityButton'
}

export type ActivityButtonProps = {
    activity: CombinedAssignActivities
    keyValue: string
    onActivityCheckboxClickHandler: CourseActivityCheckBoxClickHandler
    getActivityKey: GetActivityKeyReturn
    activityCountValue: GridCountsActivities
    topicId: TopicId
    strandId?: StrandId
    strandIndex?: number
    additionalProps?: AdditionalProps
};
