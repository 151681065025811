import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit';

import { RootState } from '../react-redux/store';
import {
    AuthConstants,
    AuthState
} from './auth.types';

const initialState: AuthState = { token: null };

export const authSlice = createSlice({
    name: AuthConstants.name,
    initialState,
    reducers: {
        setAuthStateToken (state, action: PayloadAction<string>) {
            state.token = action.payload;
        }
    }
});

/**
 * ACTIONS
 */
export const { setAuthStateToken } = authSlice.actions;

/**
 * SELECTORS
 */
export const selectAuthStateToken = (state: RootState) => state[AuthConstants.name].token;

export default authSlice.reducer;
