import {
    GetChallengesAssignmentActionParams,
    GetChallengesCurrentAssignmentParams,
    GetChallengesSelectedAssignmentsParams, ManageChallengesCellAssignmentsSelectedParams,
    ManageChallengesSelectedAssignmentsProps,
    UpdateChallengesAssignmentsParams
} from './challenges-assignments-selected-helpers.types';
import {
    updateRouteSelectedAssignment
} from '@containers/activity-router/routes/helpers/assignment/assignment-selected/route-assignments-selected';
import { parseActivityKey } from '@containers/activity-router/routes/helpers/keys/key-generator';
import { AssignmentActions } from '@containers/activity-router/routes/helpers/slice/slice.types';

const {
    assign,
    reassign,
    unassign,
    modify
} = AssignmentActions;

function getChallengesCurrentAssignment ({
    activityKey,
    studentKey,
    studentAssignments
}: GetChallengesCurrentAssignmentParams) {
    const { id } = parseActivityKey(activityKey);
    const selectedAssignment = studentAssignments[studentKey]?.assignments.find((item) => item.activityId === id) || null;

    return selectedAssignment || null;
}

function getAssignmentAction ({ currentAssignment }: GetChallengesAssignmentActionParams) {
    const isAssignAction =
        !currentAssignment ||
        (!currentAssignment.currentAssignmentId && !currentAssignment.resultAccuracy);

    if (isAssignAction) return [assign];
    if (currentAssignment.currentAssignmentId) return [unassign, modify];
    if (currentAssignment.resultAccuracy) return [reassign];
}

function getChallengesSelectedAssignments ({
    currentAssignment,
    selectedAssignments,
    ...params
}: GetChallengesSelectedAssignmentsParams) {
    const assignmentType = getAssignmentAction({ currentAssignment });

    assignmentType?.forEach((assignmentAction) => {
        selectedAssignments = updateRouteSelectedAssignment({
            selectedAssignments,
            assignmentAction,
            ...params
        });
    });

    return selectedAssignments;
}

export function manageChallengesSelectedAssignments ({
    selectedAssignments,
    activityKey,
    studentKey,
    studentAssignments,
    isSelected
}: ManageChallengesSelectedAssignmentsProps) {
    const currentAssignment = getChallengesCurrentAssignment({
        activityKey,
        studentKey,
        studentAssignments
    });

    selectedAssignments = getChallengesSelectedAssignments({
        currentAssignment,
        selectedAssignments,
        activityKey,
        isSelected,
        userProfileId: studentKey
    });

    return selectedAssignments;
}

export function manageChallengesCellAssignmentsSelected ({
    activityId,
    activityType,
    student: { userProfileId },
    topicId,
    gridCells,
    selectedAssignments,
    ...params
}: ManageChallengesCellAssignmentsSelectedParams) {
    const studentKey = userProfileId.toString();

    if (!activityId) {
        Object.keys(gridCells[userProfileId][topicId]).forEach((activityKey) => {
            selectedAssignments = manageChallengesSelectedAssignments({
                selectedAssignments,
                activityKey,
                studentKey,
                ...params
            });
        });
    } else if (activityType !== null) {
        selectedAssignments = manageChallengesSelectedAssignments({
            selectedAssignments,
            activityKey: activityId,
            studentKey,
            ...params
        });
    }

    return selectedAssignments;
}

export function updateChallengesAssignments ({
    studentAssignments,
    selectedAssignments,
    gridCells
}: UpdateChallengesAssignmentsParams) {
    selectedAssignments = {
        [assign]: {},
        [reassign]: {},
        [unassign]: {},
        [modify]: {}
    };

    Object.keys(gridCells).forEach((studentKey) => {
        Object.keys(gridCells[studentKey]).forEach((topicId) => {
            Object.keys(gridCells[studentKey][topicId]).forEach((activityKey) => {
                selectedAssignments = manageChallengesSelectedAssignments({
                    selectedAssignments,
                    activityKey,
                    studentKey,
                    studentAssignments,
                    isSelected: gridCells[studentKey][topicId][activityKey].isSelected
                });
            });
        });
    });

    return selectedAssignments;
}
