import React from 'react';

import { ViewKeyItem } from './components/view-key/view-key.types';

export type SwitchLabelDefaultProps = {
    onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void
    isChecked: boolean
};

export enum DynamicGridFiltersConstants {
    name = 'dynamicGridFilters'
}

export type DynamicGridFilterProps = {
    viewKey: {
        keyItems: ViewKeyItem[]
    }
    startDueDates: SwitchLabelDefaultProps
    voluntaryAttempts: SwitchLabelDefaultProps
};

export type DynamicGridFiltersState = {
    showDueDates: boolean
    voluntaryAttempts: boolean
    isOpen: boolean
};
