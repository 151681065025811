import { useState, useEffect } from 'react';

export const useIsConnected = () => {
    const [isConnected, setIsConnected] = useState(true);

    useEffect(() => {
        const handleConnectionChange = () => {
            setIsConnected(navigator.onLine);
        };

        window.addEventListener('online', handleConnectionChange);
        window.addEventListener('offline', handleConnectionChange);

        setIsConnected(navigator.onLine);

        return () => {
            window.removeEventListener('online', handleConnectionChange);
            window.removeEventListener('offline', handleConnectionChange);
        };
    }, []);

    return isConnected;
};
