import {
    GridCount,
    StudentId
} from '../../../slice/slice.types';
import {
    UpdatedActivity,
    UpdatedStudent,
    UpdatedTopic,
    UpdatedTopicActivity,
    UpdatedTopicActivityMap,
    UpdateTopicGridCounters
} from './topic-grid-counters.types';

const {
    studentCount,
    topicCount,
    topicActivityCount,
    activityCount
} = GridCount;

function updatedTopic ({
    updatedTopicCount,
    isChecked
}: UpdatedTopic) {
    const { totalActivities } = updatedTopicCount;

    updatedTopicCount.selectedActivities = isChecked ? totalActivities : 0;

    return updatedTopicCount;
}

function updatedTopicActivityMap ({
    updatedTopicActivityMap,
    studentKey,
    isChecked,
    checkedValue,
    uncheckedValue
}: UpdatedTopicActivityMap) {
    updatedTopicActivityMap = {
        ...updatedTopicActivityMap,
        [studentKey]: isChecked ? checkedValue : uncheckedValue
    };

    return updatedTopicActivityMap;
}

function updatedTopicActivity ({
    updatedTopicActivityCount,
    topicId,
    isChecked
}: UpdatedTopicActivity) {
    let topicActivityMap: Record<StudentId, number> = {};

    Object.keys(updatedTopicActivityCount).forEach((studentKey) => {
        const {
            selectedActivities,
            totalActivities
        } = updatedTopicActivityCount[studentKey][topicId];

        updatedTopicActivityCount[studentKey][topicId].selectedActivities = isChecked ? totalActivities : 0;

        const updatedSelectedActivities = updatedTopicActivityCount[studentKey][topicId].selectedActivities;

        topicActivityMap = updatedTopicActivityMap({
            updatedTopicActivityMap: topicActivityMap,
            studentKey,
            isChecked,
            checkedValue: updatedSelectedActivities - selectedActivities,
            uncheckedValue: -(selectedActivities)
        });
    });

    return {
        updatedTopicActivityCount,
        topicActivityMap
    };
}

function updatedStudent ({
    updatedStudentCount,
    topicActivityMap
}: UpdatedStudent) {
    Object.keys(updatedStudentCount).forEach((studentKey) => {
        const updatedStudentSelectedActivities = topicActivityMap[studentKey];

        updatedStudentCount[studentKey].selectedActivities += updatedStudentSelectedActivities;
    });

    return updatedStudentCount;
}

function updatedActivity ({
    updatedActivityCount,
    allStudentsArray,
    isChecked
}: UpdatedActivity) {
    Object.keys(updatedActivityCount).forEach((activityKey) => {
        const { selectedActivities } = updatedActivityCount[activityKey];

        updatedActivityCount[activityKey].selectedActivities =
            isChecked ? [...selectedActivities, ...allStudentsArray] : [];
    });

    return updatedActivityCount;
}

export function updateTopicGridCounters ({
    counters,
    topicId,
    isChecked
}: UpdateTopicGridCounters) {
    if (topicId) {
        const {
            updatedTopicActivityCount,
            topicActivityMap
        } = updatedTopicActivity({
            updatedTopicActivityCount: counters[topicActivityCount],
            topicId,
            isChecked
        });

        // Updated Topic Counters
        counters[topicCount][topicId] = updatedTopic({
            updatedTopicCount: counters[topicCount][topicId],
            isChecked
        });
        // Updated Topic Activity Counters
        counters[topicActivityCount] = updatedTopicActivityCount;
        // Updated Student Counters
        counters[studentCount] = updatedStudent({
            updatedStudentCount: counters[studentCount],
            topicActivityMap
        });
        // Updated Activity Counters
        counters[activityCount][topicId] = updatedActivity({
            updatedActivityCount: counters[activityCount][topicId],
            allStudentsArray: Object.keys(counters[studentCount]).map((key) => key),
            isChecked
        });
    }

    return counters;
}
