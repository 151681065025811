export enum ActivitiesPercentages {
    SCORE_0 = 0,
    SCORE_50 = 50,
    SCORE_85 = 85,
    SCORE_100 = 100
}

export enum AssignmentResultType {
    latestTeacherAssigned = 1,
    highest = 3,
    highestTeacherAssigned = 4,
    highestVoluntary = 5
}

export const getActivitiesGridCellDynamicStyle = (score: number) => {
    if(score >= ActivitiesPercentages.SCORE_0 && score < ActivitiesPercentages.SCORE_50) {
        return {
            background: 'linear-gradient(135deg, #FBDFE1 0%, #F1C4C7 100%)',
            color: 'error.900'
        };
    } else if(score >= ActivitiesPercentages.SCORE_50 && score < ActivitiesPercentages.SCORE_85) {
        return {
            background: 'linear-gradient(135deg, #FEE8AD 0%, #F3D88D 100%)',
            color: 'secondary.900'
        };
    } else if(score >= ActivitiesPercentages.SCORE_85 && score <= ActivitiesPercentages.SCORE_100) {
        return {
            background: 'linear-gradient(135deg, #8AEB8D 0%, #57CF5B 100%)',
            color: 'teal.900'
        };
    }

    return {
        background: '',
        color: ''
    };
};
