import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DynamicGridFiltersConstants, DynamicGridFiltersState } from './dynamic-grid-filters.types';
import { RootState } from '@config/react-redux/store';

const { name } = DynamicGridFiltersConstants;
const initialState: DynamicGridFiltersState = {
    isOpen: false,
    showDueDates: false,
    voluntaryAttempts: true
};

export const dynamicGridFiltersSlice = createSlice({
    name,
    initialState,
    reducers: {
        setDynamicGridFiltersOpen (state, action: PayloadAction<boolean>) {
            state.isOpen = action.payload;
        },
        setShowDueDate (state, action: PayloadAction<boolean>) {
            state.showDueDates = action.payload;
        },
        setVoluntaryAttempts (state, action: PayloadAction<boolean>) {
            state.voluntaryAttempts = action.payload;
        }
    }
});

/**
 * ACTIONS
 */
export const {
    setDynamicGridFiltersOpen,
    setShowDueDate,
    setVoluntaryAttempts
} = dynamicGridFiltersSlice.actions;

/**
 * SELECTORS
 */
export const selectDynamicGridFiltersOpen = (state: RootState) => state[name].isOpen;

export const selectShowDueDates = (state: RootState) => state[name].showDueDates;

export const selectVoluntaryAttempts = (state: RootState) => state[name].voluntaryAttempts;

export default dynamicGridFiltersSlice.reducer;
