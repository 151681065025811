export const dropdownButtonBorderRadius = '8px 8px 0 0px';

export const dropdownContainerWidth = {
    base: '255px',
    tablet: '373px'
};

export const dropdownContainerHeight = {
    base: '32px',
    tablet: '40px'
};

export const dropdownContainerFontSize = {
    base: 'xs',
    tablet: 'sm'
};
