import { BoxProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

// TODO: Make Generic
import {
    AssignmentActionRecord
} from '@containers/activity-router/routes/learn/activities/helpers/slice/assignments/selected/assignments-selected-helpers.types';

export enum FooterButtonVariants {
    footerButtonPrimary = 'footerButtonPrimary',
    footerButtonSecondary = 'footerButtonSecondary',
    footerButtonNeutral = 'footerButtonNeutral'
}

export type GridFooterButtonProps = {
    assignmentAction: AssignmentActionRecord
    buttonText: string
    buttonDisabledText: string
    onClick: () => void
    isRequestPending: boolean
    buttonVariant: FooterButtonVariants
    isActive: boolean
    isLoading?: boolean
};

export type GridFooterStructure = Array<GridFooterButtonProps>;

export type GridFooterProps = {
    leftSideDisplay: ReactElement
    structure: GridFooterStructure
    styleOverride?: { container?: BoxProps }
};

export type GetAssignmentActionCountParams = { assignmentAction: AssignmentActionRecord };
