import {
    GetSelectedClassroom,
    GetSelectedGroup
} from '../class-group-filters.types';
import { TransformCombinedAssignGroup } from '@services/gateways/combined-assign-gateway/combined-assign-gateway.types';

export function getSelectedClassroom ({ data, value } : GetSelectedClassroom) {
    return data.find(item => item.id === value) || data[0];
}

export function getSelectedGroup ({ data, value } : GetSelectedGroup) {
    return data.find(item => item.id === value) || data[0];
}

export function getNextValidGroup ({ groups }: { groups: Array<TransformCombinedAssignGroup> }) {
    return groups.find((group) => group.studentsCount !== 0) || groups[0];
}
