import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { DropdownOption } from '@components/dropdown/dropdown.types';
import { prepareHeadersBase } from '@services/api/services-api-helpers';
import {
    CombinedAssignGatewayAssignmentsDefaultParams,
    CombinedAssignGatewayClassroomsParams,
    CombinedAssignGatewayInitialDataParams,
    CombinedAssignGatewayNewCoursesAttemptsParams,
    CombinedAssignGatewayNewCoursesCourseListParams,
    CombinedAssignGatewayNewCoursesYearGroupsParams,
    CombinedAssignGatewayTeacherNotesExistenceParams
} from '@services/gateways/combined-assign-gateway/api/combined-assign-gateway-api.types';
import {
    ChallengesAssignmentReturn,
    CombinedAssignCourseList,
    CombinedAssignInitData, CombinedAssignYearGroup,
    NewCoursesAssignmentReturn, NewCoursesAttempt,
    SelectedGroupStudents
} from '@services/gateways/combined-assign-gateway/combined-assign-gateway.types';
import { transformNewCoursesCourseListData } from '@services/gateways/combined-assign-gateway/transform/courses/courses-transform';
import {
    transformNewCoursesYearGroups
} from '@services/gateways/combined-assign-gateway/transform/year-groups/year-groups-transform';

export const combinedAssignGatewayApi = createApi({
    reducerPath: 'combinedAssignGatewayApi',
    baseQuery: fetchBaseQuery({
        baseUrl: window._env_?.COMBINED_ASSIGN_GATEWAY_URL || '',
        prepareHeaders: (headers) => prepareHeadersBase(headers)
    }),
    endpoints: (builder) => ({
        getInitialData: builder.query<CombinedAssignInitData, CombinedAssignGatewayInitialDataParams>({
            query: ({ preferredClassroomId, preferredGroupId, authToken, preferredCourseType, locale }) => ({
                url: '/assignment-management',
                params: {
                    preferredClassroomId,
                    preferredGroupId,
                    authToken,
                    preferredCourseType,
                    locale
                }
            })
        }),
        getClassGroupStudents: builder.query<SelectedGroupStudents, CombinedAssignGatewayClassroomsParams>({
            query: ({ classroomId, groupId, authToken }) => ({
                url: `/classrooms/${classroomId}/groups/${groupId}/students`,
                params: { authToken }
            })
        }),
        getCourse: builder.query({
            query: ({ courseType, classroomId, courseId, authToken, locale }) => ({
                url: `/course-types/${courseType}?classroomId=${classroomId}`,
                params: {
                    courseId,
                    authToken,
                    locale
                }
            })
        }),
        getNewCoursesAssignments: builder.query<Array<NewCoursesAssignmentReturn>, CombinedAssignGatewayAssignmentsDefaultParams>({
            query: ({
                classroomId,
                productId,
                authToken,
                groupId,
                assignmentResultType,
                assignmentRequestType,
                usePreviousResultWhenThereIsNoCurrent
            }) => ({
                // eslint-disable-next-line max-len
                url: `/assign-review-conquest/products/${productId}/classes/${classroomId}/groups/${groupId}/assignments?assignmentResultType=${assignmentResultType}&usePreviousResultWhenThereIsNoCurrent=${usePreviousResultWhenThereIsNoCurrent}&assignmentRequestType=${assignmentRequestType}`,
                params: { authToken }
            })
        }),
        getNewCoursesYearGroups: builder.query<Array<DropdownOption>, CombinedAssignGatewayNewCoursesYearGroupsParams>({
            query: ({ authToken, locale }) => ({
                url: '/year-groups',
                params: {
                    locale,
                    authToken
                }
            }),
            transformResponse: (response: Array<CombinedAssignYearGroup>) => {
                return transformNewCoursesYearGroups(response);
            }
        }),
        getNewCoursesCourseList: builder.query<Array<DropdownOption>, CombinedAssignGatewayNewCoursesCourseListParams>({
            query: ({ courseType, yearGroup, authToken, locale }) => ({
                // eslint-disable-next-line etc/no-commented-out-code
                // url: `/course-types/${courseType}/courses?yearGroup=${yearGroup}&countryCode=${countryCode}`,
                url: `/course-types/${courseType}/courses?yearGroup=${yearGroup}`,
                params: {
                    locale,
                    authToken
                }
            }),
            transformResponse: (response: Array<CombinedAssignCourseList>) => {
                return transformNewCoursesCourseListData(response);
            }
        }),
        getNewCoursesAttempts: builder.query<Array<NewCoursesAttempt>, CombinedAssignGatewayNewCoursesAttemptsParams>({
            query: ({ productId, classroomId, studentUserProfileId, assignmentResultType, questionSetIds, authToken, locale }) => ({
                // eslint-disable-next-line max-len
                url: `/assign-review-conquest/products/${productId}/classes/${classroomId}/students/${studentUserProfileId}/question-set-attempts`,
                method: 'POST',
                params: {
                    locale,
                    authToken,
                    assignmentResultType
                },
                body: questionSetIds
            })
        }),
        getChallengesAssignments: builder.query<ChallengesAssignmentReturn[], CombinedAssignGatewayAssignmentsDefaultParams>({
            query: ({
                productId,
                classroomId,
                groupId,
                ...params
            }) => ({
                url: `/assign-review-psr/products/${productId}/classes/${classroomId}/groups/${groupId}/assignments`,
                params
            })
        }),
        getHasTeacherNotes: builder.query<boolean, CombinedAssignGatewayTeacherNotesExistenceParams>({
            query: ({
                activityId,
                authToken,
                locale
            }) => ({
                url: `/assign-review-psr/teacher-notes/${activityId}/existence`,
                params: {
                    locale,
                    authToken
                }
            })
        })
    })
});

export const {
    useGetInitialDataQuery,
    useGetClassGroupStudentsQuery,
    useGetCourseQuery,
    useGetNewCoursesAssignmentsQuery,
    useGetNewCoursesYearGroupsQuery,
    useGetNewCoursesCourseListQuery,
    useGetNewCoursesAttemptsQuery,
    useGetChallengesAssignmentsQuery,
    useGetHasTeacherNotesQuery
} = combinedAssignGatewayApi;
