import { i18n } from '@lingui/core';
import { I18nProvider as LinguiI18nProvider } from '@lingui/react';
import React, { ReactNode } from 'react';

interface I18nProviderProps {
    children: ReactNode
}

export const I18nProvider = ({ children }: I18nProviderProps) => {
    return (
        <LinguiI18nProvider forceRenderOnLocaleChange={false} i18n={i18n}>
            {children}
        </LinguiI18nProvider>
    );
};
