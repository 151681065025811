import { useMemo } from 'react';

import {
    selectClassGroupDictionary,
    selectClassGroupFilterClassroomSelectedId,
    selectClassGroupFilterGroup,
    selectClassGroupFilterGroupSelectedId
} from '../../class-group-filters-slice';
import { useAppSelector } from '@config/react-redux/hooks';

export const useRestrictData = () => {
    const selectedGroup = useAppSelector(selectClassGroupFilterGroup).selected;
    const classGroupDictionary = useAppSelector(selectClassGroupDictionary);
    const classroomId = useAppSelector(selectClassGroupFilterClassroomSelectedId);
    const groupId = useAppSelector(selectClassGroupFilterGroupSelectedId);
    const isRestrictedToAssignmentsOnly = useMemo(() => {
        if (
            classroomId !== null &&
            groupId !== null &&
            selectedGroup !== null
        ) {
            if (
                classGroupDictionary[classroomId] !== undefined &&
                classGroupDictionary[classroomId][groupId] !== undefined
            ) {
                return classGroupDictionary[classroomId][groupId].isRestrictedToAssignmentsOnly;
            } else {
                return selectedGroup.isRestrictedToAssignmentsOnly;
            }
        }

        return false;
    }, [classGroupDictionary, classroomId, groupId, selectedGroup]);

    return isRestrictedToAssignmentsOnly;
};
