import { ChakraProvider } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

import { theme } from '@config/chakra-ui/theme/theme';

type ChakraUiProviderProps = {
    children: ReactNode
};

export const ChakraUiProvider = ({ children }: ChakraUiProviderProps) => {
    return (
        <ChakraProvider theme={theme}>
            {children}
        </ChakraProvider>
    );
};
