import moment from 'moment/moment';

import {
    onMultiSelectClearAll,
    updateMultiSelectGridCellsAndCounters
} from '@containers/activity-router/routes/helpers/multi-select/multi-select-helpers';
import {
    ManageNewCoursesMultiSelectParams
} from '@containers/activity-router/routes/learn/new-courses/components/new-courses-multi-select/new-courses-multi-select.types';
import {
    getNewCoursesCurrentAssignment,
    getNewCoursesSelectedAssignments
} from '@containers/activity-router/routes/learn/new-courses/helpers/slice/assignments/selected/assignments-selected-helpers';
import { NewCoursesPercentages } from '@containers/activity-router/routes/learn/new-courses/new-courses-constants';
import { NewCoursesAssignment } from '@services/gateways/combined-assign-gateway/combined-assign-gateway.types';

const {
    SCORE_0,
    SCORE_50,
    SCORE_80,
    SCORE_100
} = NewCoursesPercentages;

function getAssignmentType (currentAssignment: NewCoursesAssignment) {
    const {
        currentAssignmentDueDate,
        currentAssignmentStartDate
    } = currentAssignment;
    const isAssignmentPastDueDate = moment().local().isAfter(moment(currentAssignmentDueDate));
    const isFutureAssignment = moment(currentAssignmentStartDate).isAfter(moment().local());

    return {
        isAssignmentPastDueDate,
        isFutureAssignment
    };
}

export function manageNewCoursesMultiSelect ({
    option,
    gridCells,
    gridCounters,
    studentAssignments,
    selectedAssignments
}: ManageNewCoursesMultiSelectParams) {
    Object.keys(gridCells).forEach((studentId) => {
        Object.keys(gridCells[studentId]).forEach((topicId) => {
            Object.keys(gridCells[studentId][topicId]).forEach((activityId) => {
                const currentAssignment = getNewCoursesCurrentAssignment({
                    activityKey: activityId,
                    studentKey: studentId,
                    studentAssignments
                });
                const isValidResult = currentAssignment && currentAssignment.resultAccuracy !== null;
                const isValidAssignment = currentAssignment && currentAssignment.currentAssignmentId != null;

                function genericSuccessCallback () {
                    updateMultiSelectGridCellsAndCounters({
                        gridCells,
                        gridCounters,
                        studentId,
                        topicId,
                        activityId
                    });
                    getNewCoursesSelectedAssignments({
                        currentAssignment,
                        selectedAssignments,
                        isSelected: true,
                        activityKey: activityId,
                        userProfileId: studentId
                    });
                }

                switch (option) {
                case 'All Cells': {
                    genericSuccessCallback();
                    break;
                }
                case 'Assigned Now': {
                    if (isValidAssignment) {
                        const {
                            isFutureAssignment,
                            isAssignmentPastDueDate
                        } = getAssignmentType(currentAssignment);

                        if (!isAssignmentPastDueDate && !isFutureAssignment) genericSuccessCallback();
                    }
                    break;
                }
                case 'Assigned Later': {
                    if (isValidAssignment) {
                        const { isFutureAssignment } = getAssignmentType(currentAssignment);

                        if (isFutureAssignment) genericSuccessCallback();
                    }
                    break;
                }
                case 'Overdue': {
                    if (isValidAssignment) {
                        const { isAssignmentPastDueDate } = getAssignmentType(currentAssignment);

                        if (isAssignmentPastDueDate) genericSuccessCallback();
                    }
                    break;
                }
                case 'Low Range Correct results': {
                    if (isValidResult && !isValidAssignment) {
                        const { resultAccuracy } = currentAssignment;

                        if (
                            resultAccuracy !== null &&
                            resultAccuracy >= SCORE_0 &&
                            resultAccuracy < SCORE_50
                        ) genericSuccessCallback();
                    }
                    break;
                }
                case 'Mid Range Correct results': {
                    if (isValidResult && !isValidAssignment) {
                        const { resultAccuracy } = currentAssignment;

                        if (
                            resultAccuracy !== null &&
                            resultAccuracy >= SCORE_50 &&
                            resultAccuracy < SCORE_80
                        ) genericSuccessCallback();
                    }
                    break;
                }
                case 'High Range Correct results': {
                    if (isValidResult && !isValidAssignment) {
                        const { resultAccuracy } = currentAssignment;

                        if (
                            resultAccuracy !== null &&
                            resultAccuracy >= SCORE_80 &&
                            resultAccuracy <= SCORE_100
                        ) genericSuccessCallback();
                    }
                    break;
                }
                case 'Clear All': {
                    onMultiSelectClearAll({
                        gridCells,
                        gridCounters,
                        studentId,
                        topicId,
                        activityId
                    });
                    getNewCoursesSelectedAssignments({
                        currentAssignment,
                        selectedAssignments,
                        isSelected: false,
                        activityKey: activityId,
                        userProfileId: studentId
                    });
                    break;
                }
                }
            });
        });
    });

    return {
        gridCells,
        gridCounters
    };
}
