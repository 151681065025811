import {
    getActivityKey,
    getStudentKey,
    getTopicKey
} from '../../../keys/key-generator';
import {
    ActivityCount,
    StudentCount,
    TopicActivityCount,
    TopicCount
} from '../../../slice/topic/topic-slice.types';
import {
    SelectedGroupStudents,
    TransformCombinedAssignTopic,
    TransformCourseTopics
} from '@services/gateways/combined-assign-gateway/combined-assign-gateway.types';

type DefaultParams = {
    selectedGroupStudents: SelectedGroupStudents
    topics: Array<TransformCourseTopics>
};

export function getInitialStudentCountTopic ({
    selectedGroupStudents,
    topics
}: DefaultParams) {
    const updatedGridStudentCount: StudentCount = {};

    selectedGroupStudents.forEach(({ userProfileId }) => {
        const studentKey = getStudentKey({ id: userProfileId.toString() });

        let studentTotalActivities = 0;

        function processStrand ({ topics: strandTopics }: TransformCourseTopics) {
            if (strandTopics) {
                strandTopics.forEach(topic => processTopic(topic));
            }
        }

        function processTopic (topic: TransformCombinedAssignTopic) {
            if (topic.activities) {
                const activitiesLength = topic.activities?.length || 1;

                studentTotalActivities += activitiesLength;
            }
        }

        topics.forEach(topic => {
            if (topic.topics) {
                processStrand(topic);
            } else {
                processTopic(topic as TransformCombinedAssignTopic);
            }
        });

        updatedGridStudentCount[studentKey] = {
            selectedActivities: 0,
            totalActivities: studentTotalActivities
        };
    });

    return updatedGridStudentCount;
}

export function getInitialTopicCountTopic ({
    selectedGroupStudents,
    topics
}: DefaultParams) {
    const updatedGridTopicCount: TopicCount = {};
    const selectedGroupStudentsLength = selectedGroupStudents.length;

    function processStrand ({ topics: strandTopics, id: strandId }: TransformCourseTopics) {
        if (strandTopics) {
            strandTopics.forEach(topic => {
                processTopic(topic, strandId);
            });
        }
    }

    function processTopic (topic: TransformCombinedAssignTopic, strandId?: string) {
        if (topic.activities) {
            const key = getTopicKey({
                strandId,
                topicId: topic.id
            });
            const activitiesLength = topic.activities.length || 1;

            updatedGridTopicCount[key] = {
                selectedActivities: 0,
                totalActivities: selectedGroupStudentsLength * activitiesLength
            };
        }
    }

    topics.forEach(topic => {
        if (topic.topics) {
            processStrand(topic);
        } else {
            processTopic(topic as TransformCombinedAssignTopic);
        }
    });

    return updatedGridTopicCount;
}

export function getInitialTopicActivityCountTopic ({
    selectedGroupStudents,
    topics
}: DefaultParams) {
    const updatedGridTopicActivityCount: TopicActivityCount = {};

    selectedGroupStudents.forEach(({ userProfileId }) => {
        const studentKey = getStudentKey({ id: userProfileId.toString() });

        function processStrand ({ topics: strandTopics, id: strandId }: TransformCourseTopics) {
            if (strandTopics) {
                strandTopics.forEach(topic => {
                    processTopic(topic, strandId);
                });
            }
        }

        function processTopic (topic: TransformCombinedAssignTopic, strandId?: string) {
            if (topic.activities) {
                let topicActivityTotalActivities = 0;

                const key = getTopicKey({
                    strandId,
                    topicId: topic.id
                });
                const activitiesLength = topic.activities?.length || 1;

                topicActivityTotalActivities += activitiesLength;

                updatedGridTopicActivityCount[studentKey] = {
                    ...updatedGridTopicActivityCount[studentKey],
                    [key]: {
                        selectedActivities: 0,
                        totalActivities: topicActivityTotalActivities
                    }
                };
            }
        }

        topics.forEach(topic => {
            if (topic.topics) {
                processStrand(topic);
            } else {
                processTopic(topic as TransformCombinedAssignTopic);
            }
        });
    });

    return updatedGridTopicActivityCount;
}

export function getInitialActivityCountTopic ({
    selectedGroupStudents,
    topics
}: DefaultParams) {
    const updatedGridActivityCount: ActivityCount = {};
    const selectedGroupStudentsLength = selectedGroupStudents.length;

    function processStrand ({ topics: strandTopics, id: strandId }: TransformCourseTopics) {
        if (strandTopics) {
            strandTopics.forEach(topic => {
                processTopic(topic, strandId);
            });
        }
    }

    function processTopic (topic: TransformCombinedAssignTopic, strandId?: string) {
        if (topic.activities) {
            const key = getTopicKey({
                strandId,
                topicId: topic.id
            });

            topic.activities?.forEach(({
                id: activityId,
                activityType
            }) => {
                const activityKey = getActivityKey({
                    id: activityId,
                    activityType
                });

                updatedGridActivityCount[key] = {
                    ...updatedGridActivityCount[key],
                    [activityKey]: {
                        selectedActivities: [],
                        totalActivities: selectedGroupStudentsLength
                    }
                };
            });
        }
    }

    topics.forEach(topic => {
        if (topic.topics) {
            processStrand(topic);
        } else {
            processTopic(topic as TransformCombinedAssignTopic);
        }
    });

    return updatedGridActivityCount;
}

export function generateInitialGridCounters ({
    selectedGroupStudents,
    topics
}: DefaultParams) {
    return {
        studentCount: getInitialStudentCountTopic({
            selectedGroupStudents,
            topics
        }),
        topicCount: getInitialTopicCountTopic({
            selectedGroupStudents,
            topics
        }),
        topicActivityCount: getInitialTopicActivityCountTopic({
            selectedGroupStudents,
            topics
        }),
        activityCount: getInitialActivityCountTopic({
            selectedGroupStudents,
            topics
        })
    };
}
