export enum NewCoursesPercentages {
    SCORE_0 = 0,
    SCORE_50 = 50,
    SCORE_80 = 80,
    SCORE_100 = 100
}

export enum NewCoursesAssignmentResultType {
    highestTeacherAssigned = 0,
    highestVoluntary,
    highest,
}

export const newCoursesNoCourseAssignedId = '00000000-0000-0000-0000-000000000000';

export const getNewCoursesGridCellDynamicStyle = (score: number) => {
    if(score >= NewCoursesPercentages.SCORE_0 && score < NewCoursesPercentages.SCORE_50) {
        return {
            background: 'linear-gradient(135deg, #FBDFE1 0%, #F1C4C7 100%)',
            color: 'error.900'
        };
    } else if(score >= NewCoursesPercentages.SCORE_50 && score < NewCoursesPercentages.SCORE_80) {
        return {
            background: 'linear-gradient(135deg, #FEE8AD 0%, #F3D88D 100%)',
            color: 'secondary.900'
        };
    } else if(score >= NewCoursesPercentages.SCORE_80 && score <= NewCoursesPercentages.SCORE_100) {
        return {
            background: 'linear-gradient(135deg, #8AEB8D 0%, #57CF5B 100%)',
            color: 'teal.900'
        };
    }

    return {
        background: '',
        color: ''
    };
};
