import React from 'react';

import * as styled from './loading-checkbox.styled';
import { CustomSkeleton } from '@components/loading/custom-skeleton/custom-skeleton';

export const LoadingCheckbox = () => {
    return (
        <styled.checkboxWrapper>
            <CustomSkeleton {...styled.checkboxInput} />
        </styled.checkboxWrapper>
    );
};
