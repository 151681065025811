import { teacherComponentsTheme } from '@3plearning/chakra-teacher-components';
import { extendTheme } from '@chakra-ui/react';
import type { Dict } from '@chakra-ui/utils';

import { Button } from './components/button/button';
import { Checkbox } from './components/checkbox/checkbox';
import { Popover } from './components/popover/popover';
import { Switch } from './components/switch/switch';
import { strandColors } from './foundations/colors/strand-colors';
import { styles } from './styles/styles';

export const theme: Dict = extendTheme({
    ...teacherComponentsTheme,
    colors: {
        ...teacherComponentsTheme.colors,
        ...strandColors
    },
    components: {
        Button,
        Switch,
        Checkbox,
        Popover
    },
    styles
});
