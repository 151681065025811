import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useEffect } from 'react';

import {
    useAppDispatch,
    useAppSelector
} from '@config/react-redux/hooks';
import {
    selectAppLandingModal,
    setAppLandingModal
} from '@containers/app/app-landing/app-landing-slice';
import {
    AppLandingModalConstants,
    AppLandingModalTypes,
    getAppLandingModalDefault
} from '@containers/app/app-landing/app-landing.types';
import { useIsConnected } from '@hooks/use-is-connected/use-is-connected';

export type QueryError = FetchBaseQueryError| unknown;

export type UseAppErrorHandlingProps = {
    error: QueryError
    type: AppLandingModalTypes
    callback?: () => void
};

const {
    errorHome,
    errorConnection
} = AppLandingModalConstants;

export const useAppErrorHandling = ({
    error,
    type,
    callback
}: UseAppErrorHandlingProps) => {
    const dispatch = useAppDispatch();
    const {
        variant,
        type: selectedModalType
    } = useAppSelector(selectAppLandingModal);
    const isConnected = useIsConnected();

    useEffect(() => {
        const shouldDisplayErrorModal =
            error &&
            variant === null;

        if (shouldDisplayErrorModal) {
            if (isConnected) {
                dispatch(setAppLandingModal({
                    variant: errorHome,
                    type
                }));
            } else {
                dispatch(setAppLandingModal({
                    variant: errorConnection,
                    type
                }));
            }
        } else if (isConnected && variant !== null) {
            if (callback && selectedModalType === type) callback();
            dispatch(setAppLandingModal(getAppLandingModalDefault()));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, isConnected]);
};
