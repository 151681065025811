/* eslint-disable @typescript-eslint/no-var-requires,import/no-commonjs */
import { Box } from '@chakra-ui/react';
import React from 'react';

import { useEnvConfig } from '@config/env/env-config-provider';

export const BuildNumber = () => {
    const { BUILD_NUMBER } = useEnvConfig();

    return (
        <Box
            bottom={0}
            color={'rgb(78 88 94)'}
            fontSize={'14px'}
            left={'5px'}
            position={'fixed'}
            zIndex={'9999'}
        >
            {BUILD_NUMBER || '0.0.0'}
        </Box>
    );
};
