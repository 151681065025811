import { chakra, Flex } from '@chakra-ui/react';

import { CellDimensions } from '@containers/activity-router/data-grid-container/data-grid-container.styled';

export const student = {
    h: '118px',
    transform: 'skewX(-46deg) translate(80px, 10px)',
    w: '56px'
};

export const details = chakra(Flex, {
    label: 'loading-students-details',
    baseStyle: {
        w: CellDimensions.w,
        h: '41px',
        alignItems: 'center',
        justifyContent: 'center',
        borderRight: '1px solid',
        borderColor: 'neutral.100',
        mt: '17px',
        mb: '11px',
        _first: {
            borderLeft: '1px solid',
            borderColor: 'neutral.100'
        }
    }
});

export const assigned = {
    borderRadius: '8px',
    h: '41px',
    w: '57px'
};
