import { VStack } from '@chakra-ui/react';
import React from 'react';

import { VerticalSpacing } from '../../../style/constants';
import * as styled from './loading-app.styled';
import { CustomSkeleton } from '@components/loading/custom-skeleton/custom-skeleton';
import { LoadingRoute } from '@components/loading/loading-route/loading-route';

export const LoadingApp = () => {
    return (
        <styled.container>
            <styled.wrapper>
                <CustomSkeleton {...styled.header} />
                <VStack spacing={VerticalSpacing} w={'100%'}>
                    <CustomSkeleton {...styled.navigation} />
                    <CustomSkeleton {...styled.activityTypes} />
                </VStack>
            </styled.wrapper>
            <LoadingRoute isLoadingApp={true} />
        </styled.container>

    );
};
