import React, { PropsWithChildren } from 'react';

import { use3pScriptLoader } from './hooks/use-3p-script-loader';

export const ScriptingProvider = ({ children }: PropsWithChildren) => {
    use3pScriptLoader();

    return (
        <>
            {children}
        </>
    );
};
