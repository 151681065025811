import { Box, chakra, Flex } from '@chakra-ui/react';

import { PagePadding } from '../../../style/constants';

export const ACTIVITY_TYPE_TABS_HEIGHT = {
    base: '56px',
    tablet: '64px'
};

export const container = chakra(Box, {
    label: 'activity-type-tabs-container',
    baseStyle: {
        width: '100%',
        paddingRight: {
            base: 6,
            tablet: PagePadding.right
        },
        paddingLeft: {
            base: 6,
            tablet: PagePadding.left
        }
    }
});

export const wrapper = chakra(Flex, {
    label: 'activity-type-tabs-wrapper',
    baseStyle: {
        width: '100%',
        height: ACTIVITY_TYPE_TABS_HEIGHT,
        justifyContent: 'center',
        background: 'white.50',
        border: '1px solid',
        borderColor: 'white.60',
        borderTopLeftRadius: 'xl',
        borderTopRightRadius: 'xl',
        overflow: 'hidden'
    }
});

export const item = chakra(Flex, {
    label: 'activity-type-tabs-item',
    baseStyle: {
        position: 'relative',
        minWidth: '250px',
        height: {
            base: '56px',
            tablet: '64px'
        },
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: '6',
        cursor: 'pointer',
        marginTop: '4px',
        padding: '0 8px 4px 8px',
        fontSize: {
            base: 'sm',
            tablet: 'md'
        },
        _hover: {
            fontWeight: 500
        }
    }
});
