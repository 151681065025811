import {
    chakra,
    Flex,
    Box
} from '@chakra-ui/react';

import { PagePadding } from '../../../style/constants';

export const CELL_DIMENSION = 71;

export const getMultipleCellWidth = (cellCount: number) => CELL_DIMENSION * cellCount;

export const CellDimensions = {
    w: `${CELL_DIMENSION}px`,
    h: `${CELL_DIMENSION}px`,
    minW: `${CELL_DIMENSION}px`,
    minH: `${CELL_DIMENSION}px`
};

export const GridHeaderHeight = '186px';

export const DynamicGridFiltersHeight = '56px';

export const CourseContainerWidth = {
    w: {
        base: '350px',
        tablet: '380px'
    },
    minW: {
        base: '350px',
        tablet: '380px'
    }
};

export const dataGridContainer = chakra(Flex, {
    label: 'data-grid-container-container',
    baseStyle: {
        w: '100%',
        backgroundColor: 'white.0',
        paddingTop: '3',
        paddingLeft: PagePadding.left,
        position: 'relative'
    }
});

export const routeContainer = chakra(Box, {
    label: 'route-container',
    baseStyle: {
        backgroundColor: 'white.0',
        w: '100%',
        marginTop: '0 !important'
    }
});

export const dataGridLeftPanel = chakra(Flex, {
    label: 'data-grid-left-panel',
    baseStyle: {
        position: 'sticky',
        left: 0,
        backgroundColor: 'white.0',
        zIndex: 'sticky',
        alignSelf: 'flex-start'
    }
});

export const dataGridRightPanel = chakra(Flex, {
    label: 'data-grid-right-panel',
    baseStyle: {
        paddingRight: '100px',
        w: 'fit-content',
        flexDirection: 'column',
        alignSelf: 'flex-start'
    }
});

export const dataGridCourse = chakra(Flex, {
    label: 'data-grid-course',
    baseStyle: {
        flexDirection: 'column'
    }
});
