import { ComponentStyleConfig } from '@chakra-ui/react';

import { DropdownButtonVariants } from './dropdown-button/dropdown-button';
import { FooterButtons } from './footer-button/footer-button';
import { ActivityButtons } from './grid-buttons/activity-button';
import { TopicButtons } from './grid-buttons/topic-button';
import { TopicFilterButtonVariants } from './topic-filter/topic-filter-button';

export const Button: ComponentStyleConfig = {
    variants: {
        ...DropdownButtonVariants,
        ...FooterButtons,
        ...TopicFilterButtonVariants,
        ...ActivityButtons,
        ...TopicButtons
    } };
