import { CombinedAssignGateway, CombinedAssignDynamicGateway } from '../combined-assign-gateway';
import { useEnvConfig } from '@config/env/env-config-provider';

type GetCombinedAssignGatewayUrlParams = {
    key: keyof typeof CombinedAssignGateway
};

type GetCombinedAssignGatewayDynamicUrlParams = {
    key: keyof typeof CombinedAssignDynamicGateway
};

export const useCombinedAssignGateway = () => {
    const { COMBINED_ASSIGN_GATEWAY_URL } = useEnvConfig();

    function getCombinedAssignGatewayUrl ({ key }: GetCombinedAssignGatewayUrlParams) {
        return `${COMBINED_ASSIGN_GATEWAY_URL}${CombinedAssignGateway[key]}`;
    }

    function getCombinedAssignGatewayDynamicUrl ({ key }: GetCombinedAssignGatewayDynamicUrlParams, props: any) {
        return `${COMBINED_ASSIGN_GATEWAY_URL}${CombinedAssignDynamicGateway[key](props)}`;
    }

    return {
        getCombinedAssignGatewayUrl,
        getCombinedAssignGatewayDynamicUrl
    };
};
