import { GridCount } from '../../../slice/slice.types';
import { GridCounters } from '../../../slice/topic/topic-slice.types';
import {
    ManageActivitiesGridCellCounters,
    UpdateGridCellCounters
} from '../counters.types';
import {
    GetTopicGridCellActivityCount,
    UpdateTopicGridActivityCount
} from './grid-cell-counters.types';

const {
    studentCount,
    topicCount,
    topicActivityCount,
    activityCount
} = GridCount;

function manageActivitiesGridCellCounters ({
    counters,
    studentId,
    isSelected,
    topicId,
    activityId
}: ManageActivitiesGridCellCounters) {
    const { selectedActivities } = counters[activityCount][topicId][activityId];
    const index = selectedActivities.indexOf(studentId);

    if (isSelected) {
        if (selectedActivities.indexOf(studentId) === -1) {
            counters[activityCount][topicId][activityId].selectedActivities.push(studentId);
        }
    } else {
        counters[activityCount][topicId][activityId].selectedActivities.splice(index, 1);
    }

    return counters[activityCount][topicId][activityId].selectedActivities;
}

function getTopicGridCellActivityCount ({
    currentTopicActivityCount,
    isSelected
}: GetTopicGridCellActivityCount) {
    const {
        selectedActivities: topicActivitySelectedActivities,
        totalActivities: topicActivityTotalActivities
    } = currentTopicActivityCount;
    const updatedActivityCount = topicActivityTotalActivities - topicActivitySelectedActivities;

    return isSelected ? updatedActivityCount : -(topicActivityTotalActivities);
}

function updatedTopicGridActivityCount ({
    updatedActivityCount,
    studentId,
    isSelected
}: UpdateTopicGridActivityCount) {
    Object.keys(updatedActivityCount).forEach((activityKey) => {
        if (isSelected) {
            updatedActivityCount[activityKey].selectedActivities = [
                ...updatedActivityCount[activityKey].selectedActivities,
                ...[studentId]
            ];
        } else {
            updatedActivityCount[activityKey].selectedActivities =
                updatedActivityCount[activityKey].selectedActivities.filter((studentKey) => studentKey !== studentId);
        }
    });

    return updatedActivityCount;
}

function getUpdatedTopicGridCellCounters ({
    counters,
    studentId,
    topicId,
    isSelected
}: Omit<UpdateGridCellCounters, 'activityId'>) {
    if (topicId) {
        const { totalActivities: topicActivityTotalActivities } = counters[topicActivityCount][studentId][topicId];
        const updatedActivityCount = getTopicGridCellActivityCount({
            currentTopicActivityCount: counters[topicActivityCount][studentId][topicId],
            isSelected
        });

        // Updated Student Counter
        counters[studentCount][studentId].selectedActivities += updatedActivityCount;
        // Updated Topic Counter
        counters[topicCount][topicId].selectedActivities += updatedActivityCount;
        // Uodated Topic Activity Counter
        counters[topicActivityCount][studentId][topicId].selectedActivities = isSelected ? topicActivityTotalActivities : 0;
        // Updated Activity Counter
        counters[activityCount][topicId] = updatedTopicGridActivityCount({
            updatedActivityCount: counters[activityCount][topicId],
            studentId,
            isSelected
        });
    }

    return counters;
}

export function updateGridCellCounters ({
    counters,
    isSelected,
    studentId,
    topicId,
    activityId
}: UpdateGridCellCounters) {
    if (!activityId) {
        counters = getUpdatedTopicGridCellCounters({
            counters,
            studentId,
            topicId,
            isSelected
        });
    }
    if (activityId) {
        const updatedGridCounter = isSelected ? 1 : -1;
        const updatedCounters = manageActivitiesGridCellCounters({
            counters,
            isSelected,
            studentId,
            topicId,
            activityId
        });

        // Updated Activity Counter
        counters[activityCount][topicId][activityId].selectedActivities = updatedCounters;
        // Updated Student Counter
        counters[studentCount][studentId].selectedActivities += updatedGridCounter;
        // Updated Topic Activity Counter
        counters[topicActivityCount][studentId][topicId].selectedActivities += updatedGridCounter;
        // Updated Topic Count
        counters[topicCount][topicId].selectedActivities += updatedGridCounter;
    }

    return counters;
}

export function resetGridCellCounters ({ counters }: { counters: GridCounters }) {
    Object.keys(counters[studentCount]).forEach((studentKey) => {
        counters[studentCount][studentKey].selectedActivities = 0;
    });
    Object.keys(counters[topicCount]).forEach((topicKey) => {
        counters[topicCount][topicKey].selectedActivities = 0;
    });
    Object.keys(counters[activityCount]).forEach((topicKey) => {
        Object.keys(counters[activityCount][topicKey]).forEach((activityKey) => {
            counters[activityCount][topicKey][activityKey].selectedActivities = [];
        });
    });
    Object.keys(counters[topicActivityCount]).forEach((studentKey) => {
        Object.keys(counters[topicActivityCount][studentKey]).forEach((topicId) => {
            counters[topicActivityCount][studentKey][topicId].selectedActivities = 0;
        });
    });

    return counters;
}
