import {
    chakra,
    PopoverBody,
    PopoverContent
} from '@chakra-ui/react';

export const contextModalBody = chakra(PopoverBody, {
    label: 'context-modal-body',
    baseStyle: {
        backgroundColor: 'white.0',
        borderRadius: 'xl',
        paddingInlineStart: '0px',
        paddingInlineEnd: '0px',
        padding: '0px'
    }
});

export const contextModalContent = chakra(PopoverContent, {
    label: 'context-modal-content',
    baseStyle: {
        boxShadow: 'none !important',
        w: 'unset',
        border: 'none'
    }
});
