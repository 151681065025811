import {
    chakra,
    Flex
} from '@chakra-ui/react';

import { CellDimensions, GridHeaderHeight } from '../data-grid-container.styled';

export const GenericGridFiltersHeight = `calc(${GridHeaderHeight} + ${CellDimensions.h})`;

export const container = chakra(Flex, {
    label: 'generic-grid-filters-container',
    baseStyle: {
        position: 'sticky',
        top: 0,
        height: GenericGridFiltersHeight,
        width: CellDimensions.w,
        flexDirection: 'column',
        backgroundColor: 'white.0',
        zIndex: 'sticky',
        alignSelf: 'flex-start'
    }
});
