import { FooterButtonVariants } from '@containers/activity-router/data-grid-container/grid-footer/grid-footer.types';

export const footerButtonDefault = {
    w: 'auto',
    minW: '160px',
    h: '40px',
    fontSize: 'md',
    borderRadius: '40px',
    p: {
        base: '8px 24px 8px 24px',
        tablet: '8px 32px 8px 32px'
    },
    lineHeight: '28px',
    fontWeight: 500
};

export const FooterButtons = {
    [FooterButtonVariants.footerButtonPrimary]: {
        ...footerButtonDefault,
        background: 'linear-gradient(103.65deg, #00ABE8 -2.03%, #007ACC 43.7%, #0063A7 105.46%) !important',
        color: 'white.0 !important',
        _hover: {
            background: 'linear-gradient(102.49deg, #007ACC 0%, #014B8B 100%) !important'
        },
        _disabled: {
            background: 'neutral.100 !important',
            opacity: 1,
            pointerEvents: 'none',
            color: 'neutral.600 !important'
        },
        _active: {
            background: 'linear-gradient(98.39deg, #0063A7 0%, #01437D 100%) !important',
            color: 'white.0 !important'
        },
        _loading: {
            background: 'linear-gradient(98.39deg, #0063A7 0%, #01437D 100%) !important',
            opacity: '0.7',
            pointerEvents: 'none',
            color: 'white.0 !important'
        }
    },
    [FooterButtonVariants.footerButtonSecondary]: {
        ...footerButtonDefault,
        background: 'whiteAlpha.800',
        border: '1px solid',
        borderColor: 'primary.300',
        color: 'primary.600',
        _hover: {
            background: 'primary.50',
            border: '1px solid',
            borderColor: 'primary.400',
            color: 'primary.700'
        },
        _disabled: {
            background: 'white.0',
            opacity: 1,
            pointerEvents: 'none',
            color: 'neutral.500',
            border: '1px solid',
            borderColor: 'neutral.100'
        },
        _active: {
            background: 'primary.200',
            border: '1px solid',
            borderColor: 'primary.400',
            color: 'neutral.600'
        }
    },
    [FooterButtonVariants.footerButtonNeutral]: {
        ...footerButtonDefault,
        background: 'whiteAlpha.800',
        border: '1px solid',
        borderColor: 'neutral.300',
        color: 'neutral.600',
        _hover: {
            background: 'neutral.50',
            color: 'neutral.700'
        },
        _active: {
            background: 'primary.50',
            border: '1px solid',
            borderColor: 'primary.300',
            color: 'primary.700'
        }
    }
};
