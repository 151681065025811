import { CourseData } from '@containers/activity-router/data-grid-container/courses/types/courses.types';

export enum AssessmentsConstants {
    name = 'assessments'
}

export type AssessmentsState = {
    course: {
        selected: CourseData
    }
};
