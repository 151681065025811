import { WHOLE_CLASS_GROUP_ID } from '../class-group-filters.constants';
import {
    GetCurrentIsRestrictedToAssignmentsOnly,
    ManageRestrict,
    ManageRestrictGroups,
    RestrictedDictionary
} from '../class-group-filters.types';

export function manageWholeClassRestrict ({ data, action }: ManageRestrict) {
    const updatedRestrictedDictionaryItems: RestrictedDictionary = {};

    data.forEach((group) => {
        const groupIdToString = group.id.toString();

        updatedRestrictedDictionaryItems[groupIdToString] = {
            ...action.payload
        };
    });

    return updatedRestrictedDictionaryItems;
}

function getCurrentIsRestrictedToAssignmentsOnly ({
    group,
    selected,
    dictionary,
    updatedIsRestrictedToAssignmentsOnly
}: GetCurrentIsRestrictedToAssignmentsOnly) {
    let currentIsRestrictedToAssignmentsOnly = group.isRestrictedToAssignmentsOnly;

    if (group.id !== selected.id && group.id in dictionary)
        currentIsRestrictedToAssignmentsOnly = dictionary[group.id].isRestrictedToAssignmentsOnly;
    if (group.id === selected.id) currentIsRestrictedToAssignmentsOnly = updatedIsRestrictedToAssignmentsOnly;

    return currentIsRestrictedToAssignmentsOnly;
}

function manageGroupIsRestricted ({
    data,
    action,
    selected,
    dictionary
}: ManageRestrictGroups) {
    const updatedIsRestrictedToAssignmentsOnly = action.payload.isRestrictedToAssignmentsOnly;
    const selectedGroupId = selected.id.toString();
    const updatedRestrictedDictionaryItems: RestrictedDictionary = {};

    let checkAllGroupsRestrictAccess = true;

    data.forEach((group) => {
        if (group.id !== WHOLE_CLASS_GROUP_ID) {
            const currentIsRestrictedToAssignmentsOnly = getCurrentIsRestrictedToAssignmentsOnly({
                group,
                selected,
                dictionary,
                updatedIsRestrictedToAssignmentsOnly
            });

            if (!currentIsRestrictedToAssignmentsOnly) checkAllGroupsRestrictAccess = false;
        }
    });

    if (checkAllGroupsRestrictAccess) {
        data.forEach((group) => {
            updatedRestrictedDictionaryItems[group.id] = {
                isRestrictedToAssignmentsOnly: updatedIsRestrictedToAssignmentsOnly
            };
        });
    } else {
        updatedRestrictedDictionaryItems[selectedGroupId] = {
            isRestrictedToAssignmentsOnly: updatedIsRestrictedToAssignmentsOnly
        };
    }

    return updatedRestrictedDictionaryItems;
}

function manageGroupFullAccess ({
    action,
    selected
}: Pick<ManageRestrictGroups, 'action' | 'selected'>) {
    const updatedIsRestrictedToAssignmentsOnly = action.payload.isRestrictedToAssignmentsOnly;
    const selectedGroupId = selected.id.toString();
    const updatedRestrictedDictionaryItems: RestrictedDictionary = {};

    updatedRestrictedDictionaryItems[WHOLE_CLASS_GROUP_ID] = {
        isRestrictedToAssignmentsOnly: updatedIsRestrictedToAssignmentsOnly
    };
    updatedRestrictedDictionaryItems[selectedGroupId] = {
        isRestrictedToAssignmentsOnly: updatedIsRestrictedToAssignmentsOnly
    };

    return updatedRestrictedDictionaryItems;
}

export function manageGroupRestrict ({
    data,
    action,
    selected,
    dictionary
}: ManageRestrictGroups) {
    const updatedIsRestrictedToAssignmentsOnly = action.payload.isRestrictedToAssignmentsOnly;

    let updatedRestrictedDictionaryItems: RestrictedDictionary = {};

    if (updatedIsRestrictedToAssignmentsOnly) {
        updatedRestrictedDictionaryItems = manageGroupIsRestricted({
            data,
            action,
            selected,
            dictionary
        });
    } else {
        updatedRestrictedDictionaryItems = manageGroupFullAccess({
            action,
            selected
        });
    }

    return updatedRestrictedDictionaryItems;
}
