import { GlobalProps } from '@emotion/react';

import { scrollbar } from './scrollbar';

export const global = (props: GlobalProps) => ({
    body: {
        overflow: 'hidden'
    },
    ...scrollbar
});
