import {
    CleanupRouteSelectedAssignmentParams,
    ManageRouteCheckboxAssignmentsParams,
    UpdateRouteAssignmentDefaultProps,
    UpdateRouteSelectedAssignmentParams
} from '@containers/activity-router/routes/helpers/assignment/assignment-selected/route-assignments-selected.types';
import { AssignmentsSelected } from '@containers/activity-router/routes/helpers/slice/slice.types';

function updateRouteAssignment ({
    selectedAssignmentKey,
    userProfileId,
    isSelected
}: UpdateRouteAssignmentDefaultProps) {
    return isSelected ? selectedAssignmentKey.includes(userProfileId) ?
        selectedAssignmentKey : [...selectedAssignmentKey, userProfileId] :
        selectedAssignmentKey.filter((studentId) => studentId !== userProfileId);
}

function cleanupRouteSelectedAssignments ({
    selectedAssignments,
    assignmentAction,
    key
}: CleanupRouteSelectedAssignmentParams): AssignmentsSelected {
    const shouldCleanup = Object.keys(selectedAssignments[assignmentAction][key]).length === 0;

    if (shouldCleanup) delete selectedAssignments[assignmentAction][key];

    return selectedAssignments;
}

export function updateRouteSelectedAssignment ({
    selectedAssignments,
    assignmentAction,
    activityKey,
    ...params
}: UpdateRouteSelectedAssignmentParams) {
    selectedAssignments[assignmentAction][activityKey] = updateRouteAssignment({
        selectedAssignmentKey: selectedAssignments[assignmentAction][activityKey] || [],
        ...params
    });

    selectedAssignments = cleanupRouteSelectedAssignments({
        selectedAssignments,
        assignmentAction,
        key: activityKey
    });

    return selectedAssignments;
}

export function manageRouteCheckboxAssignments ({
    selectedAssignments,
    gridCells,
    isChecked,
    checkAssignmentEligibility,
    manageSelectedAssignments
}: ManageRouteCheckboxAssignmentsParams) {
    Object.keys(gridCells).forEach((studentKey) => {
        Object.keys(gridCells[studentKey]).forEach((topicKey) => {
            Object.keys(gridCells[studentKey][topicKey]).forEach((activityKey) => {
                if (checkAssignmentEligibility({
                    studentKey,
                    topicKey,
                    activityKey
                })) {
                    selectedAssignments = manageSelectedAssignments({
                        selectedAssignments,
                        activityKey,
                        studentKey,
                        isSelected: isChecked
                    });
                }
            });
        });
    });

    return selectedAssignments;
}
