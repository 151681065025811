/* eslint-disable max-len */
import { createIcon } from '@chakra-ui/icons';
import React from 'react';

export const InfoActiveIcon = createIcon({
    displayName: 'InfoActiveIcon',
    viewBox: '0 0 20 20',
    path: (
        <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.99935 3.33073C6.31745 3.33073 3.33268 6.3155 3.33268 9.9974C3.33268 13.6793 6.31745 16.6641 9.99935 16.6641C13.6812 16.6641 16.666 13.6793 16.666 9.9974C16.666 6.3155 13.6812 3.33073 9.99935 3.33073ZM1.66602 9.9974C1.66602 5.39502 5.39698 1.66406 9.99935 1.66406C14.6017 1.66406 18.3327 5.39502 18.3327 9.9974C18.3327 14.5998 14.6017 18.3307 9.99935 18.3307C5.39698 18.3307 1.66602 14.5998 1.66602 9.9974Z" fill="#0063A7" />
            <path d="M9.99935 8.33073C10.4596 8.33073 10.8327 8.70383 10.8327 9.16406V14.1641C10.8327 14.6243 10.4596 14.9974 9.99935 14.9974C9.53911 14.9974 9.16602 14.6243 9.16602 14.1641V9.16406C9.16602 8.70383 9.53911 8.33073 9.99935 8.33073Z" fill="#0063A7" />
            <path d="M11.2493 6.2474C11.2493 6.93775 10.6897 7.4974 9.99935 7.4974C9.30899 7.4974 8.74935 6.93775 8.74935 6.2474C8.74935 5.55704 9.30899 4.9974 9.99935 4.9974C10.6897 4.9974 11.2493 5.55704 11.2493 6.2474Z" fill="#0063A7" />
        </svg>

    )
});
