import {
    chakra,
    FormLabel,
    FormControl,
    Switch,
    keyframes, Flex
} from '@chakra-ui/react';

const fadeIn = keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
`;

export const container = chakra(Flex, {
    label: 'access-toggle-container',
    baseStyle: {
        w: {
            base: '185px',
            tablet: '221px'
        },
        alignItems: 'center',
        height: '100%',
        marginRight: '30'
    }
});

export const formLabelUnrestricted = chakra(FormLabel, {
    label: 'access-toggle-form-label-unrestricted',
    baseStyle: {
        fontSize: {
            base: 'xs',
            tablet: 'sm'
        },
        textAlign: 'right',
        w: {
            base: '150px',
            tablet: '175px'
        },
        h: 'auto',
        mt: {
            base: '4px',
            tablet: '8px'
        },
        mr: 0,
        animation: `${fadeIn} 1.5s`,
        fontWeight: 400,
        lineHeight: {
            base: '15px',
            tablet: '18px'
        },
        color: 'neutral.600'
    }
});

export const formLabelRestricted = chakra(formLabelUnrestricted, {
    label: 'access-toggle-form-label-restricted',
    baseStyle: {
        color: 'yellow.700'
    }
});

export const formControl = chakra(FormControl, {
    label: 'access-toggle-form-control',
    baseStyle: {
        h: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'column',
        justifyContent: 'center'
    }
});

export const accessRestrictSwitch = chakra(Switch, {
    label: 'access-restrict-switch',
    baseStyle: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: 0,
        w: {
            base: '66px',
            tablet: '94px'
        },
        h: 'auto'
    }
});

export const iconOverrideStyles = {
    w: {
        base: '22px',
        tablet: '40px'
    },
    h: {
        base: '28px',
        tablet: '40px'
    }
};
