import { parseActivityKey } from '@containers/activity-router/routes/helpers/keys/key-generator';
import {
    onMultiSelectClearAll,
    updateMultiSelectGridCellsAndCounters
} from '@containers/activity-router/routes/helpers/multi-select/multi-select-helpers';
import { ActivitiesPercentages } from '@containers/activity-router/routes/learn/activities/activities-constants';
import {
    GetTopicAvailability,
    ManageActivitiesMultiSelectParams,
    UpdateComplexityFilterCountersParams
} from '@containers/activity-router/routes/learn/activities/components/activities-multi-select/activities-multi-select.types';
import {
    getActivitiesSelectedAssignments
} from '@containers/activity-router/routes/learn/activities/helpers/slice/assignments/selected/assignments-selected-helpers';
import {
    manageSelectedComplexityCounters,
    resetActivitiesFiltersComplexityCounters
} from '@containers/activity-router/routes/learn/activities/helpers/slice/filters/complexity/filters-complexity-counters-helpers';
import { TransformedStudentAssignment } from '@services/gateways/classroom-report-gateway/classroom-report-gateway.types';
import { ActivitiesComplexity } from '@services/gateways/combined-assign-gateway/combined-assign-gateway.types';

const {
    SCORE_0,
    SCORE_50,
    SCORE_85,
    SCORE_100
} = ActivitiesPercentages;

function isValidResult (currentAssignment: TransformedStudentAssignment) {
    return (
        currentAssignment.result &&
        currentAssignment.result.score !== null &&
        currentAssignment.assignment === null
    );
}

function getTopicAvailability ({
    filteredTopics,
    topicId
}: GetTopicAvailability) {
    if (filteredTopics.length === 0) return true;
    if (filteredTopics.find((value) => value.id === topicId)) return true;

    return false;
}

function updateComplexityFilterCounters ({
    prevIsSelected,
    isSelected,
    activityId,
    selectedTopic,
    studentId,
    complexityCounters
}: UpdateComplexityFilterCountersParams) {
    if (prevIsSelected !== isSelected) {
        const { id, activityType } = parseActivityKey(activityId as string);
        const selectedActivity = selectedTopic?.activities?.find((activity) =>
            activity.id === id &&
            activity.activityType === activityType
        );

        if (selectedActivity && activityId) {
            complexityCounters = manageSelectedComplexityCounters({
                activityId,
                studentId,
                complexityCounters,
                complexity: selectedActivity.complexity as ActivitiesComplexity
            });
        }
    }

    return complexityCounters;
}

export function manageActivitiesMultiSelect ({
    option,
    gridCells,
    gridCounters,
    studentAssignments,
    selectedAssignments,
    filteredTopics,
    complexityCounters,
    course
}: ManageActivitiesMultiSelectParams) {
    Object.keys(gridCells).forEach((studentId) => {
        Object.keys(gridCells[studentId]).forEach((topicId) => {
            const selectedTopic = course?.topics.find((topic) => topic.id === topicId);
            const isTopicAvailable = getTopicAvailability({
                filteredTopics,
                topicId
            });

            Object.keys(gridCells[studentId][topicId]).forEach((activityId) => {
                const prevIsSelected = gridCells[studentId][topicId][activityId].isSelected;
                const isSelected = true;
                const hashKey = `${studentId}/${activityId}`;
                const currentAssignment = studentAssignments[hashKey];

                function genericSuccessCallback () {
                    if (isTopicAvailable) {
                        updateMultiSelectGridCellsAndCounters({
                            gridCells,
                            gridCounters,
                            studentId,
                            topicId,
                            activityId
                        });
                        getActivitiesSelectedAssignments({
                            currentAssignment,
                            selectedAssignments,
                            activityKey: activityId,
                            isSelected,
                            userProfileId: studentId
                        });
                        updateComplexityFilterCounters({
                            prevIsSelected,
                            isSelected,
                            activityId,
                            studentId,
                            selectedTopic,
                            complexityCounters
                        });
                    }
                }

                switch (option) {
                case 'All Cells': genericSuccessCallback(); break;
                case 'Assigned Now': {
                    if (currentAssignment.assignment) {
                        const {
                            assignment: {
                                isAssignmentPastDueDate,
                                isAFutureAssignment
                            }
                        } = currentAssignment;

                        if (!isAssignmentPastDueDate && !isAFutureAssignment) {
                            genericSuccessCallback();
                        }
                    }
                    break;
                }
                case 'Assigned Later': {
                    if (currentAssignment.assignment?.isAFutureAssignment) genericSuccessCallback();
                    break;
                }
                case 'Overdue': {
                    if (currentAssignment.assignment?.isAssignmentPastDueDate) genericSuccessCallback();
                    break;
                }
                case 'Low Range Correct results': {
                    if (isValidResult(currentAssignment)) {
                        const { score } = currentAssignment.result;

                        if (score >= SCORE_0 && score < SCORE_50) genericSuccessCallback();
                    }
                    break;
                }
                case 'Mid Range Correct results': {
                    if (isValidResult(currentAssignment)) {
                        const { score } = currentAssignment.result;

                        if (score >= SCORE_50 && score < SCORE_85) genericSuccessCallback();
                    }
                    break;
                }
                case 'High Range Correct results': {
                    if (isValidResult(currentAssignment)) {
                        const { score } = currentAssignment.result;

                        if (score >= SCORE_85 && score <= SCORE_100) genericSuccessCallback();
                    }
                    break;
                }
                case 'Clear All': {
                    onMultiSelectClearAll({
                        gridCells,
                        gridCounters,
                        studentId,
                        topicId,
                        activityId
                    });
                    getActivitiesSelectedAssignments({
                        currentAssignment,
                        selectedAssignments,
                        activityKey: activityId,
                        isSelected: false,
                        userProfileId: studentId
                    });

                    complexityCounters = resetActivitiesFiltersComplexityCounters(complexityCounters);
                }
                }
            });
        });
    });

    return {
        gridCells,
        gridCounters,
        complexityCounters
    };
}
