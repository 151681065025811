import { chakra, Flex } from '@chakra-ui/react';

import { CellDimensions } from '@containers/activity-router/data-grid-container/data-grid-container.styled';

export const checkboxWrapper = chakra(Flex, {
    label: 'loading-route-checkboxWrapper',
    baseStyle: {
        ...CellDimensions,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'neutral.200'
    }
});

export const checkboxInput = {
    borderRadius: '6px',
    h: '32px',
    w: '32px'
};
