import { getTopicKey, parseStrandCourseKey } from '@containers/activity-router/routes/helpers/keys/key-generator';
import { GridCount } from '@containers/activity-router/routes/helpers/slice/slice.types';
import { GridCounters } from '@containers/activity-router/routes/helpers/slice/topic/topic-slice.types';

type GenerateOverviewGridCounterParams = {
    gridCounters: GridCounters
    strandCourseId: string
};

const updateStudentCount = (gridCounters: GridCounters) => {
    Object.keys(gridCounters[GridCount.studentCount] || {}).forEach((studentKey) => {
        const studentCount = gridCounters[GridCount.studentCount]?.[studentKey];

        if (studentCount) studentCount.totalActivities += 1;
    });
};
const updateTopicCount = (gridCounters: GridCounters, topicKey: string) => {
    const topicCount = gridCounters[GridCount.topicCount] || {};

    gridCounters[GridCount.topicCount] = {
        ...topicCount,
        [topicKey]: {
            selectedActivities: topicCount[topicKey]?.selectedActivities ?? 0,
            totalActivities: (topicCount[topicKey]?.totalActivities ?? 0) + 1
        }
    };
};
const updateTopicActivityCount = (gridCounters: GridCounters, topicKey: string) => {
    Object.keys(gridCounters[GridCount.topicActivityCount] || {}).forEach((studentKey) => {
        const topicActivityCount = gridCounters[GridCount.topicActivityCount]?.[studentKey]?.[topicKey] || {};

        gridCounters[GridCount.topicActivityCount][studentKey] = {
            ...gridCounters[GridCount.topicActivityCount][studentKey],
            [topicKey]: {
                selectedActivities: topicActivityCount.selectedActivities ?? 0,
                totalActivities: (topicActivityCount.totalActivities ?? 0) + 1
            }
        };
    });
};
const updateActivityCount = (gridCounters: GridCounters, topicKey: string, activityKey: string) => {
    const activityCount = gridCounters[GridCount.activityCount] || {};

    gridCounters[GridCount.activityCount] = {
        ...activityCount,
        [topicKey]: {
            ...activityCount[topicKey],
            [activityKey]: {
                selectedActivities: activityCount[topicKey]?.[activityKey]?.selectedActivities ?? [],
                totalActivities: (activityCount[topicKey]?.[activityKey]?.totalActivities ?? 0) + 1
            }
        }
    };
};

export const generateOverviewGridCounter = ({
    gridCounters,
    strandCourseId
}: GenerateOverviewGridCounterParams) => {
    const { strandId, topicId, activityId: activityKey } = parseStrandCourseKey(strandCourseId);
    const topicKey = getTopicKey({ strandId,
        topicId });
    const isVisible = !!gridCounters?.[GridCount.activityCount]?.[topicKey]?.[activityKey];

    if (!isVisible) {
        updateStudentCount(gridCounters);
        updateTopicCount(gridCounters, topicKey);
        updateTopicActivityCount(gridCounters, topicKey);
        updateActivityCount(gridCounters, topicKey, activityKey);
    }

    return gridCounters;
};
