import { getTranslations } from './level-filter.translations';
import { DropdownProps } from '@components/dropdown/dropdown.types';

export const defaultLevelFilterValue = 0;

export const defaultLevelFilterArray = (): DropdownProps['options'] => {
    const {
        SELECT,
        LEVEL
    } = getTranslations();

    return [
        {
            label: SELECT,
            value: defaultLevelFilterValue,
            disabled: false
        },
        {
            label: `${LEVEL} 2`,
            value: 2,
            disabled: false
        },
        {
            label: `${LEVEL} 3`,
            value: 3,
            disabled: false
        },
        {
            label: `${LEVEL} 4`,
            value: 4,
            disabled: false
        },
        {
            label: `${LEVEL} 5`,
            value: 5,
            disabled: false
        },
        {
            label: `${LEVEL} 6`,
            value: 6,
            disabled: false
        },
        {
            label: `${LEVEL} 7`,
            value: 7,
            disabled: false
        },
        {
            label: `${LEVEL} 8`,
            value: 8,
            disabled: false
        },
        {
            label: `${LEVEL} 9`,
            value: 9,
            disabled: false
        },
        {
            label: `${LEVEL} 10`,
            value: 10,
            disabled: false
        },
        {
            label: `${LEVEL} 11`,
            value: 11,
            disabled: false
        }
    ];
};
