import { useLingui } from '@lingui/react';
import React from 'react';

import * as styled from './access-toggle.styled';

type FormLabelProps = {
    isChecked: boolean
    labels: {
        checked: string
        unchecked: string
    }
};

export const FormLabel = ({
    isChecked,
    labels
}: FormLabelProps) => {
    const { i18n: { _locale } } = useLingui();
    const labelWidth = () => {
        if (_locale.toLowerCase() === 'es-mx') {
            return {
                width: {
                    base: '190px',
                    tablet: '220px'
                }
            };
        }
    };

    if(isChecked) {
        return (
            <styled.formLabelRestricted htmlFor="access-toggle" mb="0" {...labelWidth()}>
                {labels.checked}
            </styled.formLabelRestricted>
        );
    }

    return (
        <styled.formLabelUnrestricted htmlFor="access-toggle" mb="0" {...labelWidth()}>
            {labels.unchecked}
        </styled.formLabelUnrestricted>
    );
};
