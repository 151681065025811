import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { useEffect } from 'react';

import { useEnvConfig } from '../../env/env-config-provider';

export const useSentry = () => {
    const { ENVIRONMENT } = useEnvConfig();

    function initSentry () {
        Sentry.init({
            dsn: 'https://fce7090a086546b0ab1ff089f3b4359b@ase.3plearning.com/27',
            integrations: [new BrowserTracing()],
            environment: window._env_.ENVIRONMENT,
            tracesSampleRate: window._env_.ENVIRONMENT === 'prod' ? 0.2 : 1.0,
            ignoreErrors: [
                // fetch cancelled errors to ignore
                // https://stackoverflow.com/questions/55738408/javascript-typeerror-cancelled-error-when-calling-fetch-on-ios
                'TypeError: Failed to fetch',
                'TypeError: NetworkError when attempting to fetch resource.',
                'TypeError: cancelled'
            ],
            beforeSend (event) {
                if (window._env_.ENVIRONMENT === 'local') return null;

                return event;
            },
            denyUrls: [
                // Chrome extensions
                /extensions\//i,
                /^chrome:\/\//i
            ]
        });
    }

    useEffect(() => {
        if (ENVIRONMENT !== 'local') {
            initSentry();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
