export enum AppLandingConstants {
    name = 'appLanding'
}

export enum AppLandingModalConstants {
    errorHome,
    errorConnection,
    viewportGuide,
    skillQuestsRefresh,
    challengesRefresh,
    assessmentsRefresh
}

export enum AppLandingModalTypes {
    route,
    viewport,
    initData,
    assignment,
    restrict,
    course,
    student,
    assignmentCallback,
    assignedActivities,
    navigation
}

export type AppLandingModal = {
    variant: AppLandingModalConstants | null
    type: AppLandingModalTypes | null
};

export type AppLandingState = {
    modal: AppLandingModal
};

export const getAppLandingModalDefault = (): AppLandingModal => ({
    variant: null,
    type: null
});
