import {
    manageActivityGridCheckbox,
    manageTopicGridCheckbox
} from '@containers/activity-router/routes/helpers/courses/course-checkbox-helpers';
import {
    updateGridActivityCounters
} from '@containers/activity-router/routes/helpers/grid/counters/activity-grid-counters/activity-grid-counters';
import {
    manageStudentGridCounters
} from '@containers/activity-router/routes/helpers/grid/counters/student-grid-counters/student-grid-counters';
import {
    updateTopicGridCounters
} from '@containers/activity-router/routes/helpers/grid/counters/topic-grid-counters/topic-grid-counters';
import {
    ToggleActivityGridCheckboxProps,
    ToggleStudentGridCheckboxProps,
    ToggleTopicGridCheckboxProps
} from '@containers/activity-router/routes/helpers/slice/checkbox/slice-checkbox-helpers.types';
import {
    manageStudentGridCheckbox
} from '@containers/activity-router/routes/helpers/student-list/student-list-checkbox-helpers/student-list-checkbox-helpers';

export const toggleStudentGridCheckbox = ({
    gridCells,
    gridCounters,
    isChecked,
    studentId
}: ToggleStudentGridCheckboxProps) => {
    gridCells = manageStudentGridCheckbox({
        gridCells,
        updatedStudentId: studentId,
        isChecked
    });
    gridCounters = manageStudentGridCounters({
        counters: gridCounters,
        isChecked,
        studentId
    });

    return {
        gridCells,
        gridCounters
    };
};

export const toggleTopicGridCheckbox = ({
    gridCells,
    gridCounters,
    topicId,
    isChecked
}: ToggleTopicGridCheckboxProps) => {
    gridCells = manageTopicGridCheckbox({
        gridCells,
        updatedTopicId: topicId,
        isChecked
    });
    gridCounters = updateTopicGridCounters({
        counters: gridCounters,
        topicId,
        isChecked
    });

    return {
        gridCells,
        gridCounters
    };
};

export const toggleActivityGridCheckbox = ({
    gridCells,
    gridCounters,
    topicId,
    isChecked,
    activityId
}: ToggleActivityGridCheckboxProps) => {
    gridCells = manageActivityGridCheckbox({
        gridCells,
        updatedActivityId: activityId,
        isChecked
    });
    gridCounters = updateGridActivityCounters({
        counters: gridCounters,
        topicId,
        activityId,
        isChecked
    });

    return {
        gridCells,
        gridCounters
    };
};
