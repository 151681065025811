/* eslint-disable no-tabs */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
    getInitialFilteredCourseData
} from './view-assigned-activities-modal-helpers';
import {
    ViewAssignmentModalConstants,
    ViewAssignmentModalState,
    ActivitiesAssignmentModalCheckbox,
    TopicsAssignmentModalCheckbox,
    AllTopicsAssignmentModalCheckbox,
    SetAssignmentModalStudentActivityFilter,
    ActivityFilters,
    InitialiseAssignedActivitiesParams
} from './view-assigned-activities-modal.types';
import { RootState } from '@config/react-redux/store';
import { SetCourseExpandCollapse } from '@containers/activity-router/data-grid-container/courses/types/courses.types';
import {
    manageCourseStrandIsExpanded,
    manageCourseTopicIsExpanded
} from '@containers/activity-router/routes/helpers/courses/course-expand-helpers';
import {
    initAssignedActivitiesCourseData
} from '@services/gateways/combined-assign-gateway/transform/courses/courses-transform';

const initialState: ViewAssignmentModalState = {
    isOpen: false,
    triggerRefetch: false,
    currentStudent: {
        userProfileId: null,
        groupId: null
    },
    selectedActivities: {},
    studentsData: {
        [ActivityFilters.ASSIGNED]: {},
        [ActivityFilters.OVERDUE]: {},
        [ActivityFilters.COMPLETED]: {}
    },
    course: {
        selected: null,
        filtered: null
    },
    activityFilter: ActivityFilters.ASSIGNED,
    assignments: {}
};

export const viewAssignedActivitiesModalSlice = createSlice({
    name: ViewAssignmentModalConstants.name,
    initialState,
    reducers: {
        onActivityAssignmentModalClickHandler (
            state,
            action: PayloadAction<Omit<InitialiseAssignedActivitiesParams, 'assignments' | 'studentsData'>>
        ) {
            const {
                course,
                currentStudent
            } = action.payload;

            state.currentStudent = currentStudent;
            state.course.selected = course;
            state.selectedActivities = {};
        },
        setActivityAssignmentModalInitAssignment (
            state,
            action: PayloadAction<Pick<InitialiseAssignedActivitiesParams, 'assignments' | 'studentsData'>>
        ) {
            const {
                studentsData,
                assignments
            } = action.payload;

            state.studentsData = studentsData;
            state.course.filtered = getInitialFilteredCourseData({
                course: state.course.selected,
                selectedStudentData: studentsData[state.activityFilter][state.currentStudent.userProfileId as number]
            });
            state.assignments = assignments;
        },
        setActivityAssignmentModalUpdatedAssignments (
            state,
            action: PayloadAction<Pick<InitialiseAssignedActivitiesParams, 'studentsData' | 'assignments'>>
        ) {
            const {
                studentsData,
                assignments
            } = action.payload;

            state.studentsData = studentsData;
            state.course.filtered = getInitialFilteredCourseData({
                course: state.course.filtered,
                selectedStudentData: studentsData[state.activityFilter][state.currentStudent.userProfileId as number],
                persistExpanded: true
            });
            state.assignments = assignments;
            state.selectedActivities = {};
            state.triggerRefetch = false;
        },
        onActivityAssignmentModalNavigationHandler (
            state,
            action: PayloadAction<Pick<InitialiseAssignedActivitiesParams, 'course' | 'currentStudent'>>
        ) {
            const {
                course,
                currentStudent
            } = action.payload;

            state.currentStudent = currentStudent;
            state.course.selected = course;
            state.course.filtered = getInitialFilteredCourseData({
                course,
                selectedStudentData: state.studentsData[state.activityFilter][currentStudent.userProfileId as number]
            });
            state.selectedActivities = {};
        },
        setActivityAssignmentModalTriggerRefetch (state, action: PayloadAction<boolean>) {
            state.triggerRefetch = action.payload;
        },
        resetActivityAssignmentModalCourseSelected (state) {
            if (state.course.selected && state.currentStudent.userProfileId) {
                state.course.selected = initAssignedActivitiesCourseData(state.course.selected);
                state.course.filtered = getInitialFilteredCourseData({
                    course: state.course.selected,
                    selectedStudentData: state.studentsData[state.activityFilter][state.currentStudent.userProfileId]
                });
            }
        },
        setActivityAssignmentModalActivityFilter (state, action: PayloadAction<SetAssignmentModalStudentActivityFilter>) {
            state.activityFilter = action.payload.activityFilter;
        },
        setActivityAssignmentModalExpandCollapse (state, action: PayloadAction<SetCourseExpandCollapse>) {
            const {
                topicId,
                strandId,
                isExpanded
            } = action.payload;
            const isStrand = state.course.filtered && state.course.filtered?.topics[0].topics !== null;

            if (state.course.filtered) {
                if (isStrand) {
                    state.course.filtered.topics = manageCourseStrandIsExpanded({
                        course: state.course.filtered,
                        payload: {
                            isExpanded,
                            strandId,
                            topicId
                        }
                    });
                } else {
                    state.course.filtered.topics = manageCourseTopicIsExpanded({
                        course: state.course.filtered,
                        payload: {
                            isExpanded,
                            topicId
                        }
                    });
                }
            }
        },
        clearActivityAssignmentModalSelectedActivities (state) {
            state.selectedActivities = {};
        },
        toggleActivitiesAssignmentModalCheckbox (state, action: PayloadAction<ActivitiesAssignmentModalCheckbox>) {
            const { topicId, activityId, isReassigned } = action.payload;
            const selectedActivities = state.selectedActivities[topicId] || [];
            const activityIndex = selectedActivities.findIndex(activity => {
                return activity.activityKey === activityId;
            });
            const updatedActivities = selectedActivities.filter(activity => activity.activityKey !== activityId);

            if (activityIndex !== -1) {
                state.selectedActivities[topicId] = updatedActivities;
            } else {
                state.selectedActivities[topicId] = [...selectedActivities, {
                    activityKey: activityId,
                    isReassigned
                }];
            }
        },
        toggleTopicsAssignmentModalCheckbox (state, action: PayloadAction<TopicsAssignmentModalCheckbox>) {
            const { topicId, isChecked, filteredActivityIds } = action.payload;
            const filteredActivities = filteredActivityIds.filter(activities => !activities.isReassigned);

            state.selectedActivities[topicId] = isChecked ? [...filteredActivities] : [];
        },
        toggleAllTopicsAssignmentModalCheckbox (state, action: PayloadAction<AllTopicsAssignmentModalCheckbox>) {
            const { isChecked } = action.payload;

            if (!isChecked) {
                state.selectedActivities = {};

                return;
            }

            const { activityFilter, studentsData } = state;
            const selectedStudentData = studentsData[activityFilter][state.currentStudent.userProfileId as number];

            if (selectedStudentData) {
                Object.keys(selectedStudentData).forEach((topicKey) => {
                    const { selectedActivities } = state;
                    const activities = Object.keys(selectedStudentData[topicKey].topics);
                    const activityKeys = activities.map(activityKey => ({ activityKey }));

                    selectedActivities[topicKey] = activityKeys.map(({ activityKey }) => ({
                        activityKey,
                        isReassigned: selectedStudentData[topicKey].topics[activityKey].isReassigned
                    }));
                });
            }
        },
        setActivityAssignmentModalOpen (state, action: PayloadAction<boolean>) {
            state.isOpen = action.payload;
        },
        closeActivityAssignmentModal: () => initialState
    }
});

/**
 * ACTIONS
 */
export const {
    onActivityAssignmentModalClickHandler,
    setActivityAssignmentModalInitAssignment,
    setActivityAssignmentModalUpdatedAssignments,
    onActivityAssignmentModalNavigationHandler,
    resetActivityAssignmentModalCourseSelected,
    setActivityAssignmentModalExpandCollapse,
    closeActivityAssignmentModal,
    clearActivityAssignmentModalSelectedActivities,
    toggleActivitiesAssignmentModalCheckbox,
    toggleTopicsAssignmentModalCheckbox,
    toggleAllTopicsAssignmentModalCheckbox,
    setActivityAssignmentModalTriggerRefetch,
    setActivityAssignmentModalActivityFilter,
    setActivityAssignmentModalOpen
} = viewAssignedActivitiesModalSlice.actions;

/**
 * SELECTORS
 */
export const selectViewAssignedActivitiesModalCourseSelected = (state: RootState) =>
    state[ViewAssignmentModalConstants.name].course.selected;

export const selectViewAssignedActivitiesModalCourseFiltered = (state: RootState) =>
    state[ViewAssignmentModalConstants.name].course.filtered;

export const selectViewAssignedActivitiesModalSelectedStudent = (state: RootState) =>
    state[ViewAssignmentModalConstants.name].currentStudent.userProfileId;

export const selectViewAssignedActivitiesModalSelectedStudentGroupId = (state: RootState) =>
    state[ViewAssignmentModalConstants.name].currentStudent.groupId;

export const selectViewAssignedActivitiesModalStudentData = (state: RootState) =>
    state[ViewAssignmentModalConstants.name].studentsData;

export const selectViewAssignedActivitiesModalSelectedActivities = (state: RootState) =>
    state[ViewAssignmentModalConstants.name].selectedActivities;

export const selectViewAssignedActivitiesModalIsOpen = (state: RootState) =>
    state[ViewAssignmentModalConstants.name].isOpen;

export const selectViewAssignedActivitiesModalTriggerRefetch = (state: RootState) =>
    state[ViewAssignmentModalConstants.name].triggerRefetch;

export const selectViewAssignedActivitiesModalActivityFilter = (state: RootState) =>
    state[ViewAssignmentModalConstants.name].activityFilter;

export const selectViewAssignedActivitiesModalAssignments = (state: RootState) =>
    state[ViewAssignmentModalConstants.name].assignments;

export default viewAssignedActivitiesModalSlice.reducer;
