import {
    Box,
    chakra,
    Flex
} from '@chakra-ui/react';

import { PagePadding } from '../../style/constants';

export const CLASS_GROUP_FILTERS_CONTAINER_HEIGHT = {
    base: '92px',
    tablet: '108px'
};

export const container = chakra(Box, {
    label: 'class-group-filters-container',
    baseStyle: {
        w: '100%',
        h: CLASS_GROUP_FILTERS_CONTAINER_HEIGHT,
        paddingRight: PagePadding.right,
        paddingLeft: PagePadding.left
    }
});

export const wrapper = chakra(Box, {
    label: 'class-group-filters-wrapper',
    baseStyle: {
        w: '100%',
        h: '100%',
        borderRadius: 'xl',
        bgColor: 'white.0',
        boxShadow: '0 2px 8px rgba(12, 23, 48, 0.08)'
    }
});

export const flex = chakra(Flex, {
    label: 'class-group-filters-flex',
    baseStyle: {
        w: '100%',
        h: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: '9'
    }
});

export const stack = chakra(Flex, {
    label: 'class-group-filters-hstack',
    baseStyle: {
        width: {
            base: '615px',
            tablet: '787px'
        },
        alignItems: 'baseline',
        justifyContent: 'flex-start',
        marginRight: '32px',
        gap: {
            base: 8,
            tablet: 10
        }
    }
});
