import { UpdateGridCellsAndCountersParams } from './multi-select-helpers.types';
import { GridCount } from '@containers/activity-router/routes/helpers/slice/slice.types';

const {
    studentCount,
    topicCount,
    topicActivityCount,
    activityCount
} = GridCount;

export function updateMultiSelectGridCellsAndCounters ({
    gridCells,
    gridCounters,
    studentId,
    topicId,
    activityId
}: UpdateGridCellsAndCountersParams) {
    const isCurrentlySelected = gridCells[studentId][topicId][activityId].isSelected;

    gridCells[studentId][topicId][activityId].isSelected = true;

    if (!isCurrentlySelected) {
        gridCounters[studentCount][studentId].selectedActivities += 1;
        gridCounters[topicCount][topicId].selectedActivities += 1;
        gridCounters[topicActivityCount][studentId][topicId].selectedActivities += 1;
        gridCounters[activityCount][topicId][activityId].selectedActivities = [
            ...gridCounters[activityCount][topicId][activityId].selectedActivities,
            studentId
        ];
    }
}

export function onMultiSelectClearAll ({
    gridCells,
    gridCounters,
    studentId,
    topicId,
    activityId
}: UpdateGridCellsAndCountersParams) {
    gridCells[studentId][topicId][activityId].isSelected = false;

    gridCounters[studentCount][studentId].selectedActivities = 0;
    gridCounters[topicCount][topicId].selectedActivities = 0;
    gridCounters[topicActivityCount][studentId][topicId].selectedActivities = 0;
    gridCounters[activityCount][topicId][activityId].selectedActivities = [];
}
