/* eslint-disable max-len */
/* eslint-disable no-mixed-spaces-and-tabs */
import { FlexProps } from '@chakra-ui/react';
import { MouseEvent } from 'react';

import {
    AssignedActivitiesModalHeaderProps
} from './components/assigned-activities-navigation/components/assigned-acitivities-modal-header/view-assigned-activities-modal-header';
import { DropdownOption } from '@components/dropdown/dropdown.types';
import { CourseData } from '@containers/activity-router/data-grid-container/courses/types/courses.types';
import { GridFooterButtonProps } from '@containers/activity-router/data-grid-container/grid-footer/grid-footer.types';
import {
    Percentage
} from '@containers/activity-router/data-grid-container/student-list/components/assigned-activities/assigned-activities.types';
import {
    GetSelectedAssignment, GetSelectedAssignmentsReturn,
    GroupId,
    TopicId,
    UserProfileId
} from '@containers/activity-router/data-grid-container/student-list/student-list.types';
import { FooterButtonProps } from '@containers/activity-router/routes/helpers/footer/footer-helpers.types';
import { RouteHelpersModalConstants } from '@containers/activity-router/routes/helpers/modal/route-modal-helpers.types';
import { ActivityId } from '@containers/activity-router/routes/helpers/slice/slice.types';
import { AssignmentResultType } from '@containers/activity-router/routes/learn/activities/activities-constants';
import { NewCoursesAssignmentResultType } from '@containers/activity-router/routes/learn/new-courses/new-courses-constants';
import {
    CombinedAssignInProgressAttemptTeacherStatus,
    SelfReflections
} from '@services/gateways/combined-assign-gateway/combined-assign-gateway.types';

export enum ViewAssignmentModalConstants {
    name = 'viewAssignmentModal'
}

export enum ActivityFilters {
    ASSIGNED = 'ASSIGNED',
    COMPLETED = 'COMPLETED',
    OVERDUE = 'OVERDUE'
}

type GetButtonPropsParams = {
    modalValue: RouteHelpersModalConstants
    isRequestPending: boolean
    setIsRequestPending: (value: boolean) => void
};

export type GetResultStyle = (score: number) => FlexProps;

export type ViewAssignmentClickProps = {
    userProfileId: UserProfileId
    encryptedUserProfileId: string
    groupId: GroupId
    onOpen: () => void
};

export type ViewAssignmentClick = ({
    userProfileId,
    groupId,
    onOpen,
    encryptedUserProfileId
}: ViewAssignmentClickProps) => void;

export type AssignmentToggle = AssignmentResultType | NewCoursesAssignmentResultType;

export type AssignedActivitiesRequestPending = Pick<FooterButtonProps, 'isRequestPending' | 'setIsRequestPending'>;

export type UseGetAssignmentModalDataProps = { assignmentToggle: AssignedActivitiesAssignmentToggle };

export type UseGetAssignmentModalDataReturn = { isLoadingAssignmentData: boolean };

type AssignmentModalEmptyState = {
    translations: {
        NOTHING_CURRENTLY_ASSIGNED: string
        NOTHING_CURRENTLY_ASSIGNED_DESCRIPTION: string
        NO_OVERDUE_ACTIVITIES: string
        NO_OVERDUE_ACTIVITIES_DESCRIPTION: string
    }
    getCompletedDisplayText: (assignmentToggle: AssignmentToggle) => {
        title: string
        description: string
    }
};

type AssignmentModalColumns = {
    [key in ActivityFilters]: Array<AssignedActivitiesTableColumns>
};

export type AssignedActivitiesGridStudentData =
    AssignedActivitiesAssignedStudentData |
    AssignedActivitiesCompletedStudentData |
    AssignedActivitiesOverdueStudentData;

export type OptionalGridComponentProps = {
    getResultStyle?: GetResultStyle
};

export type AssignedColumn = {
    headerComponent: () => JSX.Element
    gridComponent: (data: AssignedActivitiesGridStudentData, optional?: OptionalGridComponentProps) => JSX.Element
};

export type AssignedColumnMap = {
    [key in keyof typeof AssignedActivitiesTableColumns]?: AssignedColumn;
};

export type UseAttemptsDataParams = {
    assignmentToggle: AssignmentToggle
};

export type SetAssignedActivitiesAttempts = (activityId: ActivityId, isExpanded: boolean) => void;

export type UseAttemptsDataReturn = {
    attemptsData: AssignedActivitiesAttemptsData
    updateSelectedActivities: SetAssignedActivitiesAttempts
};

export type DisabledCheckboxTooltipLabels = {
	assigned: string
	partiallyAssigned: string
};

export type AssignmentModalActivitiesProps = AssignedActivitiesRequestPending & {
	tabs: Array<{ type: ActivityFilters }>
    columns: AssignmentModalColumns
    viewAssignmentClick: ViewAssignmentClick
    footerProps: { getButtonProps: (params: GetButtonPropsParams) => GridFooterButtonProps }
	useGetAssignmentData: (props: UseGetAssignmentModalDataProps) => UseGetAssignmentModalDataReturn
	assignmentOptions: DropdownOption[]
    emptyState: AssignmentModalEmptyState
    useAttemptsData: (params: UseAttemptsDataParams) => UseAttemptsDataReturn
	disabledCheckboxTooltipLabels: DisabledCheckboxTooltipLabels
    getResultStyle?: GetResultStyle
};

export type Attempts = {
    attempt: number
    completionDate: string
    isAssignedResult: boolean
    score:number
};

export type Attempt = {
    activityTaskId: number
    taskType: number
    attempts: Attempts[]
};

export type FilteredActivityIds = {
    activityKey: ActivityId
    isReassigned?: boolean
}[];

export type ViewAssignedActivitiesProps = {
    viewAssignmentClick: (props: Omit<ViewAssignmentClickProps, 'onOpen'>) => void
    studentDetails: Omit<ViewAssignmentClickProps, 'onOpen'>
};

export type SetAssignmentModalStudentActivityFilter = {
    activityFilter: ActivityFilters
};

export type Result = {
    attempts: number
    completionDate: string | null
    isAssignedResult: boolean
    score: null | number
    taskType?: number
};

export type Activities = {
    activityTaskId: number
    taskType: number
};

export type SelectedActivities = Record<TopicId, FilteredActivityIds>;

export type ViewAssignmentModalState = {
    isOpen: boolean
    triggerRefetch: boolean
    currentStudent: AssignedActivitiesModalCurrentStudent
    selectedActivities: SelectedActivities
    studentsData: AssignedActivitiesStudentData
    assignments: any
    course: {
        selected: CourseData
        filtered: CourseData
    }
    activityFilter: ActivityFilters
};

export type AssignedActivitiesModalCurrentStudent = {
    userProfileId: UserProfileId | null
    groupId: GroupId
};

export type ViewAssignedActivitiesModalProps = {
    isOpen: boolean
    onClose: () => void
    assignedActivitiesModalProps: AssignmentModalActivitiesProps
    getSelectedAssignment: GetSelectedAssignment
};

export type StudentDetailsProps = Pick<AssignedActivitiesModalHeaderProps, 'userProfileId' | 'studentsData'> & {
    percentage: GetSelectedAssignmentsReturn['percentage']
};

export type InitialiseAssignedActivitiesParams = {
    course: CourseData
    studentsData: AssignedActivitiesStudentData
    currentStudent: Pick<AssignedActivitiesModalCurrentStudent, 'userProfileId' | 'groupId'>
    assignments: any
};

export type AssignedActivitiesOnClose = (e: MouseEvent) => void;

export type ActivitiesAssignmentModalCheckbox = {
    topicId: TopicId
    activityId: ActivityId
    isReassigned?: boolean
};

export type GetActivityCheckboxStateProps = ActivitiesAssignmentModalCheckbox & {
    isReassigned: boolean
};

export type TopicsAssignmentModalCheckbox = {
    topicId: TopicId
    isChecked: boolean
    filteredActivityIds: FilteredActivityIds
};

export type AllTopicsAssignmentModalCheckbox = {
    isChecked: boolean
};

export enum AssignedActivitiesAssignedStatus {
    isAFutureAssignment = 'isAFutureAssignment',
    isAssignmentPastDueDate = 'isAssignmentPastDueDate',
    assigned = 'assigned'
}

export enum AssignedActivitiesTableColumns {
    combinedDate = 'combinedDate',
    assignedDate = 'assignedDate',
    dueDate = 'dueDate',
    attempts = 'attempts',
    dateCompleted = 'dateCompleted',
    accuracy = 'accuracy',
    result = 'result',
    reflection = 'reflection',
    isVoluntaryAttempt = 'isVoluntaryAttempt',
    assignedStatus = 'assignedStatus',
    resultType = 'resultType',
    status = 'status',
    messages = 'messages'
}

export enum AssignedActivitiesTableRow {
    isReassigned = 'isReassigned',
	currentAssignmentId = 'currentAssignmentId'
}

export type AssignedDate = string | null;

export type DueDate = string | null;

export type DateCompleted = string | null;

export type AssignedActivitiesAssignedStudentData = {
    [AssignedActivitiesTableColumns.assignedDate]: AssignedDate
    [AssignedActivitiesTableColumns.dueDate]: DueDate
    [AssignedActivitiesTableColumns.attempts]: number
    [AssignedActivitiesTableColumns.assignedStatus]: AssignedActivitiesAssignedStatus
    [AssignedActivitiesTableRow.isReassigned]: undefined
    [AssignedActivitiesTableColumns.messages]?: CombinedAssignInProgressAttemptTeacherStatus
};

export type AssignedActivitiesCompletedStudentData = {
    [AssignedActivitiesTableColumns.dateCompleted]: DateCompleted
    [AssignedActivitiesTableColumns.attempts]: number
    [AssignedActivitiesTableColumns.isVoluntaryAttempt]: boolean
    [AssignedActivitiesTableColumns.accuracy]: number | null
    [AssignedActivitiesTableRow.isReassigned]: boolean
    [AssignedActivitiesTableRow.currentAssignmentId]?: number | null
    [AssignedActivitiesTableColumns.reflection]?: SelfReflections | null
    [AssignedActivitiesTableColumns.messages]?: CombinedAssignInProgressAttemptTeacherStatus
};

export type AssignedActivitiesOverdueStudentData = {
    [AssignedActivitiesTableColumns.assignedStatus]: AssignedActivitiesAssignedStatus.isAssignmentPastDueDate
    [AssignedActivitiesTableColumns.assignedDate]: AssignedDate
    [AssignedActivitiesTableColumns.dueDate]: DueDate
    [AssignedActivitiesTableColumns.attempts]: number
    [AssignedActivitiesTableRow.isReassigned]: undefined
    [AssignedActivitiesTableColumns.messages]?: CombinedAssignInProgressAttemptTeacherStatus
};

export type AssignedActivitiesUserStudentData = {
    percentage?: Percentage
    activitiesWithMultipleAttempts?: Activities[]
    totalReassignedActivities?: number | null
	averageAccuracy?: number | null
};

export type AssignedActivitiesStudentDataUser = {
    [TopicId: string]: {
		data: AssignedActivitiesUserStudentData
		topics: AssignedActivitiesStudentTopicData
	}
};

export type AssignedActivitiesAttemptsData = {
    [ActivityId: string]: Array<AssignedActivitiesCompletedStudentData>
};

export type AssignedActivitiesStudentTopicData = {
        [ActivityId: string]: AssignedActivitiesGridStudentData
    };

export type AssignedActivitiesStudentData = {
    [ActivityFilters.ASSIGNED]:
        Record<UserProfileId, {
            [TopicId: string]: {
                data: AssignedActivitiesUserStudentData
                topics: {
                    [ActivityId: string]: AssignedActivitiesAssignedStudentData
                }
            }
        }>
    [ActivityFilters.COMPLETED]:
        Record<UserProfileId, {
            [TopicId: string]: {
                data: AssignedActivitiesUserStudentData
                topics: {
                    [ActivityId: string]: AssignedActivitiesCompletedStudentData
                }
            }
        }>
    [ActivityFilters.OVERDUE]:
        Record<UserProfileId, {
            [TopicId: string]: {
                data: AssignedActivitiesUserStudentData
                topics: {
                    [ActivityId: string]: AssignedActivitiesOverdueStudentData
                }
            }
        }>
};

export type UseGetModalHeaderCheckboxStateProps = {
	selectedStudentData: AssignedActivitiesStudentDataUser
    selectedActivities: SelectedActivities
    activityFilter: ActivityFilters
};

export type AssignedActivitiesAssignmentToggle = AssignmentResultType | NewCoursesAssignmentResultType;
