import {
    TABLET_LANDSCAPE_WIDTH,
    TABLET_PORTRAIT_WIDTH
} from '@3plearning/chakra-teacher-components';

export enum ScreenSizes {
    desktop = 'desktop',
    landscape = 'landscape',
    portrait = 'portrait'
}

export const VerticalSpacing = {
    base: 4,
    tablet: 6
};

export const PagePadding = {
    left: '32px',
    right: '10'
};

export const PagePaddingPx = {
    left: '32px',
    right: '40px'
};

export const PortalSidebarWidth = 70;

const PortalSideBarWidthPx = `${PortalSidebarWidth}px`;

export const getWindowWidth = () => {
    const width = window.innerWidth;

    if (window._env_.ENVIRONMENT === 'local') {
        // Page is not rendered inside an iframe
        return width;
    } else {
        // Page is rendered inside an iframe
        const iframeWidth = width + PortalSidebarWidth;

        return iframeWidth;
    }
};

export const PortalHeaderHeight = '75px';

export const PageMinWidthValue = 1024;

export const PageMinWidth = `calc(${PageMinWidthValue}px - ${PortalSideBarWidthPx})`;

export const AppFooterHeightValue = 56;

export const AppFooterHeight = `${AppFooterHeightValue}px`;

/**
 * Intentionally left these function parameters to take any value as I'm still not sure the extent of use
 * for this function yet. If it continues to be used in one place will eventually move it there.
 */
export const getMediaQuery = ({
    desktop,
    landscape,
    portrait
}: {
    desktop: unknown
    landscape: unknown
    portrait: unknown
}) => {
    const screenWidth = getWindowWidth();

    if (screenWidth <= TABLET_LANDSCAPE_WIDTH && screenWidth > TABLET_PORTRAIT_WIDTH) {
        return landscape;
    } else if (screenWidth <= TABLET_PORTRAIT_WIDTH) {
        return portrait;
    } else {
        return desktop;
    }
};
