import {
    ChallengesDokFilterCourse
} from './components/challenges-course-filters/components/dok-filter/dok-filter.types';
import {
    ChallengesFilterLevel
} from './components/challenges-course-filters/components/level-filter/level-filter.types';
import { CourseData } from '@containers/activity-router/data-grid-container/courses/types/courses.types';
import { RouteHelpersModalConstants } from '@containers/activity-router/routes/helpers/modal/route-modal-helpers.types';
import { DefaultState } from '@containers/activity-router/routes/helpers/slice/slice.types';
import {
    TransformedChallengesAssignments
} from '@services/gateways/combined-assign-gateway/combined-assign-gateway.types';

export enum ChallengesConstants {
    name = 'challenges'
}

export type ChallengesState = DefaultState & {
    course: {
        selected: DefaultState['course']['selected']
        filtered: CourseData
    }
    filters: {
        level: ChallengesFilterLevel
        dok: ChallengesDokFilterCourse
        isExpanded: boolean
    }
    assignments: {
        grid: TransformedChallengesAssignments
    }
    modal: RouteHelpersModalConstants | null
};
