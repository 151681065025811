import { ComponentStyleConfig } from '@chakra-ui/react';

import { GridCheckbox } from './grid-checkbox/grid-checkbox';
import { TopicFilterCheckbox } from './topic-filter-checkbox/topic-filter-checkbox';

export const Checkbox: ComponentStyleConfig = {
    variants: {
        ...GridCheckbox,
        ...TopicFilterCheckbox
    },
    defaultProps: {
        size: 'xl'
    }
};
