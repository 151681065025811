import {
    chakra,
    Flex
} from '@chakra-ui/react';

export const NAVIGATION_CONTAINER_WIDTH = {
    base: 640,
    tablet: 739
};

export const NAVIGATION_GROUP_BUTTON_HEIGHT = {
    base: '40px',
    tablet: '48px'
};

export const container = chakra(Flex, {
    label: 'activity-group-tabs-container',
    baseStyle: {
        position: 'relative',
        alignItems: 'center',
        padding: '1.5',
        width: NAVIGATION_CONTAINER_WIDTH,
        height: 'auto',
        borderRadius: '100px',
        background: 'rgba(255, 255, 255, 0.36)'
    }
});

export const groupButton = chakra(Flex, {
    label: 'activity-group-tabs-group-button',
    baseStyle: {
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        height: NAVIGATION_GROUP_BUTTON_HEIGHT,
        color: 'neutral.700',
        fontSize: {
            base: 'md',
            tablet: 'lg'
        },
        fontWeight: 400,
        cursor: 'pointer',
        _hover: {
            fontWeight: 500
        }
    }
});

export const selectedButton = chakra(groupButton, {
    label: 'activity-group-tabs-selected-button',
    baseStyle: {
        position: 'absolute',
        left: 0,
        color: 'primary.600',
        backgroundColor: 'white.0',
        fontWeight: 500,
        borderRadius: '83px',
        boxShadow: '0px 1px 3px rgba(12, 23, 48, 0.16), 0px 1px 2px rgba(12, 23, 48, 0.06)',
        transition: 'left 0.25s ease-in'
    }
});
