import { useState } from 'react';

import {
    selectClassGroupFilterClassroomSelectedId,
    selectClassGroupFilterGroupSelectedId,
    updateClassGroupRestrictedDictionary
} from '../../class-group-filters-slice';
import {
    useAppDispatch,
    useAppSelector
} from '@config/react-redux/hooks';
import { AppLandingModalTypes } from '@containers/app/app-landing/app-landing.types';
import {
    useAppErrorHandling,
    UseAppErrorHandlingProps
} from '@containers/app/app-landing/hooks/error/use-app-error-handling';
import { useFetchData } from '@services/fetch/hooks/use-fetch-data';
import { useCombinedAssignGateway } from '@services/gateways/combined-assign-gateway/hooks/use-combined-assign-gateway';

type ClassGroupRestrictedProps = {
    isRestrictedToAssignmentsOnly: boolean
};

const { restrict } = AppLandingModalTypes;
const appErrorHandlerInitValue = {
    error: null,
    callback: undefined,
    type: restrict
};

export const useRestrictService = () => {
    const [appErrorHandler, setAppErrorHandler] = useState<UseAppErrorHandlingProps>(appErrorHandlerInitValue);
    const dispatch = useAppDispatch();
    const { getCombinedAssignGatewayDynamicUrl } = useCombinedAssignGateway();
    const { put } = useFetchData();
    const classroomId = useAppSelector(selectClassGroupFilterClassroomSelectedId);
    const groupId = useAppSelector(selectClassGroupFilterGroupSelectedId);
    const putClassGroupRestricted = async ({ isRestrictedToAssignmentsOnly }: ClassGroupRestrictedProps) => {
        const updatedIsRestrictedToAssignmentsOnly = !isRestrictedToAssignmentsOnly;
        const url = getCombinedAssignGatewayDynamicUrl(
            { key: 'putClassGroupRestricted' },
            {
                classroomId,
                groupId,
                isRestricted: updatedIsRestrictedToAssignmentsOnly
            });

        dispatch(updateClassGroupRestrictedDictionary({
            isRestrictedToAssignmentsOnly: updatedIsRestrictedToAssignmentsOnly
        }));

        await put(url).then(() => {
            setAppErrorHandler(appErrorHandlerInitValue);
        }).catch((e: unknown) => {
            dispatch(updateClassGroupRestrictedDictionary({ isRestrictedToAssignmentsOnly }));
            setAppErrorHandler({
                ...appErrorHandler,
                error: e,
                callback: () => putClassGroupRestricted({ isRestrictedToAssignmentsOnly })
            });
        });
    };

    useAppErrorHandling(appErrorHandler);

    return { putClassGroupRestricted };
};
