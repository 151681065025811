/* eslint-disable max-len */
import {
    CombinedAssignGatewayRestrictedParams
} from './combined-assign-gateway.types';
import {
    CombinedAssignGatewayNewCoursesAssignCourseParams,
    CombinedAssignGatewayNewCoursesExportParams,
    CombinedAssignGatewayAssignmentsDefaultParams
} from '@services/gateways/combined-assign-gateway/api/combined-assign-gateway-api.types';

export const CombinedAssignGateway = {
    postAssignActivities: '/assignments/assign',
    putModifyActivities: '/assignments/assign',
    deleteUnassignActivities: '/assignments/unassign'
};

export const CombinedAssignDynamicGateway = {
    putClassGroupRestricted: ({
        classroomId,
        groupId,
        isRestricted
    }: CombinedAssignGatewayRestrictedParams) => `/classrooms/${classroomId}/groups/${groupId}/restrict/${isRestricted}`,
    getNewCoursesAssignments: ({
        classroomId,
        productId,
        groupId,
        assignmentResultType,
        usePreviousResultWhenThereIsNoCurrent
    }: CombinedAssignGatewayAssignmentsDefaultParams) =>
        // eslint-disable-next-line max-len
        `/assign-review-conquest/products/${productId}/classes/${classroomId}/groups/${groupId}/assignments?assignmentResultType=${assignmentResultType}&usePreviousResultWhenThereIsNoCurrent=${usePreviousResultWhenThereIsNoCurrent}`,
    exportNewCourses: ({
        productId,
        classroomId,
        groupId,
        assignmentResultType,
        usePreviousResultWhenThereIsNoCurrent,
        studentsSortOrder,
        authToken,
        locale
    }: CombinedAssignGatewayNewCoursesExportParams) =>
        // eslint-disable-next-line max-len
        `/assign-review-conquest/products/${productId}/classes/${classroomId}/groups/${groupId}/assignments/export?assignmentResultType=${assignmentResultType}&usePreviousResultWhenThereIsNoCurrent=${usePreviousResultWhenThereIsNoCurrent}&studentsSortOrder=${studentsSortOrder}&authToken=${authToken}&locale=${locale}`,
    putAssignCourseClassGroup: ({
        courseType,
        courseId,
        classroomId,
        groupId,
        authToken,
        locale
    }: CombinedAssignGatewayNewCoursesAssignCourseParams) =>
        `/course-types/${courseType}/classrooms/${classroomId}/groups/${groupId}/course?courseId=${courseId}&authToken=${authToken}&locale=${locale}`,
    getChallengesAssignmentData: ({
        classroomId,
        productId,
        groupId,
        assignmentResultType,
        usePreviousResultWhenThereIsNoCurrent
    }: CombinedAssignGatewayAssignmentsDefaultParams) =>
        `/assign-review-psr/products/${productId}/classes/${classroomId}/groups/${groupId}/assignments?assignmentResultType=${assignmentResultType}&usePreviousResultWhenThereIsNoCurrent=${usePreviousResultWhenThereIsNoCurrent}`
};
