import { i18n } from '@lingui/core';

export const getTranslations = () => ({
    HEADING_TEXT: i18n._('noCoursesAssigned.heading'),
    TEXT_ONE: i18n._('noCoursesAssigned.text.one'),
    TEXT_TWO: i18n._('noCoursesAssigned.text.two'),
    TEXT_THREE: i18n._('noCoursesAssigned.text.three'),
    TEXT_FOUR: i18n._('noCoursesAssigned.text.four'),
    TEXT_FIVE: i18n._('noCoursesAssigned.text.five'),
    TEXT_SIX: i18n._('noCoursesAssigned.text.six'),
    TEXT_SEVEN: i18n._('noCoursesAssigned.text.seven'),
    TEXT_EIGHT: i18n._('noCoursesAssigned.text.eight'),
    BUTTON_TEXT: i18n._('noCoursesAssigned.button'),
    ASSIGN: i18n._('noCoursesAssigned.assign'),
    OPTION_DISABLED: i18n._('noCoursesAssigned.optionDisabled'),
    COURSE: i18n._('noCoursesAssigned.course'),
    YEAR_LEVEL: i18n._('noCoursesAssigned.yearLevel'),
    SELECT_HEADING: i18n._('noCoursesAssigned.selectHeading'),
    DISPLAY_HEADING: i18n._('emptyState.noCourseAssigned.heading.newCourses'),
    DISPLAY_BODY: i18n._('emptyState.noCourseAssigned.body.newCourses'),
    TOAST_SUCCESS: i18n._('emptyState.noCourseAssigned.toast.success.newCourses'),
    TOAST_ERROR_FRONT: i18n._('emptyState.noCourseAssigned.toast.error.front.newCourses'),
    TOAST_ERROR_BACK: i18n._('emptyState.noCourseAssigned.toast.error.back.newCourses')
});
