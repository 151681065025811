import React, {
    createContext,
    PropsWithChildren,
    useContext,
    useMemo
} from 'react';

import type { EnvConfig } from './env-config-provider.types';

export const EnvConfigContext = createContext<EnvConfig>({} as EnvConfig);

export type EnvConfigProviderProps = PropsWithChildren<{
    config?: EnvConfig
}>;

/**
 * A Basic React provider to consume the env config values using a standard React dependency injection pattern.
 * This allows for easier mocking in tests and storybook etc.
 *
 * These values are exposed through the CMD `env.sh` script to create 'env-config.js'.
 * The values are then available to the runtime on the window object `window._env_`.
 */
export function EnvConfigProvider ({
    children,
    config = window._env_ || {}
}: EnvConfigProviderProps) {
    /**
     * The bash env.sh script does not handle booleans, hence the 'true',
     * this sets the correct type Javascript type.
     */
    const contextValue = useMemo(() => {
        return Object.fromEntries(Object.entries(config).map(
            ([key, value]) => {
                const flagInValueUrl = getFlagInSearchParams(
                    window.location.href,
                    key
                );

                if (typeof flagInValueUrl === 'boolean') {
                    return [key, flagInValueUrl];
                }

                const configuredValue = (value === 'true' || value === 'false') ?
                    value == 'true' : value;

                return [key, configuredValue];
            })
        ) as EnvConfig;
    }, [config]);

    return (
        <EnvConfigContext.Provider value={contextValue}>
            {children}
        </EnvConfigContext.Provider>
    );
}

/**
 * Check if a flag value is true in search params, eg ?MOCK_CONTENTFUL_ENABLED=true.
 *
 * @return null for undefined, otherwise boolean for flag state.
 */
function getFlagInSearchParams (
    url: string,
    flagName: string
): boolean | null {
    const flagValue = new URL(url).searchParams.get(flagName);

    if (flagValue === 'true') return true;
    if (flagValue === 'false') return false;

    return null;
}

export function useEnvConfig () {
    return useContext(EnvConfigContext);
}
