import { Box, chakra, Flex } from '@chakra-ui/react';

import { PagePadding } from '../../../style/constants';

export const container = chakra(Box, {
    label: 'loading-route-container',
    baseStyle: {
        backgroundColor: 'white.0',
        marginTop: '0 !important',
        overflow: 'hidden',
        position: 'relative',
        w: '100%',
        minHeight: '100vh'
    }
});

export const grid = chakra(Flex, {
    label: 'loading-route-grid',
    baseStyle: {
        w: '100%',
        paddingLeft: PagePadding.left
    }
});
