import {
    AssignmentActions,
    DefaultState,
    GridCount
} from '@containers/activity-router/routes/helpers/slice/slice.types';

const {
    studentCount,
    topicCount,
    topicActivityCount,
    activityCount
} = GridCount;
const {
    assign,
    reassign,
    unassign,
    modify
} = AssignmentActions;

export const defaultState: DefaultState = {
    course: {
        selected: null
    },
    grid: {
        cells: {},
        counters: {
            [studentCount]: {},
            [topicCount]: {},
            [activityCount]: {},
            [topicActivityCount]: {}
        }
    },
    assignments: {
        selected: {
            [assign]: {},
            [reassign]: {},
            [unassign]: {},
            [modify]: {}
        }
    }
};
