import { Box, chakra, Flex } from '@chakra-ui/react';

import { AppFooterHeight, PagePadding } from '../../../../style/constants';
import { footerButtonDefault } from '@config/chakra-ui/theme/components/button/footer-button/footer-button';

export const container = chakra(Box, {
    label: 'loading-route-container',
    baseStyle: {
        position: 'fixed',
        backgroundColor: 'white.0',
        bottom: 0,
        h: AppFooterHeight,
        w: '100%'
    }
});

export const wrapper = chakra(Flex, {
    label: 'loading-route-wrapper',
    baseStyle: {
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingRight: PagePadding.right,
        w: '100%',
        h: '100%'
    }
});

export const button = {
    borderRadius: '40px',
    h: footerButtonDefault.h,
    minW: footerButtonDefault.minW
};
