import { useEffect } from 'react';

import { useEnvConfig } from '../../env/env-config-provider';

export const useHotjar = () => {
    const {
        HOTJAR_ID,
        HOTJAR_SV,
        ENVIRONMENT
    } = useEnvConfig();

    function manageHotjar () {
        const script = document.createElement('script');

        script.text = `
            (function(h, o, t, j, a, r) {
                h.hj = h.hj || function() {
                    (h.hj.q = h.hj.q || []).push(arguments)
                };
                h._hjSettings = {
                    hjid: ${HOTJAR_ID},
                    hjsv: ${HOTJAR_SV}
                };
                a = o.getElementsByTagName('head')[0];
                r = o.createElement('script');
                r.async = 1;
                r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
                a.appendChild(r);
            })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
        `;

        document.body.prepend(script);
    }

    useEffect(() => {
        if (ENVIRONMENT !== 'local') {
            manageHotjar();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
