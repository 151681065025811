export const scrollbar = {
    '&::-webkit-scrollbar': {
        width: '16px',
        backgroundColor: 'white.0'
    },
    '&::-webkit-scrollbar-track': {
        backgroundColor: 'white.0'
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#babac0',
        borderRadius: '16px',
        border: '4px solid #fff'
    },
    '&::-webkit-scrollbar-button': {
        display: 'none'
    }
};
