import { CourseData } from '../../../data-grid-container/courses/types/courses.types';
import {
    GridCells,
    GridCounters
} from '@containers/activity-router/routes/helpers/slice/topic/topic-slice.types';

export enum SkillQuestsConstants {
    name = 'skillQuests'
}

export type SkillQuestsState = {
    course: {
        selected: CourseData
    }
    grid: {
        cells: GridCells
        counters: GridCounters
    }
};
