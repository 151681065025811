import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import LockClosedSmall from '@components/icon/access-toggle/lock-closed-small.svg';
import LockClosed from '@components/icon/access-toggle/lock-closed.svg';
import LockOpenSmall from '@components/icon/access-toggle/lock-open-small.svg';
import LockOpen from '@components/icon/access-toggle/lock-open.svg';
import { AccessToggleVariant } from '@containers/class-group-filters/access-toggle/access-toggle';

// eslint-disable-next-line jest/unbound-method
const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(switchAnatomy.keys);
const accessTogglePartsStyle = definePartsStyle({
    thumb: {
        bg: 'white.0',
        backgroundImage: {
            base: LockOpenSmall,
            tablet: LockOpen
        },
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        transitionDuration: '.5s',
        m: '0 2px',
        h: {
            base: '18px',
            tablet: '26px'
        },
        w: {
            base: '18px',
            tablet: '26px'
        },
        _checked: {
            backgroundImage: {
                base: LockClosedSmall,
                tablet: LockClosed
            },
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            transform: {
                base: 'translateX(40px)',
                tablet: 'translateX(58px)'
            }
        }
    },
    track: {
        bg: 'success.400',
        alignItems: 'center',
        w: {
            base: '60px',
            tablet: '88px'
        },
        h: {
            base: '22px',
            tablet: '32px'
        },
        border: '1.45px solid',
        borderColor: 'neutral.300',
        _hover: {
            bg: 'success.500'
        },
        _checked: {
            bg: 'secondary.500',
            borderColor: 'secondary.500',
            _hover: {
                bg: 'secondary.600'
            }
        }
    }
});
const accessToggle = defineMultiStyleConfig({ baseStyle: accessTogglePartsStyle });

export const AccessToggleVariants = {
    [AccessToggleVariant]: {
        ...accessToggle.baseStyle
    }
};
