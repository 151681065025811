import {
    TopicButtonVariants
} from '@containers/activity-router/data-grid-container/courses/course-buttons/topic-button/topic-button.types';
import {
    CellDimensions,
    CourseContainerWidth
} from '@containers/activity-router/data-grid-container/data-grid-container.styled';

const topicButtonDefault = {
    justifyContent: 'space-between',
    paddingLeft: '16px',
    lineHeight: '21px',
    whiteSpace: 'normal',
    fontSize: {
        base: 'sm',
        tablet: 'md'
    },
    color: 'neutral.900',
    fontWeight: 500,
    boxShadow: '0px 1px 3px rgba(12, 23, 48, 0.16), 0px 1px 2px rgba(12, 23, 48, 0.06)'
};

export const TopicButtons = {
    [TopicButtonVariants.gridTopicButton]: {
        ...topicButtonDefault,
        ...CourseContainerWidth,
        h: CellDimensions.h
    },
    [TopicButtonVariants.assignModalTopicButton]: {
        ...topicButtonDefault,
        pr: '0',
        borderRadius: 0,
        h: '56px',
        w: '100%',
        justifyContent: 'space-between',
        fontWeight: 400,
        fontSize: 'xl'
    }
};
