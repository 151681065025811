import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
    GridCells,
    GridCounters,
    ToggleActivityGridCheckbox,
    ToggleGridCell,
    ToggleStudentGridCheckbox,
    ToggleTopicGridCheckbox
} from '../../helpers/slice/topic/topic-slice.types';
import {
    ActivitiesConstants,
    ActivitiesState,
    ActivitiesTopics,
    UpdateActivitiesAssignments
} from './activities.types';
import {
    manageActivitiesCellAssignmentsSelected,
    manageActivitiesSelectedAssignments,
    updateActivitiesAssignments
} from './helpers/slice/assignments/selected/assignments-selected-helpers';
import { RootState } from '@config/react-redux/store';
import { SetCourseExpandCollapse } from '@containers/activity-router/data-grid-container/courses/types/courses.types';
import {
    manageCourseTopicExpandCollapse,
    manageCourseTopicExpandCollapseCheck,
    manageCourseTopicIsExpanded
} from '@containers/activity-router/routes/helpers/courses/course-expand-helpers';
import { resetGridCells } from '@containers/activity-router/routes/helpers/grid/cells/grid-cells-helpers';
import {
    resetGridCellCounters
} from '@containers/activity-router/routes/helpers/grid/counters/grid-cell-counters/grid-cell-counters';
import {
    RouteHelpersModalConstants
} from '@containers/activity-router/routes/helpers/modal/route-modal-helpers.types';
import { toggleGridCells } from '@containers/activity-router/routes/helpers/slice/grid/slice-grid-helpers';
import { defaultState } from '@containers/activity-router/routes/helpers/slice/slice-defaults';
import {
    toggleRouteActivityGridCheckbox,
    toggleRouteStudentGridCheckbox,
    toggleRouteTopicGridCheckbox
} from '@containers/activity-router/routes/helpers/slice/slice-helpers';
import {
    manageActivitiesMultiSelect
} from '@containers/activity-router/routes/learn/activities/components/activities-multi-select/activities-multi-select-helpers';
import {
    manageActivitiesGridCellFiltersComplexityCounters,
    manageActivitiesGridCheckboxFiltersComplexityCounters
} from '@containers/activity-router/routes/learn/activities/helpers/slice/filters/complexity/filters-complexity-counters-helpers';
import {
    TransformedClassroomReportGatewayAssignments
} from '@services/gateways/classroom-report-gateway/classroom-report-gateway.types';
import {
    ActivitiesComplexity,
    TransformCourse
} from '@services/gateways/combined-assign-gateway/combined-assign-gateway.types';

const {
    all,
    core,
    easier
} = ActivitiesComplexity;
const { name } = ActivitiesConstants;
const initialState: ActivitiesState = {
    ...defaultState,
    filters: {
        complexity: {
            selected: all,
            counters: {
                [all]: {},
                [core]: {},
                [easier]: {}
            }
        },
        topics: [],
        isExpanded: true
    },
    assignments: {
        ...defaultState.assignments,
        grid: {
            totalAssignments: {},
            studentAssignments: {}
        }
    },
    modal: null
};

export const activitiesSlice = createSlice({
    name,
    initialState,
    reducers: {
        setActivitiesCourseSelected (state, action: PayloadAction<TransformCourse>) {
            state.course.selected = action.payload;
            state.filters.isExpanded = true;
        },
        resetActivitiesCourseSelected (state) {
            state.course.selected = null;
            state.filters.isExpanded = true;
        },
        setActivitiesCourseIsExpanded (state, action: PayloadAction<SetCourseExpandCollapse>) {
            if (state.course.selected) {
                state.course.selected.topics = manageCourseTopicIsExpanded({
                    course: state.course.selected,
                    payload: action.payload
                });
                if (manageCourseTopicExpandCollapseCheck({
                    course: state.course.selected,
                    isExpanded: state.filters.isExpanded
                })) state.filters.isExpanded = !state.filters.isExpanded;
            }
        },
        setActivitiesFiltersComplexitySelected (state, action: PayloadAction<ActivitiesComplexity>) {
            state.filters.complexity.selected = action.payload;
        },
        setActivitiesFiltersTopics (state, action: PayloadAction<ActivitiesTopics>) {
            state.filters.topics = action.payload;
        },
        setActivitiesGridCells (state, action: PayloadAction<GridCells>) {
            state.grid.cells = action.payload;
        },
        toggleActivitiesGridCell (state, action: PayloadAction<ToggleGridCell>) {
            const {
                student,
                topicId,
                activityId,
                isSelected
            } = action.payload;
            const userId = student.userProfileId.toString();
            const prevGridCells = JSON.parse(JSON.stringify(state.grid.cells));
            const {
                gridCells,
                gridCounters
            } = toggleGridCells({
                gridCells: state.grid.cells,
                gridCounters: state.grid.counters,
                studentId: student.userProfileId.toString(),
                topicId,
                activityId,
                isSelected
            });

            state.grid.cells = gridCells;
            state.grid.counters = gridCounters;
            state.assignments.selected = manageActivitiesCellAssignmentsSelected({
                ...action.payload,
                studentAssignments: state.assignments.grid.studentAssignments,
                gridCells: state.grid.cells,
                selectedAssignments: state.assignments.selected
            });
            state.filters.complexity.counters = manageActivitiesGridCellFiltersComplexityCounters({
                course: state.course.selected,
                topicId,
                activityId: activityId || '',
                complexityCounters: state.filters.complexity.counters,
                studentId: userId,
                gridCells: state.grid.cells,
                prevGridCells
            });
        },
        toggleActivitiesStudentGridCheckbox (state, action: PayloadAction<ToggleStudentGridCheckbox>) {
            const prevGridCells = JSON.parse(JSON.stringify(state.grid.cells));
            const {
                gridCells,
                gridCounters,
                assignmentsSelected
            } = toggleRouteStudentGridCheckbox({
                payload: action.payload,
                state: {
                    gridCells: state.grid.cells,
                    gridCounters: state.grid.counters,
                    assignmentsSelected: state.assignments.selected
                },
                manageSelectedAssignments: (params) => manageActivitiesSelectedAssignments({
                    ...params,
                    studentAssignments: state.assignments.grid.studentAssignments
                })
            });

            state.grid.cells = gridCells;
            state.grid.counters = gridCounters;
            state.assignments.selected = assignmentsSelected;

            state.filters.complexity.counters = manageActivitiesGridCheckboxFiltersComplexityCounters({
                gridCells: state.grid.cells,
                updatedStudentId: action.payload.student.userProfileId.toString(),
                course: state.course.selected,
                complexityCounters: state.filters.complexity.counters,
                prevGridCells
            });
        },
        toggleActivitiesTopicGridCheckbox (state, action: PayloadAction<ToggleTopicGridCheckbox>) {
            const prevGridCells = JSON.parse(JSON.stringify(state.grid.cells));
            const {
                gridCells,
                gridCounters,
                assignmentsSelected
            } = toggleRouteTopicGridCheckbox({
                payload: action.payload,
                state: {
                    gridCells: state.grid.cells,
                    gridCounters: state.grid.counters,
                    assignmentsSelected: state.assignments.selected
                },
                manageSelectedAssignments: (params) => manageActivitiesSelectedAssignments({
                    ...params,
                    studentAssignments: state.assignments.grid.studentAssignments
                })
            });

            state.grid.cells = gridCells;
            state.grid.counters = gridCounters;
            state.assignments.selected = assignmentsSelected;
            state.filters.complexity.counters = manageActivitiesGridCheckboxFiltersComplexityCounters({
                gridCells: state.grid.cells,
                course: state.course.selected,
                updatedTopicId: action.payload.topicId,
                complexityCounters: state.filters.complexity.counters,
                prevGridCells
            });
        },
        toggleActivitiesActivityGridCheckbox (state, action: PayloadAction<ToggleActivityGridCheckbox>) {
            const prevGridCells = JSON.parse(JSON.stringify(state.grid.cells));
            const {
                gridCells,
                gridCounters,
                assignmentsSelected
            } = toggleRouteActivityGridCheckbox({
                payload: action.payload,
                state: {
                    gridCells: state.grid.cells,
                    gridCounters: state.grid.counters,
                    assignmentsSelected: state.assignments.selected
                },
                manageSelectedAssignments: (params) => manageActivitiesSelectedAssignments({
                    ...params,
                    studentAssignments: state.assignments.grid.studentAssignments
                })
            });

            state.grid.cells = gridCells;
            state.grid.counters = gridCounters;
            state.assignments.selected = assignmentsSelected;
            state.filters.complexity.counters = manageActivitiesGridCheckboxFiltersComplexityCounters({
                gridCells: state.grid.cells,
                course: state.course.selected,
                updatedTopicId: action.payload.topicId,
                updatedActivityId: action.payload.activityId,
                complexityCounters: state.filters.complexity.counters,
                prevGridCells
            });
        },
        setActivitiesMultiSelect (state, action: any) {
            const {
                gridCells,
                gridCounters,
                complexityCounters
            } = manageActivitiesMultiSelect({
                option: action.payload.option,
                gridCells: state.grid.cells,
                gridCounters: state.grid.counters,
                studentAssignments: state.assignments.grid.studentAssignments,
                selectedAssignments: state.assignments.selected,
                filteredTopics: state.filters.topics,
                complexityCounters: state.filters.complexity.counters,
                course: state.course.selected
            });

            state.grid.cells = gridCells;
            state.grid.counters = gridCounters;
            state.filters.complexity.counters = complexityCounters;
        },
        setActivitiesGridCountersInitialValue (state, action: PayloadAction<GridCounters>) {
            state.grid.counters = action.payload;
        },
        resetActivitiesSelectedAssignments (state) {
            state.assignments.selected = defaultState.assignments.selected;
        },
        resetActivityGrid (state) {
            state.grid.cells = resetGridCells({
                gridCells: state.grid.cells,
                isSelected: false
            });
            state.grid.counters = resetGridCellCounters({
                counters: state.grid.counters
            });
        },
        resetActivitiesFiltersComplexityCounters (state) {
            state.filters.complexity.counters = {
                [all]: {},
                [core]: {},
                [easier]: {}
            };
        },
        setActivitiesModal (state, action: PayloadAction<RouteHelpersModalConstants | null>) {
            state.modal = action.payload;
        },
        updateActivitiesAssignment (state, action: PayloadAction<UpdateActivitiesAssignments>) {
            state.assignments.selected = updateActivitiesAssignments({
                updatedStudentAssignments: action.payload.updatedStudentAssignments,
                selectedAssignments: state.assignments.selected,
                gridCells: state.grid.cells
            });
        },
        setActivitiesAssignmentGrid (state, action: PayloadAction<TransformedClassroomReportGatewayAssignments>) {
            const { transformedTotalAssignments, transformedStudentAssignments } = action.payload;

            state.assignments.grid.totalAssignments = transformedTotalAssignments;
            state.assignments.grid.studentAssignments = transformedStudentAssignments;
        },
        updateActivitiesAssignmentGrid (state, action: PayloadAction<TransformedClassroomReportGatewayAssignments>) {
            const { transformedTotalAssignments, transformedStudentAssignments } = action.payload;

            Object.keys(transformedStudentAssignments).forEach((key) => {
                if (state.assignments.grid.studentAssignments[key]) {
                    state.assignments.grid.studentAssignments[key] = transformedStudentAssignments[key];
                }
            });
            Object.keys(transformedTotalAssignments).forEach((key) => {
                if (state.assignments.grid.totalAssignments[key]) {
                    state.assignments.grid.totalAssignments[key] = transformedTotalAssignments[key];
                }
            });
        },
        setActivitiesIsExpanded (state, action: PayloadAction<boolean>) {
            state.filters.isExpanded = action.payload;
            if (state.course.selected) {
                state.course.selected = manageCourseTopicExpandCollapse({
                    course: state.course.selected,
                    isExpanded: action.payload
                });
            }
        },
        resetActivitiesAssignments (state) {
            state.assignments = {
                grid: {
                    totalAssignments: {},
                    studentAssignments: {}
                },
                selected: defaultState.assignments.selected
            };
        }
    }
});

/**
 * ACTIONS
 */
export const {
    setActivitiesCourseSelected,
    setActivitiesCourseIsExpanded,
    setActivitiesFiltersComplexitySelected,
    setActivitiesFiltersTopics,
    setActivitiesGridCells,
    toggleActivitiesStudentGridCheckbox,
    toggleActivitiesTopicGridCheckbox,
    toggleActivitiesActivityGridCheckbox,
    toggleActivitiesGridCell,
    setActivitiesGridCountersInitialValue,
    resetActivitiesSelectedAssignments,
    resetActivitiesCourseSelected,
    setActivitiesModal,
    resetActivityGrid,
    resetActivitiesFiltersComplexityCounters,
    setActivitiesMultiSelect,
    updateActivitiesAssignment,
    setActivitiesAssignmentGrid,
    updateActivitiesAssignmentGrid,
    setActivitiesIsExpanded,
    resetActivitiesAssignments
} = activitiesSlice.actions;

/**
 * SELECTORS
 */
export const selectActivitiesCourseSelected = (state: RootState) => state[name].course.selected;

export const selectActivitiesFiltersComplexitySelected = (state: RootState) => state[name].filters.complexity.selected;

export const selectActivitiesFiltersTopics = (state: RootState) => state[name].filters.topics;

export const selectActivitiesGridCells = (state: RootState) => state[name].grid.cells;

export const selectActivitiesGridCounters = (state: RootState) => state[name].grid.counters;

export const selectActivitiesAssignments = (state: RootState) => state[name].assignments.selected;

export const selectActivitiesModal = (state: RootState) => state[name].modal;

export const selectActivitiesFilterComplexityCounters = (state: RootState) => state[name].filters.complexity.counters;

export const selectActivitiesAssignmentsGridStudentAssignments = (state: RootState) => state[name].assignments.grid.studentAssignments;

export const selectActivitiesAssignmentsGridTotalAssignments = (state: RootState) => state[name].assignments.grid.totalAssignments;

export const selectActivitiesAssignmentsGrid = (state: RootState) => state[name].assignments.grid;

export const selectActivitiesIsExpanded = (state: RootState) => state[name].filters.isExpanded;

export default activitiesSlice.reducer;
