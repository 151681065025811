import { SortStudentByKeyParams } from '../student-list.types';

export const sortStudentsByKey = ({
    students,
    key
}: SortStudentByKeyParams) => [...students].sort((a, b) => a[key] > b[key] ? 1 : -1);

export const getStudentListKey = (keyItems: Array<string | number>) => {
    return keyItems.slice().join('_');
};
