import {
    GridCount,
    StudentId
} from '../../../slice/slice.types';
import {
    StudentActivityMapHelper,
    TopicActivityMapHelper
} from '../counters.types';
import {
    UpdatedActivity,
    UpdatedGridActivityCounters,
    UpdatedStudent,
    UpdatedTopicActivity
} from './activity-grid-counters.types';

const {
    studentCount,
    topicCount,
    topicActivityCount,
    activityCount
} = GridCount;

function updatedActivity ({
    allStudentsArray,
    isChecked
}: UpdatedActivity) {
    return isChecked ? allStudentsArray : [];
}

function getTopicActivityCounts ({
    selectedActivityCount,
    studentKey,
    isChecked
}: Pick<UpdatedTopicActivity, 'selectedActivityCount' | 'isChecked'> & { studentKey: StudentId }) {
    const {
        selectedActivities,
        totalActivities
    } = selectedActivityCount;

    let topicCount = 0;
    let topicActivityCount = 0;

    if (isChecked) {
        topicActivityCount = selectedActivities.includes(studentKey) ? 0 : 1;
        topicCount = totalActivities - selectedActivities.length;
    } else {
        topicActivityCount = selectedActivities.includes(studentKey) ? -1 : 0;
        topicCount = -(selectedActivities.length);
    }

    return {
        topicActivityCount,
        topicCount
    };
}

function updatedTopicActivity ({
    updatedTopicActivityCount,
    selectedActivityCount,
    isChecked,
    topicId
}: UpdatedTopicActivity) {
    let studentActivityMap: StudentActivityMapHelper = {};
    let topicActivityMap: TopicActivityMapHelper = {};

    Object.keys(updatedTopicActivityCount).forEach((studentKey) => {
        const {
            topicActivityCount,
            topicCount
        } = getTopicActivityCounts({
            selectedActivityCount,
            studentKey,
            isChecked
        });

        updatedTopicActivityCount[studentKey][topicId].selectedActivities += topicActivityCount;
        studentActivityMap = {
            ...studentActivityMap,
            [studentKey]: topicActivityCount
        };
        topicActivityMap = {
            ...topicActivityMap,
            [topicId]: topicCount
        };
    });

    return {
        updatedTopicActivityCount,
        topicActivityMap,
        studentActivityMap
    };
}

function updatedStudents ({
    updatedStudentCount,
    studentActivityMap
}: UpdatedStudent) {
    Object.keys(updatedStudentCount).forEach((studentKey) => {
        const updatedValue = studentActivityMap[studentKey];

        updatedStudentCount[studentKey].selectedActivities += updatedValue;
    });

    return updatedStudentCount;
}

export function updateGridActivityCounters ({
    counters,
    activityId,
    topicId,
    isChecked
}: UpdatedGridActivityCounters) {
    if (activityId) {
        const {
            updatedTopicActivityCount,
            topicActivityMap,
            studentActivityMap
        } = updatedTopicActivity({
            updatedTopicActivityCount: counters[topicActivityCount],
            selectedActivityCount: counters[activityCount][topicId][activityId],
            isChecked,
            topicId
        });

        // Activity Counter
        counters[activityCount][topicId][activityId].selectedActivities = updatedActivity({
            allStudentsArray: Object.keys(counters[studentCount]).map((key) => key),
            isChecked
        });
        // Topic Activity Counter
        counters[topicActivityCount] = updatedTopicActivityCount;
        // Student Counter
        counters[studentCount] = updatedStudents({
            updatedStudentCount: counters[studentCount],
            studentActivityMap
        });
        // Topic Counter
        counters[topicCount][topicId].selectedActivities += topicActivityMap[topicId];
    }

    return counters;
}
