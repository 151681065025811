import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@config/react-redux/store';
import {
    AssessmentsConstants,
    AssessmentsState
} from '@containers/activity-router/routes/assess/assessments/assessments.types';
import { TransformCourse } from '@services/gateways/combined-assign-gateway/combined-assign-gateway.types';

const { name } = AssessmentsConstants;
const initialState: AssessmentsState = {
    course: {
        selected: null
    }
};

export const assessmentsSlice = createSlice({
    name,
    initialState,
    reducers: {
        setAssessmentsCourseSelected (state, action: PayloadAction<TransformCourse>) {
            state.course.selected = action.payload;
        }
    }
});

/**
 * ACTIONS
 */
export const {
    setAssessmentsCourseSelected
} = assessmentsSlice.actions;

/**
 * SELECTORS
 */
export const selectAssessmentsCourseSelected = (state: RootState) => state[name].course.selected;

export default assessmentsSlice.reducer;
