import {
    TopicFilterButtons
} from '@containers/activity-router/data-grid-container/courses/course-filters/topic-filter/topic-filter.types';

export const TopicFilterButtonVariants = {
    [TopicFilterButtons.topicFilterButton]: {
        w: '160px',
        borderRadius: '40px',
        p: '8px 32px 8px 32px',
        background: 'linear-gradient(103.65deg, #00ABE8 -2.03%, #007ACC 43.7%, #0063A7 105.46%)',
        color: 'white.0',
        lineHeight: '28px',
        _disabled: {
            background: 'neutral.100',
            color: 'neutral.600',
            opacity: 1,
            pointerEvents: 'none'
        },
        _hover: {
            background: 'linear-gradient(102.49deg, #007ACC 0%, #014B8B 100%)'
        },
        padding: '8px 32px'
    }
};
