import {
    getTopicGridCellsMap,
    manageGridCells
} from '@containers/activity-router/routes/helpers/grid/cells/grid-cells-helpers';
import {
    updateGridCellCounters
} from '@containers/activity-router/routes/helpers/grid/counters/grid-cell-counters/grid-cell-counters';
import {
    generateInitialGridCounters
} from '@containers/activity-router/routes/helpers/grid/counters/initial-grid-counters/initial-grid-counters';
import {
    GetUpdatedGridDataParams,
    ManageFilteredGridCountersParams,
    ToggleGridCellsProps
} from '@containers/activity-router/routes/helpers/slice/grid/slice-grid-helpers.types';
import { AssignmentActions } from '@containers/activity-router/routes/helpers/slice/slice.types';

const {
    assign,
    reassign,
    unassign,
    modify
} = AssignmentActions;

export const toggleGridCells = ({
    gridCells,
    gridCounters,
    studentId,
    topicId,
    activityId,
    isSelected
}: ToggleGridCellsProps) => {
    gridCells = manageGridCells({
        topicId,
        studentId,
        activityId,
        gridCells,
        isSelected
    });
    gridCounters = updateGridCellCounters({
        counters: gridCounters,
        isSelected,
        studentId,
        topicId,
        activityId
    });

    return {
        gridCells,
        gridCounters
    };
};

const getInitialGridValues = ({
    studentListData,
    updatedFilteredCourse
}: Pick<GetUpdatedGridDataParams, 'studentListData' | 'updatedFilteredCourse'>) => {
    const updatedGridCounters = generateInitialGridCounters({
        selectedGroupStudents: studentListData?.selectedGroupStudents || [],
        topics: updatedFilteredCourse?.topics || []
    });
    const updatedGridCells = getTopicGridCellsMap({
        selectedGroupStudents: studentListData?.selectedGroupStudents || [],
        topics: updatedFilteredCourse?.topics || []
    });

    return {
        updatedGridCounters,
        updatedGridCells
    };
};
const manageFilteredGridCounters = ({
    isSelected,
    updatedGridCounters,
    studentKey,
    topicKey,
    activityKey
}: ManageFilteredGridCountersParams) => {
    if (isSelected) {
        updatedGridCounters = updateGridCellCounters({
            counters: updatedGridCounters,
            isSelected,
            studentId: studentKey,
            topicId: topicKey,
            activityId: activityKey
        });
    }

    return updatedGridCounters;
};

export const getUpdatedFilteredGridData = ({
    prevGridCells,
    studentListData,
    updatedFilteredCourse,
    manageSelectedAssignmentsCallback,
    studentAssignments
}: GetUpdatedGridDataParams) => {
    const {
        updatedGridCells,
        updatedGridCounters: initialGridCounters
    } = getInitialGridValues({
        studentListData,
        updatedFilteredCourse
    });

    let updatedSelectedAssignments = {
        [assign]: {},
        [reassign]: {},
        [unassign]: {},
        [modify]: {}
    };
    let updatedGridCounters = initialGridCounters;

    Object.keys(updatedGridCells).forEach((studentKey) => {
        Object.keys(updatedGridCells[studentKey]).forEach((topicKey) => {
            Object.keys(updatedGridCells[studentKey][topicKey]).forEach((activityKey) => {
                if (prevGridCells?.[studentKey]?.[topicKey]?.[activityKey]) {
                    const updatedIsSelected = prevGridCells[studentKey][topicKey][activityKey].isSelected;

                    // Manages the Grid Cells
                    updatedGridCells[studentKey][topicKey][activityKey].isSelected = updatedIsSelected;
                    // Manages the Grid Counters
                    updatedGridCounters = manageFilteredGridCounters({
                        isSelected: updatedIsSelected,
                        updatedGridCounters,
                        studentKey,
                        topicKey,
                        activityKey
                    });
                    // Manages Selected Assignments
                    updatedSelectedAssignments = manageSelectedAssignmentsCallback({
                        selectedAssignments: updatedSelectedAssignments,
                        studentKey,
                        studentAssignments,
                        isSelected: updatedGridCells[studentKey][topicKey][activityKey].isSelected,
                        activityKey
                    });
                }
            });
        });
    });

    return {
        updatedGridCells,
        updatedGridCounters,
        updatedSelectedAssignments
    };
};
