import {
    manageRouteCheckboxAssignments
} from '@containers/activity-router/routes/helpers/assignment/assignment-selected/route-assignments-selected';
import {
    toggleActivityGridCheckbox,
    toggleStudentGridCheckbox, toggleTopicGridCheckbox
} from '@containers/activity-router/routes/helpers/slice/checkbox/slice-grid-checkbox-helpers';
import {
    ToggleRouteActivityGridCheckboxParams,
    ToggleRouteStudentGridCheckboxParams,
    ToggleRouteTopicGridCheckboxParams
} from '@containers/activity-router/routes/helpers/slice/slice.types';

export const toggleRouteStudentGridCheckbox = ({
    payload,
    state,
    manageSelectedAssignments
}: ToggleRouteStudentGridCheckboxParams) => {
    const {
        student,
        isChecked
    } = payload;
    const {
        gridCells,
        gridCounters
    } = toggleStudentGridCheckbox({
        gridCells: state.gridCells,
        gridCounters: state.gridCounters,
        isChecked,
        studentId: student.userProfileId.toString()
    });

    state.assignmentsSelected = manageRouteCheckboxAssignments({
        selectedAssignments: state.assignmentsSelected,
        gridCells: state.gridCells,
        isChecked,
        checkAssignmentEligibility: ({ studentKey }) => studentKey === student.userProfileId.toString(),
        manageSelectedAssignments
    });

    return {
        gridCells,
        gridCounters,
        assignmentsSelected: state.assignmentsSelected
    };
};

export const toggleRouteTopicGridCheckbox = ({
    payload,
    state,
    manageSelectedAssignments
}: ToggleRouteTopicGridCheckboxParams) => {
    const {
        topicId,
        isChecked
    } = payload;
    const {
        gridCells,
        gridCounters
    } = toggleTopicGridCheckbox({
        gridCells: state.gridCells,
        gridCounters: state.gridCounters,
        ...payload
    });

    state.assignmentsSelected = manageRouteCheckboxAssignments({
        selectedAssignments: state.assignmentsSelected,
        gridCells: state.gridCells,
        isChecked,
        checkAssignmentEligibility: ({ topicKey }) => topicKey === topicId,
        manageSelectedAssignments
    });

    return {
        gridCells,
        gridCounters,
        assignmentsSelected: state.assignmentsSelected
    };
};

export const toggleRouteActivityGridCheckbox = ({
    payload,
    state,
    manageSelectedAssignments
}: ToggleRouteActivityGridCheckboxParams) => {
    const {
        activityId,
        isChecked
    } = payload;
    const {
        gridCells,
        gridCounters
    } = toggleActivityGridCheckbox({
        gridCells: state.gridCells,
        gridCounters: state.gridCounters,
        ...payload
    });

    state.assignmentsSelected = manageRouteCheckboxAssignments({
        selectedAssignments: state.assignmentsSelected,
        gridCells: state.gridCells,
        isChecked,
        checkAssignmentEligibility: ({ activityKey }) => activityKey === activityId,
        manageSelectedAssignments
    });

    return {
        gridCells,
        gridCounters,
        assignmentsSelected: state.assignmentsSelected
    };
};

