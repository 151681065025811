import { selectAuthStateToken } from '../../../config/auth/auth-slice';
import { useAppSelector } from '../../../config/react-redux/hooks';

const defaultHeaders = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
};

export const useFetchData = () => {
    const authToken = useAppSelector(selectAuthStateToken);

    function handleResponseNoJson (response: any) {
        if (!response.ok) {
            const error = (response && response.message) || response.statusText;

            return Promise.reject(error);
        }

        return response.statusText;
    }

    function handleResponse (response: any) {
        if (!response.ok) {
            const error = (response && response.message) || response.statusText;

            return Promise.reject(error);
        }

        return response.json();
    }

    function get (url: string) {
        const requestOptions = {
            method: 'GET',
            headers: {
                ... defaultHeaders,
                ...(authToken && { authToken })
            }
        };

        return fetch(url, requestOptions).then(handleResponse);
    }

    function post (url: string, body: any, hasResponse = true) {
        const requestOptions = {
            method: 'POST',
            headers: {
                ... defaultHeaders,
                ...(authToken && { authToken })
            },
            body: JSON.stringify(body)
        };

        if (hasResponse) return fetch(url, requestOptions).then(handleResponse);
        else return fetch(url, requestOptions).then(handleResponseNoJson);
    }

    function put (url: string, body?: any) {
        const requestOptions = {
            method: 'PUT',
            headers: {
                ... defaultHeaders,
                ...(authToken && { authToken })
            },
            body: JSON.stringify(body)
        };

        return fetch(url, requestOptions).then(handleResponseNoJson);
    }

    function deleteRequest (url: string, body?: any) {
        const requestOptions = {
            method: 'DELETE',
            headers: {
                ... defaultHeaders,
                ...(authToken && { authToken })
            },
            body: JSON.stringify(body)
        };

        return fetch(url, requestOptions).then(handleResponseNoJson);
    }

    return {
        get,
        post,
        put,
        deleteRequest
    };
};
