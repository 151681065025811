import {
    GetInitDokCourseParams,
    GetInitiDokActivitiesParams,
    GetOverviewDokCourseActivitiesParams,
    GetOverviewDokCourseParams,
    SetDokOverviewActivityCheckboxParams
} from './challenges-course-filters.types';
import {
    DokFilterCourse,
    GetDokParentCheckboxState
} from './components/dok-filter/dok-filter.types';
import { defaultLevelFilterValue } from './components/level-filter/level-filter-constants';
import { CheckboxStates } from '@containers/activity-router/data-grid-container/grid/grid.types';
import {
    manageRouteCheckboxAssignments
} from '@containers/activity-router/routes/helpers/assignment/assignment-selected/route-assignments-selected';
import {
    generateOverviewGridCell,
    resetGridCells
} from '@containers/activity-router/routes/helpers/grid/cells/grid-cells-helpers';
import {
    resetGridCellCounters
} from '@containers/activity-router/routes/helpers/grid/counters/grid-cell-counters/grid-cell-counters';
import {
    generateOverviewGridCounter
} from '@containers/activity-router/routes/helpers/grid/counters/update-grid-counters/update-grid-counters';
import {
    getActivityKey,
    getStrandCourseKey,
    getTopicKey,
    parseStrandCourseKey
} from '@containers/activity-router/routes/helpers/keys/key-generator';
import {
    toggleActivityGridCheckbox
} from '@containers/activity-router/routes/helpers/slice/checkbox/slice-grid-checkbox-helpers';
import { AssignmentActions } from '@containers/activity-router/routes/helpers/slice/slice.types';
import {
    getUpdatedChallengesLevelFilter
} from '@containers/activity-router/routes/learn/challenges/helpers/slice/course/get-challenges-level-filtered-course';
import { ChallengesComplexity } from '@services/gateways/combined-assign-gateway/combined-assign-gateway.types';

const {
    assign,
    reassign,
    unassign,
    modify
} = AssignmentActions;
const {
    checked,
    unchecked,
    partial
} = CheckboxStates;
const {
    ALL,
    DOK2,
    DOK3,
    DOK4
}= ChallengesComplexity;

export const getDokParentCheckboxState: GetDokParentCheckboxState = (items) => {
    if (items.length === 0) return unchecked;

    const allChecked = items.every((topic) => topic.checked === checked);
    const allUnchecked = items.every((topic) => topic.checked === unchecked);

    if (allChecked) return checked;
    if (allUnchecked) return unchecked;

    return partial;
};

const getInitDokActivities = ({
    activities,
    filterLevel,
    dokFilter
}: GetInitiDokActivitiesParams) => {
    return activities.map((activity) => {
        const { complexity } = activity;
        const {
            yearGroup,
            yearGroupFromName,
            yearGroupToName
        } = activity.extraProperties;
        const updatedFilterLevel = getUpdatedChallengesLevelFilter(filterLevel);
        const isSelectedDokFilter = dokFilter === ChallengesComplexity.ALL || dokFilter === complexity;
        const isSelectedFilterLevel = filterLevel === defaultLevelFilterValue || yearGroup === updatedFilterLevel;
        const checkboxState = isSelectedDokFilter && isSelectedFilterLevel ? checked : unchecked;

        return {
            ...activity,
            checked: checkboxState,
            depthOfKnowledge: activity.complexity as ChallengesComplexity,
            yearGroupFromName: yearGroupFromName || '',
            yearGroupToName: yearGroupToName || '',
            yearGroup
        };
    });
};

export const getInitDokCourse = ({
    course,
    filterLevel,
    dokFilter = ChallengesComplexity.ALL
}: GetInitDokCourseParams): DokFilterCourse => {
    return course.topics.map((strand) => {
        const updatedTopics = strand.topics?.map((topic) => {
            const updatedActivities = getInitDokActivities({
                activities: topic.activities,
                filterLevel,
                dokFilter
            });

            return {
                id: topic.id,
                checked: getDokParentCheckboxState(updatedActivities),
                name: topic.name,
                isExpanded: false,
                activities: updatedActivities
            };
        }) || [];

        return {
            id: strand.id,
            checked: getDokParentCheckboxState(updatedTopics),
            name: strand.name,
            isExpanded: false,
            topics: updatedTopics,
            strandIndex: strand.strandIndex
        };
    });
};

const getOverviewDokCourseActivities = ({
    activities,
    strandId,
    topicId,
    courseLookup,
    type
}: GetOverviewDokCourseActivitiesParams) => {
    return activities.map((activity) => {
        const activityKey = getActivityKey({
            id: activity.id,
            activityType: activity.activityType
        });
        const courseKey = getStrandCourseKey({
            strandId,
            topicId,
            activityId: activityKey
        });
        const isChecked = courseLookup[courseKey] || (type !== 'create' && activity.checked === CheckboxStates.checked);
        const checkboxState = isChecked ? CheckboxStates.checked : CheckboxStates.unchecked;

        return {
            ...activity,
            checked: checkboxState
        };
    });
};

export const getOverviewDokCourse = ({
    dokFilterCourse,
    courseLookup,
    type
}: GetOverviewDokCourseParams): DokFilterCourse => {
    return dokFilterCourse.map((strand) => {
        const updatedTopics = strand.topics?.map((topic) => {
            const updatedActivities = getOverviewDokCourseActivities({
                activities: topic.activities,
                strandId: strand.id,
                topicId: topic.id,
                courseLookup,
                type
            });

            return {
                id: topic.id,
                checked: getDokParentCheckboxState(updatedActivities),
                name: topic.name,
                isExpanded: false,
                activities: updatedActivities
            };
        }) || [];

        return {
            id: strand.id,
            checked: getDokParentCheckboxState(updatedTopics),
            name: strand.name,
            isExpanded: false,
            topics: updatedTopics,
            strandIndex: strand.strandIndex
        };
    });
};

const getDokOverviewInitialValues = ({
    type,
    state
}: Pick<SetDokOverviewActivityCheckboxParams, 'type' | 'state'>) => {
    switch (type) {
    case 'create': {
        return {
            assignmentsSelected: {
                [assign]: {},
                [reassign]: {},
                [unassign]: {},
                [modify]: {}
            },
            gridCells: resetGridCells({
                gridCells: state.gridCells,
                isSelected: false
            }),
            gridCounters: resetGridCellCounters({
                counters: state.gridCounters
            })
        };
    }
    case 'add': {
        return {
            assignmentsSelected: { ...state.assignmentsSelected },
            gridCells: { ...state.gridCells },
            gridCounters: { ...state.gridCounters }
        };
    }
    default: return { ...state };
    }
};

export const setDokOverviewActivityCheckbox = ({
    courseLookup,
    state,
    manageSelectedAssignments,
    type
}: SetDokOverviewActivityCheckboxParams) => {
    const isChecked = true;

    Object.keys(courseLookup).forEach((key) => {
        state.gridCounters = generateOverviewGridCounter({
            gridCounters: state.gridCounters,
            strandCourseId: key
        });
        state.gridCells = generateOverviewGridCell({
            gridCells: state.gridCells,
            strandCourseId: key
        });
    });

    let {
        assignmentsSelected,
        gridCounters,
        gridCells
    } = getDokOverviewInitialValues({
        type,
        state
    });

    Object.keys(courseLookup).forEach((key) => {
        const {
            strandId,
            topicId,
            activityId
        } = parseStrandCourseKey(key);
        const topicKey = getTopicKey({
            strandId,
            topicId
        });
        const {
            gridCells: updatedGridCells,
            gridCounters: updatedGridCounters
        } = toggleActivityGridCheckbox({
            gridCells,
            gridCounters,
            activityId,
            topicId: topicKey,
            isChecked
        });

        assignmentsSelected = manageRouteCheckboxAssignments({
            selectedAssignments: assignmentsSelected,
            gridCells: updatedGridCells,
            isChecked,
            checkAssignmentEligibility: ({ activityKey }) => activityKey === activityId,
            manageSelectedAssignments
        });

        gridCells = updatedGridCells;
        gridCounters = updatedGridCounters;
    });

    return {
        gridCells,
        gridCounters,
        assignmentsSelected
    };
};

export const getDokFilterCourseValues = ({ dokFilterCourse }: { dokFilterCourse: DokFilterCourse }) => {
    const totalActivitiesByLevel: Record<number, number> = {};
    const checkedActivitiesByLevel: Record<number, number> = {};
    const initChallengesComplexity = {
        [ALL]: 0,
        [DOK2]: 0,
        [DOK3]: 0,
        [DOK4]: 0
    };
    const totalActivitiesByDok: Record<ChallengesComplexity, number> = { ...initChallengesComplexity };
    const checkedActivitiesByDok: Record<ChallengesComplexity, number> = { ...initChallengesComplexity };

    for (const strand of dokFilterCourse) {
        for (const topic of strand.topics) {
            for (const activity of topic.activities) {
                const { depthOfKnowledge, yearGroup } = activity;

                totalActivitiesByLevel[yearGroup] = (totalActivitiesByLevel[yearGroup] || 0) + 1;
                totalActivitiesByDok[depthOfKnowledge] += 1;

                if (activity.checked === CheckboxStates.checked) {
                    checkedActivitiesByLevel[yearGroup] = (checkedActivitiesByLevel[yearGroup] || 0) + 1;
                    checkedActivitiesByDok[depthOfKnowledge] += 1;
                }
            }
        }
    }

    let updatedLevelFilter = defaultLevelFilterValue;
    let updatedDokFilter= ChallengesComplexity.ALL;

    if (Object.keys(checkedActivitiesByLevel).length === 1) {
        for (const yearGroup of Object.keys(checkedActivitiesByLevel)) {
            const parsedYearGroup = parseInt(yearGroup);

            if (!isNaN(parsedYearGroup) && checkedActivitiesByLevel[parsedYearGroup] === totalActivitiesByLevel[parsedYearGroup]) {
                updatedLevelFilter = parsedYearGroup;
            }
        }
    }
    if (Object.keys(checkedActivitiesByDok).length === 1) {
        for (const dok of Object.keys(checkedActivitiesByDok)) {
            const parsedDok = parseInt(dok) as ChallengesComplexity;

            if (totalActivitiesByDok[parsedDok] === checkedActivitiesByDok[parsedDok]) {
                updatedDokFilter = parsedDok;
            }
        }
    }

    return {
        updatedLevelFilter,
        updatedDokFilter
    };
};
